import { create } from 'zustand';

export type ReportInfo = {
  isWaiting: boolean;
  name: string;
  id: string;
}

interface WaitingReportStoreState {
  reportInfo: ReportInfo | null
}

interface WaitingReportStoreActions {
  setReportInfo: (reportInfo: ReportInfo | null) => void;
}

interface WaitingReportStore {
  state: WaitingReportStoreState;
  actions: WaitingReportStoreActions;
}

export const useWaitingReportStore = create<WaitingReportStore>((set) => ({
  state: {
    reportInfo: null
  },
  actions: {
    setReportInfo: (reportInfo) =>
      set((state) => ({
        state: {
          ...state.state,
          reportInfo
        }
      })),
  },
}));
