import { GLDeviceList } from '@group-link-one/grouplink-components';

import { DeviceListContent } from './Content/DeviceListContent';
import { ModalDecomission } from './ModalDecomission/ModalDecomission';
import { ModalDeviceDetails } from './ModalDeviceDetails/ModalDeviceDetails';
import { ModalEditDevice } from './ModalEditDevice/ModalEditDevice';
import { useDeviceListBody } from './useDeviceListBody';
import { ModalAddTag } from './ModalAddTag/ModalAddTag';
import { ModalUnassignTag } from './ModalUnassignTag/ModalUnassignTag';
import { ModalSureToAddValue } from './ModalSureToAddValue/ModalSureToAddValue';

export function DeviceListBody(): JSX.Element {
  const {
    requests,
    features,
    deviceListStateGL,
    userGroupsFormatted,
    groupsChildrensAvailables,
    bigNumbers,
    t,
    user,
    tagOptionsFormatted,
    tagValuesFormatted,
    isStreetLight,
    onSelectUseCase,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onSelectFilterLampIsLitOrNot,
  } = useDeviceListBody();

  return (
    <GLDeviceList
      useCaseOptions={deviceListStateGL.useCaseOptionsAvailables}
      userGroups={userGroupsFormatted}
      userGroupsChildren={groupsChildrensAvailables}
      tagOptions={tagOptionsFormatted}
      tagValuesOptions={tagValuesFormatted}
      tooltipTextInTitleIcon={isStreetLight ? t('deviceList.disclaimer.title') : undefined}
      allDevicesOptions={{
        deviceListContent: <DeviceListContent />,
      }}
      application={user?.use_case}
      flagsOptions={{
        search: features.deviceList.filters.search,
        filters: {
          button: features.deviceList.filters.button,
          byActivationDate: features.deviceList.filters.byActivationDate,
          byGroups: features.deviceList.filters.byGroups,
          byTags: features.deviceList.filters.byTags,
          byUseCase: features.deviceList.filters.byUseCase,
        },
      }}
      bigNumbersOptions={{
        bigNumbers: bigNumbers.length > 0 && requests.getBignumberCountsDeviceList
          ? bigNumbers
          : undefined,
      }}
      modalDetails={<ModalDeviceDetails />}
      modalRegister={<ModalEditDevice />}
      modalDecommission={<ModalDecomission />}
      modalAddTag={<ModalAddTag />}
      modalUnassignTag={<ModalUnassignTag />}
      modalSureToAddValue={<ModalSureToAddValue />}
      footerText={isStreetLight ? t('deviceList.footerText.streetLight') : undefined}
      hasPermissionToShowBigNumbers={requests.lastReading}
      isOnTopHeaderContent={!requests.getBignumberCountsDeviceList}
      onSelectUseCase={onSelectUseCase}
      onSelectUserGroup={onSelectUserGroup}
      onSelectTag={onSelectTag}
      onSelectTagValue={onSelectTagValue}
      onSelectFilterLampIsLitOrNot={onSelectFilterLampIsLitOrNot}
      // onSelectFilterAlert={onSelectAlert}
    />
  );
}
