import { GLBox, GL_COLORS, GLTypography, GLTooltip, GL_HELP, GLDropdown, GL_ALARM, AlertType, useI18n } from "@group-link-one/grouplink-components";
import { alertsMapped } from "@/hooks/useAlarmsByUseCase";
import { If } from "@/utils/GLStatement/If";
import { motion } from "framer-motion";
import { alertsToNotConsider } from "../useDeviceDetailsTitleContent";

interface IAlarms {
  alarms?: AlertType[]
  onlyIcon?: boolean
}

export function Alarms({ alarms, onlyIcon = false }: IAlarms) {

  const { t } = useI18n()
  const currentAlerts = alarms?.map((alert) => {
    if (!alertsToNotConsider.includes(alert as AlertType)) {
      return alert;
    }
  }).filter(Boolean) as AlertType[];

  if (currentAlerts?.length === 0 || !currentAlerts) return null

  return (
    <GLBox
      gap={10}
      width={'fit-content'}
      align="center"
      style={{
        border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
        padding: onlyIcon ? '0px' :'5px 6px 5px 20px',
        borderRadius: 26,
      }}
    >
      {!onlyIcon && (
        <>
          <GLTypography
            text={t('deviceList.detailsModal.alarms')}
            color="FONT_COLOR_DARK_GREY"
            as="span"
            fontSize={3.5}
            weight={600}
            style={{ whiteSpace: 'nowrap' }}
          />

          <GLTooltip
            text={t('deviceList.detailsModal.alarmTooltip')}
            width={200}
            position="top-left"
            tooltipContainerStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            tooltipTextStyle={{
              color: GL_COLORS.WHITE,
            }}
          >
            <GL_HELP size={15} fill={GL_COLORS.FONT_COLOR} />
          </GLTooltip>
        </>

      )}

      <motion.div
        initial={{
          opacity: 0,
          x: -20,
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        transition={{
          duration: 0.5,
          delay: 0.15,
        }}
      >
        <GLBox
          align="center"
          justify="center"
          width="61px"
          height={36}
          style={{
            borderRadius: 20,
            border: `2px solid ${GL_COLORS.BORDER_COLOR}`,
            backgroundColor: GL_COLORS.DANGER,
          }}
        >
          <GLDropdown.Root>
            <GLDropdown.Button
              hasOutline={false}
              style={{
                backgroundColor: GL_COLORS.DANGER,
                borderRadius: 20,
              }}
              icon={
                <GLBox align="center" justify="center" gap={6}>
                  <GLTypography
                    text={String(currentAlerts.length)}
                    color={'WHITE'}
                  />
                  <GL_ALARM size={16} fill={GL_COLORS.WHITE} />
                </GLBox>
              }
            />
            <GLDropdown.BoxOptions
              width={'fit-content'}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                padding: '20px 20px',
                gridGap: 10,
                zIndex: 999999,
              }}
            >
              {currentAlerts && currentAlerts.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    x: -20,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.15,
                  }}
                >
                  <GLBox
                    height={40}
                    justify="space-between"
                    style={{
                      borderRadius: 20,
                      padding: '10px 15px',
                      backgroundColor: GL_COLORS.DANGER,
                    }}
                  >
                    <GLTypography
                      text={alertsMapped[item as AlertType] || ''}
                      color="WHITE"
                      style={{
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />

                    <GL_ALARM fill={GL_COLORS.WHITE} />
                  </GLBox>
                </motion.div>
              ))}
            </GLDropdown.BoxOptions>
          </GLDropdown.Root>
        </GLBox>
      </motion.div>
    </GLBox>
  )
}
