import { GLDetailedModal, GLModal } from '@group-link-one/grouplink-components';

import { ModalCreateTagContent } from './Content/ModalCreateTagContent';
import { useModalCreateTag } from './useModalCreateTag';

export const ModalCreateTag = (): JSX.Element => {
  const { state, actions, verifyButtonText, createTagActions } =
    useModalCreateTag();

  return (
    <GLDetailedModal
      content={<ModalCreateTagContent />}
      open={state.modalCreateTagIsOpen}
      title={verifyButtonText()}
      onClose={() => {
        actions.setModalCreateTagIsOpen(false);
        actions.reset();
        createTagActions.resetAll();
      }}
      width="100%"
      justifyContent="flex-start"
    />
  );
};
