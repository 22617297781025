import {
  GL_CHECK,
  GL_COLORS,
  GL_IDEA,
  GL_PADLOCK,
  GL_PENCIL,
  GL_TAG,
  GL_TRASHCAN,
  GLAppCard,
  GLAppForm,
  GLBox,
  GLButton,
  GLCard,
  GLCardTemplate,
  GLCheckbox,
  GLEmptyList,
  GLList,
  GLSelect,
  GLTextField,
  GLTipsCard,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { useAddValues } from './useAddValues';
import { RestrictContainerSelect } from './Style';

export const AddValues = (): JSX.Element => {
  const {
    t,
    isMobile,
    tabs,
    actions,
    state,
    isDisabled,
    handleSubmit,
    onSubmit,
    setValue,
    showFeature,
    setIsDisabled,
    register,
  } = useAddValues();

  return (
    <GLBox>
      <GLBox direction="column" width={'100%'} gap={50}>
        <GLBox>
          <GLTypography
            text={t('tags.content.modal.createNewTag.addValues.description')}
          />
        </GLBox>

        <GLBox direction="column">
          <GLTypography
            text={t(
              'tags.content.modal.createNewTag.addValues.accessType.title',
            )}
            weight={600}
          />

          <RestrictContainerSelect>
            <GLBox>
              <GLBox>
                <GL_PADLOCK fill={GL_COLORS.FONT_COLOR_DARK_GREY} />

                <GLTypography
                  color={'FONT_COLOR'}
                  text={t(
                    'tags.content.modal.createNewTag.addValues.accessType.subtitle',
                  )}
                />
              </GLBox>

              <GLCheckbox
                iconType="check"
                checked={state.isStrict}
                onChange={(e) => {
                  if (state.isStrict) {
                    actions.setIsStrict(!state.isStrict);
                    return;
                  }

                  actions.setIsStrict(e.target.checked);
                }}
              />
            </GLBox>

            <GLBox>
              <GLTypography
                color={'FONT_COLOR'}
                fontSize={3}
                text={t(
                  'tags.content.modal.createNewTag.addValues.accessType.description',
                )}
              />
            </GLBox>
          </RestrictContainerSelect>
        </GLBox>

        <GLBox direction="column" width={580}>
          <GLTypography
            text={
              state.modalType === 'creation'
                ? t('tags.content.modal.createNewTag.addValues.values.title')
                : t(
                    'tags.content.modal.createNewTag.addValues.values.titleEditTag',
                  )
            }
            weight={600}
          />

          {showFeature('show_component_create_new_tag_value') && (
            <GLBox>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: 20,
                }}
              >
                <GLTextField
                  title={t(
                    'tags.content.modal.createNewTag.addValues.values.input.label',
                  )}
                  placeholder={t(
                    'tags.content.modal.createNewTag.addValues.values.input.placeholder',
                  )}
                  {...register('values')}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setValue('values', e.target.value);
                      setIsDisabled(false);
                      return;
                    }

                    setIsDisabled(true);
                  }}
                />

                <GLButton
                  text={t(
                    'tags.content.modal.createNewTag.addValues.values.input.button',
                  )}
                  variant="outline"
                  align="center"
                  type="submit"
                  weight={600}
                  disabled={isDisabled}
                  style={{
                    borderColor: isDisabled
                      ? GL_COLORS.DISABLED_FONT_COLOR
                      : GL_COLORS.NAVY,
                    width: isMobile ? '100%' : '190px',
                  }}
                  border
                  padding
                />
              </form>
            </GLBox>
          )}

          <GLBox>
            <GLList
              listName="tag-values"
              type="list"
              mode="infinit-scroll"
              isLoading={false}
              tabs={tabs}
              tabActive={'Added values'}
              error={state.tagValuesError}
              content={
                <GLBox direction="column">
                  {state.tagValues.map((tagValue) => (
                    <GLCardTemplate.Provider>
                      <GLCardTemplate.Root
                        style={{
                          width: '100%',
                        }}
                      >
                        <GLCardTemplate.Header justify="space-between">
                          <GLCardTemplate.Column
                            width={'fit-content'}
                            align="flex-start"
                            justify="start"
                          >
                            <GLTypography
                              text={tagValue.value}
                              color="FONT_COLOR"
                              fontSize={4}
                              weight={600}
                            />
                          </GLCardTemplate.Column>

                          <GLCardTemplate.Actions
                            width={5}
                            disappearOnHover={false}
                            justify="flex-end"
                            style={{ right: 20 }}
                          >
                            {state.modalType === 'edition' && (
                              <GLBox
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  actions.setActiveTagValueToEdit({
                                    id: tagValue.id,
                                    value: tagValue.value,
                                  })
                                }
                              >
                                <GL_PENCIL
                                  fill={GL_COLORS.ACCENT_COLOR}
                                  size={16}
                                />
                              </GLBox>
                            )}

                            <GLBox
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (state.modalType === 'edition') {
                                  actions.setActiveTagValueToDelete({
                                    id: tagValue.id,
                                    value: tagValue.value,
                                  });
                                }
                                actions.setRemoveTagValue(tagValue.value);
                              }}
                            >
                              <GL_TRASHCAN fill={GL_COLORS.DANGER} size={16} />
                            </GLBox>
                          </GLCardTemplate.Actions>
                        </GLCardTemplate.Header>
                      </GLCardTemplate.Root>
                    </GLCardTemplate.Provider>
                  ))}

                  {state.tagValues.length === 0 && (
                    <GLEmptyList
                      title={t(
                        'tags.content.modal.createNewTag.addValues.values.list.empty.title',
                      )}
                      description={t(
                        'tags.content.modal.createNewTag.addValues.values.list.empty.description',
                      )}
                      icon={<GL_TAG fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
                      hasBorder={false}
                    />
                  )}
                </GLBox>
              }
            />
          </GLBox>
        </GLBox>
      </GLBox>
      <GLBox width={'504px'}>
        <GLTipsCard
          icon={<GL_IDEA fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
          title={t('tags.content.modal.createNewTag.addValues.tip.title')}
          size="small"
          type="default"
          hasAnimation
          content={
            <GLTypography
              text={t(
                'tags.content.modal.createNewTag.addValues.tip.description',
              )}
            />
          }
        />
      </GLBox>
    </GLBox>
  );
};
