import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';

import { useTagsService } from '../../../../../../../Services/tagsService/useTagsService';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../../../../../stores/tags.store';
import {
  tagValueSchema,
  useModalAssignValuesToTagContent,
} from '../../../../../GLModalAssignValuesToTag/Content/useModalAssignValuesToTagContent';
import { useI18n } from '@group-link-one/grouplink-components';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useModalCreateTagContent } from '../../../useModalCreateTagContent';

interface IUseModalEditValue {
  tagsStoreState: TagsStoreState;
  tagsStoreActions: TagsStoreActions;
  handleSubmit: UseFormHandleSubmit<
    {
      tagValue: string;
    },
    undefined
  >;
  register: UseFormRegister<{ tagValue: string }>;
  errors: FieldErrors<{ tagValue: string }>;
  onSubmitTagValue: (data: { tagValue: string }) => Promise<void>;
  t: (key: string) => string;
}

export const useModalEditValue = (): IUseModalEditValue => {
  const { t } = useI18n();
  const { updateTagValue } = useTagsService();
  const { state: tagsStoreState, actions: tagsStoreActions } = useTagsStore();
  const { refetchTagValues } = useModalCreateTagContent();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(tagValueSchema),
    values: {
      tagValue: tagsStoreState.activeTagValueToEdit?.value || '',
    },
  });

  async function onSubmitTagValue(data: { tagValue: string }): Promise<void> {
    if (tagsStoreState.activeTagValueToEdit) {
      const activeTagValue = tagsStoreState.activeTagValueToEdit;
      const newTagValue = data.tagValue;

      await updateTagValue({
        id: Number(activeTagValue.id),
        data: {
          value: newTagValue,
        },
      }).then((res) => {
        if (res.status === 200) {
          refetchTagValues();
          tagsStoreActions.setActiveTagValueToEdit(undefined);
        }
      });

      reset();
    }
  }

  return {
    tagsStoreState,
    tagsStoreActions,
    handleSubmit,
    register,
    errors,
    onSubmitTagValue,
    t,
  };
};
