import { User } from '@group-link-one/gl-utils';
import {
  AlertType,
  GL_COLORS,
  GL_GLStation,
  GLCard,
  RegisterDeviceStoreActions,
} from '@group-link-one/grouplink-components';

import {
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { getOptionsByApplication } from '../utils/getOptionsByApplication';
import { useDeviceListCard } from './useDeviceListCard';
import { alertsToNotConsider } from '../ModalDeviceDetails/TitleContent/useDeviceDetailsTitleContent';
import { DetailsDeviceStoreActions } from '../store/details-device-store';
import { differenceInHours } from 'date-fns';
import { DeviceListStoreActions } from '../store/device-list-store';
import { Features } from '@/hooks/usePrivilleges';
import { is } from 'date-fns/locale';

export interface DeviceListCardProps {
  item:
  | GetDevicesActivatedLastReadingsResponse
  | GetDevicesPendingActivationResponse;

  detailsDeviceActions: DetailsDeviceStoreActions;
  registerDeviceActions: RegisterDeviceStoreActions;
  deviceListActions: DeviceListStoreActions;
  isStreetLight?: boolean;
  features: Features;
  user?: User
}

export function DeviceListCard({
  item,
  detailsDeviceActions,
  registerDeviceActions,
  features,
  deviceListActions,
  user,
  isStreetLight
}: DeviceListCardProps): JSX.Element {
  const {
    isMobile,
    getCriticalityLevel,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    onRegisterDeviceClick,
    onClickDecomission,
  } = useDeviceListCard({
    detailsDeviceActions,
    registerDeviceActions,
    deviceListActions,
    isStreetLight
  });

  function isPendingActivationCard(
    card: unknown,
  ): card is GetDevicesPendingActivationResponse {
    const hasLastSeen =
      'last_seen' in (card as GetDevicesPendingActivationResponse);

    return !hasLastSeen;
  }

  if (isPendingActivationCard(item)) {
    return (
      <GLCard
        key={item.device_id}
        status="pending"
        variant="ut-device-list-card"
        id={String(item.device_id)}
        applicationTypeOptions={{
          icon: <GL_GLStation size={18} fill={GL_COLORS.WHITE} />,
          color: GL_COLORS.DISABLED_FONT_COLOR,
        }}
        hasAlerts={true}
        hasPermissionToRegisterDevice={
          user?.use_case === 'util_kwh' && features.devices.activateDevice
        }
        onRegisterDeviceClick={onRegisterDeviceClick}
      />
    );
  }

  const hasAlerts = item.channels.some((channel) => {
    return channel.alerts?.some(
      (alert) => !alertsToNotConsider.includes(alert as AlertType),
    );
  });

  const channelZero = item?.channels?.[0];

  /**
   * se tiver LIGHT_OUTPUT_STATUS é pq a lâmpada está ligada
   * se não tiver LIGHT_OUTPUT_STATUS é pq a lâmpada está desligada
   */

  const isLampOff = item.channels.some((channel) =>
    !(channel.alerts?.some((alert) => alert === 'LIGHT_OUTPUT_STATUS')),
    // channel.alerts?.some((alert) => alert === 'LIGHT_OUTPUT_STATUS'),
  );

  const { icon, color } = getOptionsByApplication({
    type: item.application,
    deviceMeasurementCategory: item.meta.device_measurement_category,
    off: isLampOff,
  });

  /**
   * street light
   * last_seen -> ultimo evento independente do canal
   * read_at -> ultimo evento do canal 01
   */

  function getLastEventDate() {
    const asItem = item as GetDevicesActivatedLastReadingsResponse;

    if (isStreetLight) {
      const firstChannelReading = channelZero.read_at;

      return firstChannelReading
        ? firstChannelReading
        : asItem.last_seen;
    }

    return asItem.last_seen;
  }

  const lastEvent = getLastEventDate();

  const lastEventDate = new Date(lastEvent);
  const now = new Date();

  const howManyHoursSinceLastEvent = differenceInHours(
    now,
    lastEventDate,
  )

  const criticalityLevel = getCriticalityLevel(howManyHoursSinceLastEvent);

  return (
    <GLCard
      key={item.device_id}
      variant="ut-device-list-card"
      applicationTypeOptions={{
        icon: icon(
          18,
          isLampOff ? GL_COLORS.FONT_COLOR_VARIANT : GL_COLORS.WHITE,
        ),
        color,
      }}
      activated_by={{
        name: channelZero ? channelZero.activated_by : '--',
        email: channelZero ? channelZero.activated_by_email : '--',
      }}
      status="active"
      hasAlerts={hasAlerts ? true : undefined}
      name={channelZero ? channelZero.name : '--'}
      address={item.meta.display_address || '--'}
      criticalityLevel={criticalityLevel}
      id={String(item.device_id)}
      lastEvent={lastEvent}
      isMobile={isMobile}
      isToShowDetailsButton={!!channelZero && features?.devices.showDetails}
      isWaitingForActivation={!channelZero}
      canEdit={features?.devices.editDevice}
      canDecommission={features?.devices.decommissionDevice}
      onAddressClick={() => {
        onAddressClick(item.meta.latitude, item.meta.longitude);
      }}
      tags={item?.tags}
      onOpenDetails={() => onDetailsClick(item)}
      onOpenEdit={() => onEditClick(item)}
      onDecommission={() => onClickDecomission(item)}
    />
  );
}
