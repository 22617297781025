import { useI18n, useToast } from '@group-link-one/grouplink-components';
import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../Services/queryClient';
import { useTagsService } from '../../../Services/tagsService/useTagsService';
import { GetTagRow } from '../../../Services/tagsService/useTagsService.type';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../stores/tags.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useHandleCache } from '@/hooks/useHandleCache';

interface ModalDeleteTagProps {
  t: (key: string) => string;
  actions: TagsStoreActions;
  state: TagsStoreState;
  onDelete: () => Promise<void>;
}

export const useModalDeleteTag = (): ModalDeleteTagProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { actions, state } = useTagsStore();
  const { deleteTag } = useTagsService();
  const { addToast } = useToast();
  const { invalidateCache } = useHandleCache();

  const { mutateAsync: onDelete } = useMutation({
    mutationFn: async () => {
      if (!state.activeTag?.key.id) {
        return;
      }
      await deleteTag(Number(state.activeTag?.key.id));
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.deleteTagSuccess.title'),
        message: t('tags.content.toasts.deleteTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_DELETE,
        eventDescription: 'End Tag deleted',
      });

      invalidateCache(['get-tags']);

      if (state.modalType === 'edition') actions.setModalCreateTagIsOpen(false);
      actions.setModalDeleteTagIsOpen(false);
      actions.setActiveTagId(undefined);
    },
    onError: () => {
      addToast({
        type: 'error',
        title: t('tags.content.toasts.deleteTagError.title'),
        message: t('tags.content.toasts.deleteTagError.description'),
      });
    },
  });

  return {
    actions,
    state,
    t,
    onDelete,
  };
};
