import {
  AlertType,
  AlertTypeValues,
  BigNumberProps,
  Colors,
  GL_CHECK,
  GL_COLORS,
  GL_GLStation,
  GL_LOGIN,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { format, differenceInHours } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import CheckImg from '../../../../images/DeviceList/icon-check.svg';
// import InternetImg from "../../../../images/DeviceList/icon-internet.svg";
import LoginImg from '../../../../images/DeviceList/icon-log-in.svg';
import StationImg from '../../../../images/DeviceList/icon-station.svg';
import { useDeviceListService } from '../../../../Services/deviceListService/useDeviceListService';
import { DeviceMapResponse, GetDeviceHealthCheckCountResponse } from '../../../../Services/deviceListService/useDeviceListService.types';
import {
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../../../HealthCheckMap/store/health-check-store';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import AlarmsImg from "@/images/DeviceList/bigNumbers/alarms.svg"

interface IUseHealthCheckBigNumbers {
  bigNumbers: BigNumberProps[];
  healthCheckState: HealthCheckStoreState;
  features: Features;
  isSM: boolean;
  onClickHideShowBigNumbers: () => void;
}

export const useHealthCheckBigNumbers = (): IUseHealthCheckBigNumbers => {
  const isSM = useMediaQuery('(max-width: 660px)');

  const { getDevicesHealthCheckCount } = useDeviceListService();
  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const { t } = useI18n();
  const { features } = usePrivileges();

  const alertsOnly = useMemo(() => {
    if (healthCheckState.alertFilterValue?.length === 0) {
      return undefined;
    }

    if (healthCheckState.alertFilterValue?.length === 1) {
      if (healthCheckState.alertFilterValue[0].id === 'all') {
        return true;
      }

      if (healthCheckState.alertFilterValue[0].id === 'with-alert') {
        return true;
      }
      if (healthCheckState.alertFilterValue[0].id === 'without-alert') {
        {
          return false;
        }
      }

      return undefined;
    }
  }, [healthCheckState.alertFilterValue]);

  const alerts = useMemo(() => {
    if (healthCheckState.alertTypesSelected?.length === 0) {
      return undefined;
    }

    return healthCheckState.alertTypesSelected?.map((alert) => alert.id);
  }, [healthCheckState.alertTypesSelected]);

  const from = useMemo(
    () =>
      format(
        healthCheckState.range.from || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      ),
    [healthCheckState.range.from],
  );

  const until = useMemo(
    () =>
      format(
        healthCheckState.range.to || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      ),
    [healthCheckState.range.to],
  );

  const devicesFetched = healthCheckState.devicesFetched || []
  const devicesFetchedV2 = healthCheckState.devicesFetchedV2

  const userGroupSelected: number[] | undefined = useMemo(() => {
    if (!healthCheckState?.userGroupSelecteds) {
      return [];
    }
    const userGroupsId = healthCheckState?.userGroupSelecteds?.map(
      (userGroup) => Number(userGroup.id),
    );
    return userGroupsId;
  }, [healthCheckState?.userGroupSelecteds]);

  // const { data: bigNumberCount, isLoading } = useQuery({
  //   queryKey: [
  //     'devicesHealthCheckCount',
  //     from,
  //     until,
  //     alertsOnly,
  //     alerts,
  //     userGroupSelected,
  //   ],
  //   queryFn: async () => {
  //     const response = await getDevicesHealthCheckCount({
  //       from,
  //       until,
  //       alerts_only: alertsOnly,
  //       alerts: alerts as AlertType[],
  //       group_ids: userGroupSelected,
  //     });

  //     return response;
  //   },
  // });

  function calculeCommunicationRate(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null,
  ): string | number {
    if (!devicesHealthCheckCountParam) {
      return 0;
    }

    // eslint-disable-next-line
    const total = calculeBigNumber(devicesHealthCheckCountParam, 'total');
    // eslint-disable-next-line
    const devicesWithReadings = calculeBigNumber(
      devicesHealthCheckCountParam,
      'devices_with_readings',
    );

    if (total === 0) {
      return 0;
    }

    return (
      ((Number(devicesWithReadings) / Number(total)) * 100).toFixed(2) + '%'
    );
  }

  function calculeBigNumber(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null,
    type:
      | 'devices_with_readings'
      | 'devices_without_readings'
      | 'total'
      | 'communication_rate',
  ): string | number {
    if (!devicesHealthCheckCountParam) {
      return 0;
    }

    switch (type) {
      case 'devices_with_readings':
        return devicesHealthCheckCountParam.devices_with_readings;
      case 'devices_without_readings':
        return devicesHealthCheckCountParam.devices_without_readings;
      case 'total':
        return (
          devicesHealthCheckCountParam.devices_with_readings +
          devicesHealthCheckCountParam.devices_without_readings
        );
      case 'communication_rate':
        return calculeCommunicationRate(devicesHealthCheckCountParam);
    }
  }

  function calculateDeviceWithOrWithoutReading(devicesFetched: DeviceMapResponse[], type: 'with' | 'without') {
    const withoutReadingValueHours = 72
    const now = healthCheckState.range.to || new Date()

    const devicesFiltereds = devicesFetched.filter((device) => {
      const lastReadingDevice = new Date(device.last_seen)
      const difference = differenceInHours(now, lastReadingDevice)

      if (type === 'with') {
        return difference < withoutReadingValueHours
      }

      return difference >= withoutReadingValueHours
    })

    return devicesFiltereds.length
  }

  function calculateAlarms(devicesFetched?: DeviceMapResponse[]) {
    return devicesFetched?.filter(device => {
      const devicesAlerts: AlertType[] = device.alerts

      if (devicesAlerts.some(alert => Object.values(AlertTypeValues).includes(alert))) {
        return device
      }

    }).filter(Boolean).length
  }

  const bigNumbers: BigNumberProps[] = useMemo(() => {

    const allBigNumbers: BigNumberProps[] = [
      {
        iconLeft: (
          <GL_GLStation
            fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            size={24}
          />
        ),
        iconRight: <img src={StationImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.totalDevices.title'),
        // count: devicesFetched.length,
        count: healthCheckState.devicesFetchedInfoV2?.total_devices || 0,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.LIGHT_GREEN,
        textColor: 'ULTRADARK_GREY_SECONDARY',
        tooltipText: t(
          'healthCheck.bigNumbers.totalDevices.tooltipDescription',
        ),
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          // whiteSpace: 'nowrap',
          minWidth: 200
        },
        isToAppear: features.healthCheck.bigNumbers.totalDevices,
        isLoading: healthCheckState.isFetchingDevices
      },
      {
        iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={LoginImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.withReadings.title'),
        // count: calculateDeviceWithOrWithoutReading(devicesFetched, 'with'),
        count: healthCheckState.devicesFetchedInfoV2?.recent_readings || 0,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.NAVY,
        textColor: 'WHITE',
        tooltipText: t(
          'healthCheck.bigNumbers.withReadings.tooltipDescription',
        ),
        helpIconColor: 'WHITE',
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          // whiteSpace: 'nowrap',
          minWidth: 200
        },
        isToAppear: features.healthCheck.bigNumbers.withReadings,
        isLoading: healthCheckState.isFetchingDevices

      },
      {
        iconLeft: <GL_CHECK fill={GL_COLORS.FONT_COLOR_VARIANT} size={24} />,
        iconRight: <img src={CheckImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.noReadings.title'),
        // count: calculateDeviceWithOrWithoutReading(devicesFetched, 'without'),
        count: healthCheckState.devicesFetchedInfoV2?.missing_readings || 0,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        textColor: 'FONT_COLOR_VARIANT',
        tooltipText: t('healthCheck.bigNumbers.noReadings.tooltipDescription'),
        helpIconColor: 'WHITE',
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          // whiteSpace: 'nowrap',
          minWidth: 200

        },
        isToAppear: features.healthCheck.bigNumbers.withoutReadings,
        isLoading: healthCheckState.isFetchingDevices
      },
      {
        iconRight: <img src={AlarmsImg} alt="" />,
        iconRightStyle: {
          bottom: -10,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
          backgroundColor: 'initial'
        },
        title: t('healthCheck.bigNumbers.withAlert.title'),
        count: healthCheckState.devicesFetchedInfoV2?.alerts || 0,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: GL_COLORS.HOVER_COLOR_DANGER,
        textColor: 'DANGER',
        countColor: 'FONT_COLOR',
        helpIconColor: "DANGER",
        tooltipText: t('healthCheck.bigNumbers.withAlert.tooltipDescription'),
        tooltipPosition: 'top-right',
        tooltipZIndex: 2,
        tooltipTextStyle: {
          // whiteSpace: 'nowrap',
          minWidth: 200

        },
        isLoading: healthCheckState.isFetchingDevices,
        isToAppear: true,
      },
    ];

    return allBigNumbers.filter((bigNumber) => bigNumber.isToAppear);
  }, [
    healthCheckState.isFetchingDevices,
    devicesFetchedV2,
    healthCheckState.devicesFetchedInfoV2
  ]);

  function onClickHideShowBigNumbers(): void {
    healthCheckActions.setBigNumbersIsOpen(!healthCheckState.bigNumbersIsOpen);
  }

  // useEffect(() => {
  //   healthCheckActions.setDevicesHealthCheckCount(bigNumberCount || null);
  // }, [bigNumberCount]);

  return {
    bigNumbers,
    features,
    healthCheckState,
    // isLoading,
    isSM,
    onClickHideShowBigNumbers,
  };
};
