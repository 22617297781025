import {
  GL_COLORS,
  GLBox,
  GLFilterByDeviceGroup,
  GLModal,
  GLSelectMultiple,
  GLTypography,
} from '@group-link-one/grouplink-components';
import React from 'react';

import { useModalFilter } from './useModalFilter';

export function ModalFilter(): JSX.Element {
  const {
    features,
    t,
    healthCheckActions,
    healthCheckState,
    userGroupsFormatted,
    groupsChildrensAvailables,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
  } = useModalFilter();

  return (
    <GLModal
      open={healthCheckState.modalFilterIsOpen}
      type="create"
      title={t('deviceList.search.filterOptions.title')}
      position="right"
      description={t('deviceList.search.filterOptions.description')}
      hasButtonClose={false}
      hasOutsideClick={true}
      modalContentStyle={{
        justifyContent: 'flex-start',
      }}
      content={
        <GLBox direction="column" gap={15}>
          <GLBox
            height={2}
            width="100%"
            borderRadius={8}
            style={{
              background: GL_COLORS.BORDER_COLOR,
            }}
          />

          {features.healthCheck.filters.byAlert && (
            <GLSelectMultiple
              options={[
                {
                  id: 'with-alert',
                  text: t('healthCheck.filterModal.alerts.withAlert'),
                  // content: (
                  //   <GLSelectMultiple
                  //     hasInput={false}
                  //     canSelectMultiple={true}
                  //     options={Object.entries(alertsMap).map(
                  //       ([key, value]) => ({
                  //         id: key,
                  //         text: value,
                  //       }),
                  //     )}
                  //     optionsSelected={
                  //       healthCheckState.alertTypesSelected || []
                  //     }
                  //     boxOptions={{
                  //       width: 200,
                  //       position: 'bottom-right',
                  //     }}
                  //     onSelectedOptionsChange={(selectedOptions) => {
                  //       healthCheckActions.setAlertFilterValue([
                  //         {
                  //           id: 'with-alert',
                  //           text: 'Com alerta',
                  //         },
                  //       ]);

                  //       healthCheckActions.setAlertTypesSelected(
                  //         selectedOptions,
                  //       );
                  //     }}
                  //   />
                  // ),
                },
                {
                  id: 'without-alert',
                  text: t('healthCheck.filterModal.alerts.withoutAlert'),
                },
              ]}
              optionsSelected={healthCheckState.alertFilterValue || []}
              rootOptions={{
                style: {
                  minWidth: '100%',
                },
              }}
              labelOptions={{
                text: t('healthCheck.filterModal.alerts.label'),
              }}
              inputOptions={{
                placeholder: t('common.select'),
              }}
              boxOptions={{
                width: '100%',
                position: 'bottom-right',
              }}
              canSelectMultiple={false}
              hasCheckbox={false}
              isAsynchronous
              onSelectedOptionsChange={(selectedOptions) => {
                if (!selectedOptions || selectedOptions?.length === 0) {
                  healthCheckActions.setAlertFilterValue(undefined);
                  healthCheckActions.setAlertTypesSelected([]);
                  return;
                }

                // const isWithoutAlert = selectedOptions.some(
                //   (option) => option.id === 'without-alert',
                // );

                // if (!isWithoutAlert) return

                healthCheckActions.setAlertTypesSelected([]);
                healthCheckActions.setAlertFilterValue(selectedOptions);
              }}
            />
          )}

          {/* 
            Removendo temporariamente
            {features.healthCheck.filters.byReadings && (
                <GLSelectMultiple
                  options={[
                    { id: "with-reading", text: t('healthCheck.filterModal.withReadings.withReadings') },
                    { id: "without-reading", text: t('healthCheck.filterModal.withReadings.withoutReadings') },
                  ]}
                  optionsSelected={healthCheckState.filterByReading || []}
                  rootOptions={{
                    style: {
                      minWidth: "100%",
                    },
                  }}
                  labelOptions={{
                    text: t('healthCheck.filterModal.withReadings.label'),
                  }}
                  inputOptions={{
                    placeholder: t('common.select'),
                  }}
                  boxOptions={{
                    width: "100%",
                    position: "bottom-right",
                  }}
                  canSelectMultiple={false}
                  hasCheckbox={false}
                  isAsynchronous
                  onSelectedOptionsChange={(selectedOptions) => {
                    if (selectedOptions.length === 0) {
                      healthCheckActions.setFilterByReading(undefined);
                      return;
                    }

                    healthCheckActions.setFilterByReading(selectedOptions);
                  }}
                />
            )} 
          */}

          {features.healthCheck.filters.byGroups && (
            <GLFilterByDeviceGroup
              userGroups={userGroupsFormatted}
              userGroupsChildren={groupsChildrensAvailables}
              onSelectUserGroup={onSelectUserGroup}
              isToShowAllDeviceGroupsDirectly
            />
          )}

          {features.healthCheck.filters.byTags &&
            tagOptionsFormatted &&
            tagOptionsFormatted?.length > 0 && (
              <GLBox direction="column">
                <GLSelectMultiple
                  options={tagOptionsFormatted}
                  optionsSelected={[]}
                  canSelectMultiple={false}
                  maxCharacters={20}
                  hasCheckbox={false}
                  hasSearch
                  onSelectedOptionsChange={(selectedOptions) => {
                    if (onSelectTag && !!selectedOptions)
                      onSelectTag(selectedOptions);
                  }}
                  labelOptions={{
                    text: t('deviceList.search.filterByTag'),
                  }}
                  inputOptions={{
                    width: '100%',
                    layout: 'size',
                    placeholder: t('common.select'),
                  }}
                  rootOptions={{
                    width: '100%',
                  }}
                />
              </GLBox>
            )}

          {features.healthCheck.filters.byTags &&
            tagValuesFormatted &&
            tagValuesFormatted?.length > 0 && (
              <GLSelectMultiple
                options={tagValuesFormatted}
                optionsSelected={[]}
                canSelectMultiple={false}
                maxCharacters={20}
                hasCheckbox={false}
                hasSearch
                onSelectedOptionsChange={(selectedOptions) => {
                  if (onSelectTagValue && !!selectedOptions)
                    onSelectTagValue(selectedOptions);
                }}
                labelOptions={{
                  text: t('deviceList.search.filterByTagValue'),
                }}
                inputOptions={{
                  width: '100%',
                  layout: 'size',
                  placeholder: t('common.select'),
                }}
                rootOptions={{
                  width: '100%',
                }}
              />
            )}
        </GLBox>
      }
      onClose={() => {
        healthCheckActions.setModalFilterIsOpen(false);
      }}
    />
  );
}
