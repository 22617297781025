import {
  GL_BUILDING,
  GL_LIST,
  GL_TAG,
  GL_USER,
  GLModalSteperContentStoreActions,
  GLModalSteperContentStoreState,
  i18n,
} from '@group-link-one/grouplink-components';
import { create } from 'zustand';

export interface CreateTagStoreActions
  extends GLModalSteperContentStoreActions {
  setTextCreationVariable: (text: string) => void;
}

interface CreateTagStore {
  state: GLModalSteperContentStoreState;
  actions: CreateTagStoreActions;
}

export enum CREATE_TAG_STEP_NAMES_TO_KEYS {
  IDENTIFY = 0,
  ADD_VALUES = 1,
}

export type CreateTagStepNames = keyof typeof CREATE_TAG_STEP_NAMES_TO_KEYS;

export const useCreateTagStore = create<CreateTagStore>((set) => ({
  state: {
    modalSteperContentIsOpen: false,
    stepActive: 0,
    steperIsOnTop: false,
    steps: [
      {
        key: 'identify',
        Icon: <GL_TAG />,
        title: i18n.t('tags.content.modal.steps.stepOne'),
        wasVisited: true,
      },
      {
        key: 'addValues',
        Icon: <GL_LIST />,
        textToHere: i18n.t('impersonate.modal.button.continue'),
        title: i18n.t('tags.content.modal.steps.stepTwo'),
        wasVisited: false,
      },
    ].map((_, i) => ({ ..._, id: i })),
    modalType: 'creation',
    isLoading: false,
    textToCreate: i18n.t('tags.content.rightContent.btnCreateNewTag'),
    textToEdit: i18n.t(
      'messageCenter.list.modal.creationModal.steps.four.headerButtonEdit',
    ),
  },
  actions: {
    setTextCreationVariable: (text: string) => {
      set((state) => ({
        state: {
          ...state.state,
          textToCreate: text,
        },
      }));
    },

    setModalSteperContentIsOpen: (isOpen: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          modalSteperContentIsOpen: isOpen,
        },
      }));
    },

    setModalType: (type: GLModalSteperContentStoreState['modalType']) => {
      set((state) => ({
        state: {
          ...state.state,
          modalType: type,
        },
      }));
    },

    setSteperIsOnTop: (isOnTop: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          steperIsOnTop: isOnTop,
        },
      }));
    },

    setStepActive: (step: number) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive: step,
        },
      }));
    },

    setStepWasVisited: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          steps: state.state.steps.map((step) => {
            if (
              step.id ===
              CREATE_TAG_STEP_NAMES_TO_KEYS[stepName as CreateTagStepNames]
            ) {
              return {
                ...step,
                wasVisited: true,
              };
            }

            return step;
          }),
        },
      }));
    },

    setIsLoading: (isLoading: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          isLoading,
        },
      }));
    },

    toStepOnClickInEdit: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive:
            CREATE_TAG_STEP_NAMES_TO_KEYS[stepName as CreateTagStepNames],
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          textToCreate: i18n.t('tags.content.rightContent.btnCreateNewTag'),
          modalSteperContentIsOpen: false,
          stepActive: 0,
          steperIsOnTop: false,
          modalType: 'creation',
          isLoading: false,
        },
      }));
    },
  },
}));
