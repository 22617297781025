import { useI18n } from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';
import { useModalCreateTagContent } from '../../useModalCreateTagContent';

export const useIdentify = () => {
  const { t } = useI18n();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { state, actions } = useModalCreateTagContent();

  return {
    isMobile,
    t,
    actions,
    state,
  };
};
