import { useAuth } from '@group-link-one/gl-utils';
import {
  GLPageNotFound,
  Privacy,
  Terms,
  useGLModalLogout,
  useUserInfoStore,
  Changelogs,
} from '@group-link-one/grouplink-components';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  LOCAL_STORAGE_KEY_EXTERNAL_TIME,
  useCheckTimeManipulation,
} from '../hooks/useCheckTimeManipulation';
import { usePrivileges } from '../hooks/usePrivilleges';
import Logo404 from '../images/art_404.svg';
import RightLogo404 from '../images/bg_404.svg';
import GLLogoDark from '../images/Common/logo-glsmartretail-dark.svg';
import GLLogo from '../images/Common/logo-glsmartretail-light.svg';
import { HelpCenterLayout } from '../layouts/HelpCenterLayout/HelpCenterLayout';
import { MainLayout } from '../layouts/MainLayout/MainLayout';
import { OnboardLayout } from '../layouts/OnboardLayout';
import { PageLayout } from '../layouts/PageLayout';
import { SettingsRoutes, usePageLayout } from '../layouts/usePageLayout';
import { Audit } from '../Pages/Audit/Audit';
import { DeviceList } from '../Pages/DeviceList/DeviceList';
import { EventList } from '../Pages/EventList/eventList';
import { GroupList } from '../Pages/GroupList/GroupList';
import { HealthCheckMap } from '../Pages/HealthCheckMap/Map/HealthCheckMap';
import { NewPassword } from '../Pages/Onboard/CreateNewPassword/newPassword';
import { SignUp } from '../Pages/Onboard/SignUp/signUp';
import { Reports } from '../Pages/ReportList/ReportList';
import { GroupsUsers } from '../Pages/Settings/GroupsUsers/organizationUsers';
import { LoginSecurity } from '../Pages/Settings/LoginSecurity/loginSecurity';
import { MyAccount } from '../Pages/Settings/MyAccount/myAccount';
import { Overview } from '../Pages/Settings/Overview/overview';
import { Users } from '../Pages/Settings/Users/organizationUsers';
import { Tags } from '../Pages/Tags/Tags';
import { VerifyRoute } from './VerifyRoute';
import { useGLFlags } from '@group-link-one/gl-utils';

export const AuthenticatedRoutes = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    accessToken,
    accessTokenIsValid,
    userGroups,
    setActionOnIdle,
  } = useAuth();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();
  const { getHeaderNavList } = usePageLayout();
  const { pages } = usePrivileges();
  const { initializeTime } = useCheckTimeManipulation();
  const { actions: userInfoActions } = useUserInfoStore();
  const { showFeature, flags } = useGLFlags();
  const [changelogContent, setChangelogContent] = useState('');

  const indexRoute = useMemo(() => {
    return showFeature('show_device_list_page')
      ? '/devices'
      : SettingsRoutes.OVERVIEW;
  }, [flags]);

  const settingsNavList = getHeaderNavList('settings');

  useEffect(() => {
    setActionOnIdle?.(handleOpenModalLogout);
  }, []);

  useEffect(() => {
    fetch('changelog.md')
      .then((res) => res.text())
      .then(setChangelogContent);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, [accessToken]);

  useEffect(() => {
    const hasKeyExternalTime = localStorage.getItem(
      LOCAL_STORAGE_KEY_EXTERNAL_TIME,
    );
    if (accessToken && !hasKeyExternalTime) {
      initializeTime();
    }
  }, [accessToken]);

  useEffect(() => {
    userInfoActions.setUserGroups(userGroups || []);
  }, [userGroups]);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          element={
            <VerifyRoute
              needAuth={true}
              userHasPrivileges={true}
              element={<MainLayout />}
            />
          }
        >
          <Route
            path="network"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                userHasPrivileges={pages.eventList}
              />
            }
          >
            <Route
              path="event-list"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<EventList />}
                  userHasPrivileges={pages.eventList}
                />
              }
            />
          </Route>

          <Route
            path="settings"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout headerNavListItems={settingsNavList} />}
                userHasPrivileges={Object.values(pages.settings).some(
                  (value) => value,
                )}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />
            <Route
              path="overview"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Overview />}
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />

            <Route
              path="my-account"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<MyAccount />}
                  userHasPrivileges={pages.settings.myAccount}
                />
              }
            />

            <Route
              path="login-security"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<LoginSecurity />}
                  userHasPrivileges={pages.settings.loginSecurity}
                />
              }
            />

            <Route
              path="users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Users />}
                  userHasPrivileges={pages.settings.users}
                />
              }
            />
            <Route
              path="groups-users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<GroupsUsers />}
                  userHasPrivileges={pages.settings.groupsUsers}
                />
              }
            />

            <Route
              path="audit"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Audit />}
                  userHasPrivileges={pages.settings.audit}
                />
              }
            />

            <Route
              path="tags"
              element={
                <VerifyRoute
                  needAuth={true}
                  userHasPrivileges={pages.settings.tags}
                  element={<Tags />}
                />
              }
            />
          </Route>

          <Route
            path="reports"
            element={
              <VerifyRoute
                needAuth={true}
                userHasPrivileges={pages.reports}
                element={<PageLayout />}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  userHasPrivileges={pages.reports}
                  element={<Reports />}
                />
              }
            />
          </Route>

          <Route
            path="devices"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout style={{ zIndex: "unset", position: "static" }} />}
                userHasPrivileges={pages.deviceList || pages.deviceGroupList}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<DeviceList />}
                  userHasPrivileges={pages.deviceList}
                />
              }
            />

            <Route
              path="groups"
              element={
                <VerifyRoute
                  needAuth={true}
                  userHasPrivileges={pages.deviceGroupList}
                  element={<GroupList />}
                />
              }
            />
          </Route>

          <Route
            path="health-check"
            element={
              <VerifyRoute
                needAuth={true}
                userHasPrivileges={pages.healthCheck}
                element={<PageLayout style={{ zIndex: "unset", position: "static" }} />}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  userHasPrivileges={pages.healthCheck}
                  element={<HealthCheckMap />}
                />
              }
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                userHasPrivileges={true}
                element={<Navigate to={indexRoute} />}
              />
            }
          />
        </Route>

        {accessToken && (
          <>
            <Route element={<OnboardLayout />}>
              <Route path="/join-organization" element={<SignUp />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route element={<HelpCenterLayout />}>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/changelog" element={<Changelogs changelogContent={changelogContent} />} />
            </Route>

            <Route
              path="*"
              element={
                <GLPageNotFound
                  logoOptions={{
                    src: {
                      logoLight: GLLogo,
                      logoDark: GLLogoDark,
                    },
                    backgroundImage: RightLogo404,
                    alt: 'GroupLink logo',
                    logoRight: Logo404,
                  }}
                  onClickButton={() => navigate('/')}
                  title="Oops!"
                  subtitle="Page not found"
                  description="It looks like you were detached from our IoT world..."
                />
              }
            />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};
