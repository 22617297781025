import {
  GLDropdown,
  GLTransferList,
} from '@group-link-one/grouplink-components';

import { useGroupDevicesTransferList } from './useGroupDevicesTransferList';

export function GroupDevicesTransferList(): JSX.Element {
  const {
    t,
    devicesAdded,
    devicesInGroupSearchIsCorrect,
    devicesAvailables,
    groupDevicesState,
    leftSideOptions,
    rightSideOptions,
    tagsFormatted,
    tagValuesFormatted,
    userGroupsFormatted,
    groupsChildrensAvailables,
    features,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onSearch,
    onTransferItem,
    onSelectLeftItem,
    onSelectRightItem,
    onTransferAllItemsToLeft,
    onTransferAllItemsToRight,
  } = useGroupDevicesTransferList();

  return (
    <GLTransferList
      leftSideList={devicesAvailables}
      leftSideOptions={leftSideOptions}
      leftSideSearchValue={groupDevicesState.availableDevicesSearch}
      leftSideIsFetching={groupDevicesState.isFetchingMoreAvailableDevices}
      leftSideSearchHasFailure={true}
      selectFilterOptions={{
        // userGroups: userGroupsFormatted,
        // userGroupsChildren: groupsChildrensAvailables,
        tagOptions: tagsFormatted,
        tagValuesOptions: tagValuesFormatted,
        search: features.deviceList.filters.search,
        filters: {
          button: features.deviceList.filters.button,
          byActivationDate: features.deviceList.filters.byActivationDate,
          byGroups: features.deviceList.filters.byGroups,
          byTags: features.deviceList.filters.byTags,
          byUseCase: features.deviceList.filters.byUseCase,
        },
      }}
      onSelectTag={onSelectTag}
      onSelectUserGroup={onSelectUserGroup}
      onSelectTagValue={onSelectTagValue}
      leftSideTexts={{
        search: t('common.searchPlaceholder'),
        emptyTitle:
          groupDevicesState.availableDevicesSearch.length === 0
            ? t(
                'groupsDevices.addDevicesModal.transferList.leftSide.emptyState.title',
              )
            : t(
                'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.title',
              ),
        emptyBody:
          groupDevicesState.availableDevicesSearch.length === 0
            ? t(
                'groupsDevices.addDevicesModal.transferList.leftSide.emptyState.description',
              )
            : t(
                'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.description',
              ),
      }}
      rightSideList={devicesAdded}
      rightSideOptions={rightSideOptions}
      rightSideTooltipIsOpen={!devicesInGroupSearchIsCorrect}
      rightSideTooltipText={t('deviceList.search.tooltip')}
      rightSideSearchValue={groupDevicesState.devicesInGroupSearch}
      rightSideIsFetching={groupDevicesState.isFetchingMoreDevicesInGroup}
      rightSideTexts={{
        search: t('common.searchPlaceholder'),
        emptyTitle:
          groupDevicesState.devicesInGroupSearch.length === 0
            ? t(
                'groupsDevices.addDevicesModal.transferList.rightSide.emptyState.title',
              )
            : t(
                'groupsDevices.addDevicesModal.transferList.rightSide.emptyStateFromSearch.title',
              ),
        emptyBody:
          groupDevicesState.devicesInGroupSearch.length === 0
            ? t(
                'groupsDevices.addDevicesModal.transferList.rightSide.emptyState.description',
              )
            : t(
                'groupsDevices.addDevicesModal.transferList.rightSide.emptyStateFromSearch.description',
              ),
      }}
      onTransferItemToLeft={(items) => onTransferItem(items, 'left')}
      onTransferItemToRight={(items) => onTransferItem(items, 'right')}
      onSelectLeftItem={onSelectLeftItem}
      onSelectRightItem={onSelectRightItem}
      onTransferAllItemsToLeft={onTransferAllItemsToLeft}
      onTransferAllItemsToRight={onTransferAllItemsToRight}
      onSearch={(value, side) => onSearch(value, side)}
    />
  );
}
