import {
  AlertType,
  CreateReportBody,
  CreateReportsBodyParams,
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLSelectMultipleOptions,
  useI18n,
  useToast,
  HealthCheckReportParams,
  ReportType,
} from '@group-link-one/grouplink-components';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';
import { endOfDay, format, startOfDay } from 'date-fns';

import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../../store/health-check-store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { useReportListService } from '@/Services/reportListService/useReportListService';
import { ReportInfo, useWaitingReportStore } from '@/store/waiting-report';
import { useEffect } from 'react';
import { useAuth, UseCase, User } from '@group-link-one/gl-utils';

const useCasesAvailablesToDownloadReport = [
  'util_kwh',
  'util_water',
];

interface IUseRightFilters {
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  features: Features;
  reportInfo: ReportInfo | null;
  relativeFilterTimeOptions: GLRelativeFilterTimeProps;
  t: (key: string) => string;
  is500px: boolean;
  isMobile: boolean;
  isDownloadReportAvailable: boolean;
  user: User | undefined;
  onClickFilter: () => void;
  onClickDownloadReport: () => Promise<void>;
}

export const useRightFilters = (): IUseRightFilters => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const is500px = useMediaQuery('(max-width: 500px)');
  const { features } = usePrivileges();
  const [waitingReportValue, setWaitingReportValue] = useLocalStorage(
    '@ut-is-waiting-report',
    '',
  );
  const { user } = useAuth();

  const useCase = user?.use_case
  const isDownloadReportAvailable = useCasesAvailablesToDownloadReport.includes(useCase as string);

  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const {
    state: { reportInfo },
    actions: { setReportInfo },
  } = useWaitingReportStore();

  const { createReport: createReportFn } = useReportListService();

  const { addToast } = useToast();

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? 'fit-content' : '100%',
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? 'transparent' : 'inherit',
        alignItems: isMobile ? 'flex-end' : 'initial',
      },
    },
    inputOptions: {
      width: 'fit-content',
    },
    boxOptions: {
      position: 'bottom-right',
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: healthCheckState.currentRelativeTime,
    currentDateRange: healthCheckState.currentDateRange,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_RELATIVE_DATE_FILTER,
        eventDescription: 'Start health check filter relative date',
        param1: JSON.stringify(option),
      });
      healthCheckActions.setCurrentRelativeTime(option);
    },
    setCurrentDateRange: (range?: GLDateRangeType) => {
      healthCheckActions.setCurrentDateRange(range);
    },
    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range) {
        return;
      }
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_DATE_RANGE_FILTER,
        eventDescription: 'Start health check filter date range',
        param1: String(range.from),
        param2: String(range.to),
      });
      healthCheckActions.setRange(range);

      // onBoundsChanged();
    },
  };

  function onClickFilter(): void {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_FILTER,
      eventDescription: 'Start health check filter',
    });
    healthCheckActions.setModalFilterIsOpen(true);
  }

  function getGroupsIds() {
    const parentGroups = healthCheckState.userParentGroupSelecteds;
    const childrenGroups = healthCheckState.userGroupSelecteds;

    const isEmpyParentGroups = !parentGroups || parentGroups?.length === 0;
    const isEmpyChildrenGroups =
      !childrenGroups || childrenGroups?.length === 0;

    let groupIds: number[] = [];

    if (isEmpyParentGroups) return [];

    if (!isEmpyChildrenGroups) {
      groupIds = childrenGroups?.map((group) => Number(group.id));
    } else {
      groupIds = parentGroups?.map((group) => Number(group.id)) as number[];
    }
    ('');

    return groupIds;
  }

  function getFiltersToReport(): HealthCheckReportParams {
    return {
      alerts:
        (healthCheckState.alertTypesSelected?.map(
          (alert) => alert.id,
        ) as AlertType[]) || [],
      alerts_only: healthCheckState.alertFilterValue
        ? healthCheckState.alertFilterValue?.find(
          (filter) => filter.id === 'with-alert',
        )
          ? 'true'
          : 'false'
        : undefined,
      has_recently_readings: healthCheckState.filterByReading
        ? healthCheckState.filterByReading?.find(
          (filter) => filter.id === 'with-reading',
        )
          ? 'true'
          : 'false'
        : undefined,
      group_ids: getGroupsIds(),
      org_id: user?.org.id || 0,
      tag_key: healthCheckState?.tagSelecteds?.[0]?.value || undefined,
      tag_value: healthCheckState?.tagValuesSelecteds?.[0]?.text || undefined,
      geo_filter: healthCheckState.geo_filter,
    };
  }

  async function onClickDownloadReport() {
    try {
      if (waitingReportValue && reportInfo) return;

      const today = new Date();
      const dayFormatted = format(today, 'dd-MMM-yyyy HH:mm:ss');

      const reportTypeMap: Partial<Record<UseCase, ReportType>> = {
        util_kwh: 'EnergyHealthCheckReport',
        util_water: 'WaterHealthCheckReport',
      }

      const reportType: ReportType = useCase && useCase in reportTypeMap
        ? reportTypeMap[useCase as UseCase]!
        : 'WaterHealthCheckReport';

      const reportParams = {
        type: reportType,
        name: `Health Check Report: ${dayFormatted}`,
        params: getFiltersToReport(),
      } as CreateReportBody;

      const createReportResponse = await createReportFn(reportParams);

      const infos = {
        isWaiting: true,
        name: reportParams.name,
        id: createReportResponse.id,
      };

      setReportInfo(infos);
      setWaitingReportValue(JSON.stringify(infos));

      addToast({
        title: t('healthCheck.downloadReport.onSuccess.title'),
        message: t('healthCheck.downloadReport.onSuccess.message'),
        animationDuration: 7,
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: t('healthCheck.downloadReport.onError.title'),
        message: t('healthCheck.downloadReport.onError.message'),
      });
    }
  }

  return {
    healthCheckState,
    healthCheckActions,
    features,
    reportInfo,
    relativeFilterTimeOptions,
    t,
    is500px,
    isMobile,
    isDownloadReportAvailable,
    user,
    onClickFilter,
    onClickDownloadReport,
  };
};
