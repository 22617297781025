import { useI18n } from "@group-link-one/grouplink-components";
import { GetDevicesActivatedLastReadingsResponse, LastReadingSeries, Serie } from "@/Services/deviceListService/useDeviceListService.types"

interface UseGetStreetLightValues {
  device: GetDevicesActivatedLastReadingsResponse | undefined;
  channelActive?: number;
}

const unitBySerieMap: Record<Serie, string> = {
  rms_voltage: 'V',
  rms_current: 'A',
  reactive_energy_accum: 'VArh',
  active_energy_accum: 'Wh',
  power_factor: '',
  energy_direct: 'kWh',
  energy_reverse: 'kWh',
  water: 'm³',
}

export const useGetStreetLightValues = ({ device, channelActive = 0 }: UseGetStreetLightValues) => {

  const { t } = useI18n()
  const currentChannelData = device?.channels[channelActive];

  const lastReadingIsASeries = currentChannelData?.last_reading &&
    typeof currentChannelData?.last_reading !== 'number' &&
    currentChannelData?.last_reading.series

  function findValueInSeries(serie: Serie, params?: { toFixed?: number, showUnit?: boolean }): string {
    const value = (lastReadingIsASeries as LastReadingSeries[])?.find(
      (series) => series.serie === serie,
    )?.value

    const valueFormatted = value && value !== '0'
      ? params?.toFixed
        ? Number(value).toFixed(params.toFixed)
        : value
      : '--'

    return valueFormatted !== '--'
      ? valueFormatted + ` ${(params?.showUnit ? unitBySerieMap[serie] : '')}`
      : valueFormatted
  }

  function getRealPower(): string {
    const voltage = findValueInSeries('rms_voltage') === '--'
      ? 0
      : Number(findValueInSeries('rms_voltage'))

    const current = findValueInSeries('rms_current') === '--'
      ? 0
      : Number(findValueInSeries('rms_current'))

    const realPower = voltage * current
    const realPowerFormatted = realPower.toFixed(2)

    return realPowerFormatted
  }

  const streetLightValues = {
    rms_voltage: findValueInSeries('rms_voltage'),
    rms_current: findValueInSeries('rms_current'),
    reactive_energy: findValueInSeries('reactive_energy_accum'),
    active_energy: findValueInSeries('active_energy_accum'),
    power_factor: findValueInSeries('power_factor', { toFixed: 2 }),
    real_power: getRealPower(),
  }

  const streetLightValuesArray = [
    // {
    //   label:  t('deviceList.detailsModal.ut.bigNumbers.voltage.title'),
    //   value: streetLightValues.rms_voltage,
    // },
    // {
    //   label:  t('deviceList.detailsModal.ut.bigNumbers.current.title'),
    //   value: streetLightValues.rms_current,
    // },
    // {
    //   label:  t('deviceList.detailsModal.ut.bigNumbers.reactiveEnergy.title'),
    //   value: streetLightValues.reactive_energy,
    // },
    {
      label:  t('deviceList.detailsModal.ut.bigNumbers.activeEnergy.title'),
      value: findValueInSeries('active_energy_accum', { showUnit: true }),
    },
    // {
    //   label:  t('deviceList.detailsModal.ut.bigNumbers.powerFactor.title'),
    //   value: streetLightValues.power_factor,
    // },
    // {
    //   label:  t('deviceList.detailsModal.ut.bigNumbers.realPower.title'),
    //   value: streetLightValues.real_power,
    // }
  ]

  return {
    findValueInSeries,
    streetLightValues,
    streetLightValuesArray
  }
}
