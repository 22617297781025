import {
  GL_COLORS,
  GL_IDEA,
  GLBox,
  GLTextAreaField,
  GLTextField,
  GLTipsCard,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { useIdentify } from './useIdentify';

export const Identify = (): JSX.Element => {
  const { isMobile, t, state, actions } = useIdentify();

  return (
    <GLBox
      justify="space-between"
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? 50 : 150}
    >
      <GLBox direction="column">
        <GLBox
          width={!isMobile ? '407.55px' : '100%'}
          direction="column"
          gap={15}
        >
          <GLTextField
            disabled={state.modalType === 'edition'}
            title={t('tags.content.modal.createNewTag.keyName')}
            placeholder={t(
              'tags.content.modal.createNewTag.placeholder.keyName',
            )}
            onChange={(e) => {
              const newValue = e.target.value.replace(/ /g, '_').toLowerCase();

              actions.setKeyName(newValue);
            }}
            value={state.keyName}
            isRequired={state.modalType === 'creation'}
            failure={!!state.keyNameError}
            failureMessage={state.keyNameError}
            textFieldContainerStyle={{}}
          />

          {state.modalType === 'creation' && (
            <GLTipsCard
              type="default"
              title=""
              hasAnimation
              icon={<></>}
              size="small"
              content={
                <GLTypography
                  text={t(
                    'tags.content.modal.createNewTag.placeholder.tagNameInformation',
                  )}
                  fontSize={3}
                />
              }
            />
          )}
        </GLBox>

        <GLBox>
          <GLTextField
            title={t('tags.content.modal.createNewTag.label')}
            placeholder={t('tags.content.modal.createNewTag.placeholder.label')}
            defaultValue={
              state.modalType === 'edition' ? state.activeTag?.key?.label : ''
            }
            onChange={(e) => {
              actions.setLabel(e.target.value);
            }}
            value={state.label}
            isRequired
            failure={!!state.labelError}
            failureMessage={state.labelError}
            textFieldContainerStyle={{
              height: 100,
            }}
          />
          <GLTextAreaField
            title={t('tags.content.modal.createNewTag.description')}
            placeholder={t(
              'tags.content.modal.createNewTag.placeholder.description',
            )}
            defaultValue={
              state.modalType === 'edition'
                ? state?.activeTag?.key?.description
                : ''
            }
            value={state.description}
            onChange={(e) => {
              actions.setDescription(e.target.value);
            }}
            // textFieldContainerStyle={{
            //   height: 100,
            // }}
            characterLimit={250}
          />
        </GLBox>
      </GLBox>

      <GLBox width={isMobile ? '100%' : '504px'}>
        <GLTipsCard
          icon={<GL_IDEA fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
          title={t('tags.content.modal.createNewTag.tip.title')}
          size="small"
          hasAnimation
          type="default"
          content={
            <GLTypography
              color="FONT_COLOR_DARK_GREY"
              text={t('tags.content.modal.createNewTag.tip.description')}
            />
          }
        />
      </GLBox>
    </GLBox>
  );
};
