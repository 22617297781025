import { TabProps, useI18n } from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';

import { useTagsService } from '../../../Services/tagsService/useTagsService';
import { TagsStoreActions, useTagsStore } from '../stores/tags.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { useEffect, useMemo } from 'react';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { GetTagRow } from '@/Services/tagsService/useTagsService.type';
import { useMediaQuery } from 'usehooks-ts';

interface TagsContentProps {
  t: (key: string) => string;
  data: GetTagRow[] | undefined;
  tabs: TabProps[];
  tagsIsLoading: boolean;
  refetch: () => void;
  actions: TagsStoreActions;
  isMobile: boolean;
}

export const useTagsContent = (): TagsContentProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { getTags } = useTagsService();
  const { state, actions } = useTagsStore();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const tagSearch = useMemo(() => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.TAGS_SEARCH,
      eventDescription: 'Search for tags',
      param1: state.search,
    });

    return state.search;
  }, [state.search]);

  const {
    data,
    isLoading: tagsIsLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-tags', tagSearch],
    queryFn: async () => {
      const response = await getTags({
        key: tagSearch || undefined,
      });

      return response.rows;
    },
  });

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      count: data?.length,
      cacheKey: 'tags',
      name: 'Tags',
      onClick: () => {},
    },
  ];

  return { t, data, tabs, tagsIsLoading, refetch, actions, isMobile };
};
