import { QueryOptions, useQueryClient } from "@tanstack/react-query";

interface GetCacheDataParams<T> {
  queryKey: QueryOptions['queryKey'];
  callback?: () => Promise<T>;
}

interface UpdatedCacheDataParams<T> {
  queryKey: QueryOptions['queryKey'];
  newData: T;
}

interface UseHandleCache {
  getData: <T>(params: GetCacheDataParams<T>) => Promise<T | undefined>;
  updatedData: <T>(params: UpdatedCacheDataParams<T>) => Promise<T | undefined>;
  verifyCache: <T>(queryKey: QueryOptions['queryKey']) => Promise<T | undefined>;
  setData: <T>(queryKey: QueryOptions['queryKey'], data: T) => Promise<void>;
  invalidateCache: (queryKey: QueryOptions['queryKey']) => Promise<void>;
  refetchData: (queryKey: QueryOptions['queryKey']) => Promise<void>;
}

export const useHandleCache = (): UseHandleCache => {
  const queryClient = useQueryClient();

  async function updatedData<T>(params: UpdatedCacheDataParams<T>): Promise<T | undefined> {
    if (!params.queryKey) return

    const hasCache = queryClient.getQueryData(params.queryKey);

    if (hasCache) {
      queryClient.setQueryData(params.queryKey, params.newData);
    }
  }

  async function getData<T>({
    queryKey,
    callback
  }: GetCacheDataParams<T>): Promise<T | undefined> {
    let response: T | undefined = undefined;

    if (!queryKey) return

    const hasCache = queryClient.getQueryData(queryKey!);

    if (hasCache) {
      response = hasCache as T;
    } else {

      if (!callback) return

      response = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => {
          return await callback!()
            .then((res) => res)
            .catch(() => undefined)
        },
      });

    }

    return response;
  }

  async function setData<T>(queryKey: QueryOptions['queryKey'], data: T): Promise<void> {
    if (!queryKey) return

    queryClient.setQueryData(queryKey, data);
  }

  async function verifyCache<T>(queryKey: QueryOptions['queryKey']): Promise<T | undefined> {
    if (!queryKey) return

    const hasCache = queryClient.getQueryData(queryKey);

    return hasCache ? hasCache as T : undefined;
  }

  async function invalidateCache(queryKey: QueryOptions['queryKey']): Promise<void> {
    if (!queryKey) return

    queryClient.removeQueries({
      queryKey
    });
  }

  async function refetchData(queryKey: QueryOptions['queryKey']): Promise<void> {
    if (!queryKey) return

    queryClient.refetchQueries({ queryKey });
  }

  return {
    getData,
    invalidateCache,
    verifyCache,
    setData,
    updatedData,
    refetchData
  }
}
