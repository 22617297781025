import { useI18n, useToast } from '@group-link-one/grouplink-components';
import {
  DetailsDeviceStoreActions,
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../store/details-device-store';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { useModalAddTagContent } from '../ModalAddTag/Content/useModalAddTagContent';
import { useDeviceListAllDevices } from '../Content/useDeviceListContent';
import { queryClient } from '@/Services/queryClient';
import { useExtractURLSearch } from '@/hooks/useExtractURLSearch';
import { useHandleCache } from '@/hooks/useHandleCache';

interface ModalAddTag {
  t: (key: string) => string;
  onUnassignTag: () => Promise<void | unknown>;
  deviceListState: DetailsDeviceStoreState;
  deviceListActions: DetailsDeviceStoreActions;
}

export const useModalUnassignTag = (): ModalAddTag => {
  const { invalidateCache } = useHandleCache();
  const { addToast } = useToast();
  const { assignDeviceToTag } = useTagsService();
  const { t } = useI18n();
  const { state: deviceListState, actions: deviceListActions } =
    useDetailsDeviceStore();
  const { tagSelected } = useModalAddTagContent();

  async function onUnassignTag(): Promise<void | unknown> {
    try {
      const willBeRemoved =
        deviceListState.modalUnassignTagOptions.tagsThatWillBeRemoved!;

      await assignDeviceToTag({
        device_ids: [deviceListState.device?.device_id!],
        operations: willBeRemoved,
        preserve_existing_tags: true,
        strict: false,
        is_value_unique: false,
      });

      if (willBeRemoved.length > 1) {
        deviceListActions.setDevice({
          ...deviceListState.device!,
          tags: (deviceListState.device?.tags! || []).filter(
            (tag) => !willBeRemoved.some((item) => item.key === tag.key),
          ),
        });
      } else {
        deviceListActions.setDevice({
          ...deviceListState.device!,
          tags: (deviceListState.device?.tags! || []).filter(
            (tag) => tag.key !== tagSelected.key,
          ),
        });
      }

      deviceListActions.setModalUnassignTagOptions({
        open: false,
        tagsThatWillBeRemoved: [],
      });
      invalidateCache(['devices-activated-last-readings']);

      addToast({
        title: t('common.success'),
        message: t(
          'deviceList.detailsModal.tags.modal.toast.removeTag.description',
        ),
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: t('common.error'),
        message: t(
          'deviceList.detailsModal.tags.modal.toast.errorWhenCreate.description',
        ),
        type: 'error',
      });
      return error;
    }
  }

  return {
    t,
    deviceListState,
    deviceListActions,
    onUnassignTag,
  };
};
