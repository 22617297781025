import { BigNumberProps, Colors, GL_COLORS, GL_GLStation, GL_IDEA, useI18n } from "@group-link-one/grouplink-components"
import { useMediaQuery } from "usehooks-ts";
import LampsLitImg from '@/images/DeviceList/bigNumbers/lamps-lit.svg'
import LampsOffImg from '@/images/DeviceList/bigNumbers/lamps-off.svg'
import AlarmsImg from '@/images/DeviceList/bigNumbers/alarm.svg'

interface BigNumbersSLParams {
  lampsLitCount: number;
  lampsOffCount: number;
  alarmsCount: number;
  isLoading: boolean;
  isSM: boolean;
}

export const useNewStreetLightBigNumbers = () => {
  const isSM = useMediaQuery('(max-width: 660px)');
  const { t } = useI18n();

  const bigNumbersSL = ({ alarmsCount, lampsLitCount, lampsOffCount, isLoading, isSM }: BigNumbersSLParams): BigNumberProps[] => [
    {
      iconRight: <img src={LampsLitImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? 'scale(0.85)' : 'none',
      },
      title: t('deviceList.bigNumbers.lampsLit.title'),
      count: lampsLitCount,
      type: isSM ? 'sm' : 'lg',
      backgroundColor: Colors.LIGHT_GREEN,
      textColor: 'ULTRADARK_GREY_SECONDARY',
      tooltipText: t('deviceList.bigNumbers.lampsLit.tooltipDescription'),
      tooltipPosition: 'top-left',
      tooltipZIndex: 2,
      tooltipWidth: isSM ? 250 : 320,
      isLoading,
      isToAppear: true,
    },
    {
      iconRight: <img src={LampsOffImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? 'scale(0.85)' : 'none',
      },
      title: t('deviceList.bigNumbers.lampsOff.title'),
      count: lampsOffCount,
      type: isSM ? 'sm' : 'lg',
      backgroundColor: GL_COLORS.NAVY,
      textColor: 'FONT_COLOR_VARIANT',
      helpIconColor: 'FONT_COLOR_VARIANT',
      tooltipText: t('deviceList.bigNumbers.lampsOff.tooltipDescription'),
      tooltipPosition: 'top-left',
      tooltipZIndex: 2,
      tooltipWidth: isSM ? 250 : 320,
      isLoading,
      isToAppear: true,
    },
    {
      iconRight: <img src={AlarmsImg} alt="" />,
      iconRightStyle: {
        bottom: -10,
        right: isSM ? -40 : 20,
        transform: isSM ? 'scale(0.85)' : 'none',
        backgroundColor: 'initial'
      },
      title: t('deviceList.bigNumbers.lampsWithAlert.title'),
      count: alarmsCount,
      type: isSM ? 'sm' : 'lg',
      backgroundColor: GL_COLORS.HOVER_COLOR_DANGER,
      textColor: 'DANGER',
      countColor: 'FONT_COLOR',
      helpIconColor: "DANGER",
      tooltipText: t('deviceList.bigNumbers.lampsWithAlert.tooltipDescription'),
      tooltipPosition: 'top-left',
      tooltipZIndex: 2,
      tooltipWidth: isSM ? 250 : 320,
      isLoading,
      isToAppear: true,
    },
  ]

  return {
    bigNumbersSL,
  }
}
