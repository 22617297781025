import {
  GL_COLORS,
  GL_TAG,
  GLBox,
  GLEmptyList,
  GLList,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { TagCard } from '../Card/TagCard';
import { useTagsContent } from './useTagsContent';
import TagNotFound from '../../../images/Tags/Group 11881.svg';

export const TagsContent = (): JSX.Element => {
  const { data = [], tabs, tagsIsLoading, t, actions, isMobile } = useTagsContent();

  return (
    <GLBox
      direction="column"
      gap={10}
      style={{
        flex: 1,
      }}
    >
      <GLTypography
        text={t('tags.content.list.title')}
        color={'FONT_COLOR_DARK_GREY'}
        fontSize={4}
        style={{
          paddingBottom: 20,
        }}
      />

      {data?.length > 0 && (
        <GLList
          tabActive={t('Tags')}
          tabs={tabs}
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
          type="list"
          mode="default"
          hasFixedHeaderAnimation={false}
          hasListHeader={true}
          isLoading={tagsIsLoading}
          content={
            <>
              {data?.map((tag) => (
                <TagCard
                  key={tag?.id}
                  key_name={tag?.key}
                  id={tag?.id}
                  description={tag.description}
                  label={tag.label}
                  is_strict={tag.is_strict!}
                  created_at={tag.created_at || ''}
                />
              ))}
            </>
          }
        />
      )}

      {data?.length === 0 && !tagsIsLoading && (
        <GLEmptyList
          title={t('tags.content.list.emptyList.title')}
          description={t('tags.content.list.emptyList.description')}
          icon={<img src={TagNotFound} alt="" />}
          onClick={() => actions.setModalCreateTagIsOpen(true)}
          hasBorder
        />
      )}

      {tagsIsLoading && (
        <GLList isLoading={tagsIsLoading} type="list" mode="default" />
      )}
    </GLBox>
  );
};
