import { GL_COLORS, GLBox } from '@group-link-one/grouplink-components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HealthCheckMapHeader = styled(GLBox)`
  padding: 30px 40px;
`;

export const HealthCheckMapFooter = styled(GLBox)`
  /* padding: 30px 40px; */

  position: absolute;
  right: 40px;
  left: 30px;
  bottom: 40px;
`;

export const HealthCheckModal = styled(motion.div)`
  position: absolute;
  right: 40px;
  left: 40px;
  bottom: 20px;
  z-index: 1000;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */

  /* width: 100%; */
  height: 375px;

  padding: 20px;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border: 1px solid ${GL_COLORS.BORDER_COLOR};
  border-radius: 12px;
  /* overflow-y: auto; */

  .device-modal-informations {
    padding-bottom: 10px;

    &::-webkit-scrollbar-thumb {
      height: 5px !important;
    }

    &::-webkit-scrollbar {
      height: 5px !important;
      width: 5px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: ${GL_COLORS.DISABLED_BACKGROUND_COLOR};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${GL_COLORS.FONT_COLOR_DARK_GREY};
      border-radius: 10px;
    }
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
  height: 180px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${GL_COLORS.FONT_COLOR_DARK_GREY};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${GL_COLORS.DISABLED_BACKGROUND_COLOR};
  }
`;
