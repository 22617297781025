import { useAuth, User } from '@group-link-one/gl-utils';
import {
  CreateReportsBodyParams,
  GetReportRow,
  ReportStatus,
  ReportType,
  useCreateReportStore,
  useGetParamsToCreateReport,
  useI18n,
  useReportTypeStore,
  useToast,
} from '@group-link-one/grouplink-components';

import { useReportListService } from '../../../Services/reportListService/useReportListService';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

interface IUseModalContent {
  createReport: UseMutateAsyncFunction<
    CreateReportsBodyParams | Error | unknown
  >;
  updateReportList: (newReport: GetReportRow) => void;
  getDevicesActivated: () => void;
  isPending: boolean;
  user: User | undefined;
}

export const useModalContent = (): IUseModalContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { t } = useI18n();

  const { getParamsToCreateReport } = useGetParamsToCreateReport({ user });

  const {
    createReport: createReportFn,
    devicesActivated,
    devicesActivatedCount,
  } = useReportListService();

  const { state: reportTypeState, actions: reportTypeActions } =
    useReportTypeStore();

  const { actions: createReportActions } = useCreateReportStore();

  const queryClient = useQueryClient();

  function getReportsCached(): GetReportRow[] | undefined {
    return queryClient.getQueryData<GetReportRow[]>(['get-reports']);
  }

  function updateReportList(newReport: GetReportRow): void {
    const reportsCached = getReportsCached();

    if (reportsCached) {
      queryClient.setQueryData(
        ['get-reports'],
        reportsCached.concat(newReport),
      );
    }
  }

  function formatReportStatus(): ReportStatus {
    const reportsCached = getReportsCached();

    if (reportsCached) {
      const reportStatus = reportsCached.find(
        (report) => report.status === 'running',
      );

      if (reportStatus) {
        return 'pending';
      }
    }

    return 'running';
  }

  const { mutateAsync: createReport, isPending } = useMutation({
    mutationKey: ['create-report'],
    mutationFn: async () => {
      const reportTypeId = reportTypeState.reportTypeSelected.id;
      if (!reportTypeId) {
        return;
      }

      const paramsToCreate = getParamsToCreateReport(reportTypeId);

      if (!paramsToCreate) {
        return;
      }

      paramsToCreate.params = {
        ...paramsToCreate.params,
        device_id: reportTypeState.device_id,
      };

      if (
        reportTypeState.tagSelected &&
        reportTypeState.tagSelected.length > 0
      ) {
        paramsToCreate.params = {
          ...paramsToCreate.params,
          tag_key: reportTypeState.tagSelected[0].id,
        };
      }

      if (
        reportTypeState.tagValueSelected &&
        reportTypeState.tagValueSelected.length > 0
      ) {
        paramsToCreate.params = {
          ...paramsToCreate.params,
          tag_value: reportTypeState.tagValueSelected[0].text,
        };
      }

      if (user?.org.id === 265 && reportTypeId === 'DeviceList') {
        paramsToCreate.params = {
          ...paramsToCreate.params,
          supportsMv: false,
        };
      }

      const response = await createReportFn({
        ...paramsToCreate,
      });

      return {
        id: Number(response.id),
        ...paramsToCreate,
      };
    },
    onSuccess: (data) => {
      addToast({
        type: 'success',
        title: t('reports.toast.created.success.title'),
        message: t('reports.toast.created.success.message'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.REPORT_LIST_CREATE,
        eventDescription: 'End Report creation',
        param1: JSON.stringify({
          type: data?.type,
        }),
      });

      const newReportStatusFormatted = formatReportStatus();

      const newReport: GetReportRow = {
        id: data?.id || 0,
        name: reportTypeState.reportName,
        created_at: new Date().toISOString(),
        devices_count: 0,
        download_url: '',
        report_type: reportTypeState.reportTypeSelected.id as ReportType,
        status: newReportStatusFormatted,
        params_from: reportTypeState.reportDateRange?.from
          ? String(reportTypeState.reportDateRange?.from)
          : '',
        params_until: reportTypeState.reportDateRange?.to
          ? String(reportTypeState.reportDateRange?.to)
          : '',
      };

      updateReportList(newReport);

      createReportActions.resetAll();
      reportTypeActions.resetAll();
    },
    onError: () => {
      addToast({
        type: 'error',
        title: 'Error creating report',
        message: 'An error occurred while creating the report',
      });
    },
  });

  async function getDevicesActivated(): Promise<void> {
    await devicesActivated({
      device_name: reportTypeState.deviceNameReportFilter,
      remote_id: reportTypeState.deviceRemoteIdReportFilter,
    });

    await devicesActivatedCount({
      device_name: reportTypeState.deviceNameReportFilter,
      remote_id: reportTypeState.deviceRemoteIdReportFilter,
    }).then((res) => {
      reportTypeActions.setDeviceId(res.device_ids);

      if (res.count === 0) {
        addToast({
          type: 'warning',
          title: t(
            'reports.modalStepper.stepper.one.form.filter.toast.two.title',
          ),
          message: t(
            'reports.modalStepper.stepper.one.form.filter.toast.two.message',
          ),
        });

        return;
      }

      addToast({
        type: 'success',
        title: t(
          'reports.modalStepper.stepper.one.form.filter.toast.one.title',
        ),
        message: t(
          'reports.modalStepper.stepper.one.form.filter.toast.one.message',
          {
            count: res.count,
          },
        ),
      });
    });
  }

  return {
    createReport,
    updateReportList,
    getDevicesActivated,
    isPending,
    user,
  };
};
