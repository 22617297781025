import { useAuth } from '@group-link-one/gl-utils';
import {
  GLHeader,
  GLHeaderNavListItemProps,
} from '@group-link-one/grouplink-components';
import { Outlet } from 'react-router-dom';

import { useNotifications } from '../hooks/useNotifications';
import { useAuthServices } from '../Services/authService/useAuthServices';
import { useMenuOptions } from './useMenuOptions';

interface PageLayoutProps {
  headerNavListItems?: GLHeaderNavListItemProps[];
  style?: React.CSSProperties;
}

export const PageLayout = ({
  headerNavListItems,
  style
}: PageLayoutProps): JSX.Element => {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const { menuOptions } = useMenuOptions();

  const headerNavItemActive = headerNavListItems?.find(
    (navItem) => navItem.path === window.location.pathname,
  );

  const { allNotifications } = useNotifications();

  return (
    <>
      <GLHeader
        headerNavListItems={headerNavListItems}
        headerNavItemActive={headerNavItemActive?.id}
        user={user!}
        notifications={allNotifications}
        menuOptions={menuOptions}
        style={style}
        onLogOut={() => logOut({})}
      />
      <Outlet />
    </>
  );
};
