import {
  GLBox,
  GLButton,
  GLSelectMultiple,
  GLTextField,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { useModalAddTagContent } from './useModalAddTagContent';
import { motion } from 'framer-motion';

export const ModalAddTagContent = (): JSX.Element => {
  const {
    tagOptionsFormatted,
    t,
    errors,
    tagSelected,
    tagValuesFormatted,
    newTagValue,
    tagInfos,
    handleSubmit,
    onSubmit,
    setValue,
    onSelectTag,
    setNewTagValue,
    onSubmitTagValue,
  } = useModalAddTagContent();

  return tagOptionsFormatted && tagOptionsFormatted?.length > 0 ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '360px',
        gap: 30,
      }}
    >
      <GLSelectMultiple
        options={tagOptionsFormatted}
        optionsSelected={[]}
        canSelectMultiple={false}
        hasCheckbox={false}
        maxCharacters={20}
        hasInput
        hasSearch
        onSelectedOptionsChange={(selectedOptions) => {
          if (onSelectTag) onSelectTag(selectedOptions);
        }}
        labelOptions={{
          text: t('deviceList.detailsModal.tags.modal.selectTag'),
        }}
        inputOptions={{
          width: '100%',
          layout: 'size',
          placeholder: t('common.select'),
          failure: !!errors.tag?.message,
          failureMessage: errors.tag?.message,
          value: tagSelected.value,
        }}
        rootOptions={{
          width: '100%',
        }}
      />

      {tagSelected.id && (
        <motion.div
          initial={{
            opacity: 0,
            x: -20,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.5,
            delay: 0 * 0.15,
          }}
        >
          {tagValuesFormatted && tagValuesFormatted.length > 0 && (
            <GLSelectMultiple
              options={tagValuesFormatted!}
              optionsSelected={[]}
              maxCharacters={20}
              hasCheckbox={false}
              canSelectMultiple={false}
              hasInput
              hasSearch
              onSelectedOptionsChange={(selectedOptions) => {
                setValue('tagValue', selectedOptions[0]?.text);
              }}
              labelOptions={{
                text: t('deviceList.detailsModal.tags.modal.selectTagValue'),
              }}
              inputOptions={{
                width: '100%',
                layout: 'size',
                placeholder: t('common.select'),
                failure: !!errors.tagValue?.message,
                failureMessage: errors.tagValue?.message,
              }}
              rootOptions={{
                width: '100%',
              }}
            />
          )}

          {!tagInfos?.key.is_strict && (
            <form
              onSubmit={() => {}}
              style={{
                marginTop:
                  tagValuesFormatted && tagValuesFormatted?.length > 0 ? 30 : 0,
              }}
            >
              <GLBox align="flex-end">
                <GLBox width={350}>
                  <GLBox direction="column">
                    <GLTypography
                      text={t(
                        'deviceList.detailsModal.tags.modal.orAddNewValue',
                      )}
                      weight={600}
                    />
                    <GLTextField
                      placeholder={t(
                        'tags.content.modal.manageTag.valuesContent.form.search',
                      )}
                      onChange={(e) => setNewTagValue(e.target.value)}
                      // {...register('tagValue')}
                    />
                  </GLBox>
                </GLBox>

                <GLButton
                  align="center"
                  text={t(
                    'tags.content.modal.manageTag.valuesContent.form.btnAdd',
                  )}
                  disabled={newTagValue.length === 0}
                  variant="fill"
                  weight={600}
                  type="button"
                  style={{
                    width: 'fit-content',
                    paddingInline: 30,
                  }}
                  onClick={onSubmitTagValue}
                />
              </GLBox>
            </form>
          )}
        </motion.div>
      )}

      {/* {tagSelected && (
        <motion.div
          initial={{
            opacity: 0,
            x: -20,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.5,
            delay: 1 * 0.15,
          }}
        >
          <GLBox direction={'column'}>
            <GLBox direction={'column'}>
              <GLTypography
                text={t(
                  'tags.content.modal.assignTagToDevice.form.keepExistingTags.label',
                )}
                weight={600}
              />

              <GLCheckbox
                iconType="check"
                checked={keepExistingTags}
                onChange={() => {
                  setValue('preserve_existing_tags', keepExistingTags);
                  setKeepExistingTags(!keepExistingTags);
                }}
              />
            </GLBox>
          </GLBox>
        </motion.div>
      )} */}

      <GLButton
        text={t('common.save')}
        variant="fill"
        align="center"
        type="submit"
        weight={600}
      />
    </form>
  ) : (
    <GLBox>
      <GLTypography text="No tags created" />
    </GLBox>
  );
};
