import { useAuth, useGLFlags } from '@group-link-one/gl-utils';
import {
  AlertType,
  AlertTypeValues,
  GL_COLORS,
  GLSelectMultipleOptions,
  useHandleFilterDeviceGroup,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useJsApiLoader } from '@react-google-maps/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { subHours } from 'date-fns/subHours';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

import { env } from '../../../env';
import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  Application,
  DeviceMapResponse,
  DeviceMapV2Response,
  GetDeviceMapParams,
  GetDevicesActivatedLastReadingsResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { useThemeActiveStore } from '../../../store/theme';
import { mapDarkStyles, mapStyles } from '../../../utils/mapStyles';
import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../store/health-check-store';
import { ClustererOptions } from '@react-google-maps/marker-clusterer';

import ClusterIcon from '@/images/HealthCheck/cluster-icon';
import { getOptionsByApplication } from '@/Pages/DeviceList/utils/getOptionsByApplication';
import { UseCase } from '@/Pages/EventList/Content/Columns/AllColumns';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';
import { useTagsService } from '@/Services/tagsService/useTagsService';

const center = {
  lat: -23.5932056,
  lng: -46.6780125,
};

const clustererStylesDefault = {
  textColor: '#fff',
  textSize: 16,
  fontFamily: 'Figtree',
  fontWeight: 'semi-bold',
};

interface IUseHealthCheckMap {
  t: (key: string) => string;
  center: { lat: number; lng: number };
  map: google.maps.Map | null;
  zoom: number;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  clustererOptions: ClustererOptions;
  deviceSelected: GetDevicesActivatedLastReadingsResponse | undefined;
  currentDevices: DeviceMapResponse[] | undefined;
  markerIcon: google.maps.Icon | undefined;
  mapStyle: google.maps.MapTypeStyle[];
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  isLoaded: boolean;
  isMobile: boolean;
  onLoad: (mapInstance: google.maps.Map) => void;
  onBoundsChanged: () => void;
  onUnmount: () => void;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectUserGroup: (
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ) => void;
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectTagValue: (tagValueSelecteds: GLSelectMultipleOptions[]) => void;
  jitter: (coord: number) => number;
  handleZoomChanged: () => void;
}

export const useHealthCheckMap = (): IUseHealthCheckMap => {
  const { logEventAnalytics } = useFBAnalytics();
  const { userGroups } = useAuth();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { state: themeActiveState, actions: themeActiveActions } =
    useThemeActiveStore();

  const [groupsChildrensAvailables, setGroupsChildrensAvailables] = useState<
    GLSelectMultipleOptions[] | undefined
  >(undefined);

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles,
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(
    undefined,
  );
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [zoom, setZoom] = useState(4);

  const { getTags, getTagValue } = useTagsService();
  const { getDeviceMapV2 } = useDeviceListService();
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const { showFeature } = useGLFlags();
  const { user } = useAuth();

  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const { formatGroupList, onSelectUserGroup: onHandleSelectUserDeviceGroup } =
    useHandleFilterDeviceGroup();

  const { data: tags } = useQuery({
    queryKey: ['get-tags'],
    enabled: showFeature('show_device_tags_page'),
    queryFn: async () => {
      const response = await getTags({
        key: undefined,
      });

      return response.rows;
    },
  });

  const { data: tagValues } = useQuery({
    queryKey: ['get-tags-values', healthCheckState.tagSelecteds],
    queryFn: async () => {
      if (!healthCheckState.tagSelecteds) return [];

      const response = await getTagValue({
        key: healthCheckState.tagSelecteds[0].id,
      });

      return response.values;
    },
  });

  const onLoad = useCallback(
    function callback(mapInstance: google.maps.Map) {
      setMap(mapInstance);

      const svgIcon = {
        url:
          'data:image/svg+xml;utf-8,' +
          encodeURIComponent(`
          <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
          </svg>
        `),
        scaledSize: new window.google.maps.Size(22, 22),
      };

      setMarkerIcon(svgIcon);
    },
    [center],
  );

  const generateClusterIcon = (): string => {
    const applicationByUseCase: Record<UseCase, Application> = {
      util_kwh: 'GLUtilitiesEnergy',
      util_light: 'GLUtilitiesLight',
      util_water: 'GLUtilitiesWater',
    };

    const deviceMeasurementCategory = user?.device_measurement_categories?.length === 1
      ? user?.device_measurement_categories[0]
      : undefined;

    const options = getOptionsByApplication({
      type: applicationByUseCase[user?.use_case as UseCase],
      deviceMeasurementCategory
    });

    if (!options) {
      return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        ClusterIcon(GL_COLORS.ACCENT_COLOR),
      )}`;
    }

    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      ClusterIcon(options.color),
    )}`;
  };

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const from = useMemo(() => {
    const startDate = subHours(new Date(), 72);
    // return format(startDate, 'yyyy-MM-dd') + 'T00:00:00.00Z';
    return new Date(format(startDate, 'yyyy-MM-dd HH:mm:ss')).toISOString();
  }, []);

  const until = useMemo(() => {
    const endDate = new Date();
    // return format(endDate, 'yyyy-MM-dd') + 'T23:59:59.00Z';
    return new Date(format(endDate, 'yyyy-MM-dd HH:mm:ss')).toISOString();
  }, []);

  const tagOptionSelected: string | undefined = useMemo(() => {
    if (!healthCheckState?.tagSelecteds) {
      return undefined;
    }
    return healthCheckState?.tagSelecteds[0]?.value;
  }, [healthCheckState?.tagSelecteds]);

  const tagValueOptionSelected: string | undefined = useMemo(() => {
    if (!healthCheckState?.tagValuesSelecteds) {
      return undefined;
    }
    return healthCheckState?.tagValuesSelecteds[0]?.text;
  }, [healthCheckState?.tagValuesSelecteds]);

  const alerts = useMemo(() => {
    if (healthCheckState.alertTypesSelected?.length === 0) {
      return undefined;
    }

    return healthCheckState.alertTypesSelected?.map((alert) => alert.id);
  }, [healthCheckState.alertTypesSelected]);

  const alertsOnly: 'true' | 'false' | undefined = useMemo(() => {
    if (
      healthCheckState.alertFilterValue?.length === 0 ||
      // healthCheckState.alertFilterValue?.[0].id === 'with-alert' ||
      !healthCheckState.alertFilterValue
    ) {
      return undefined;
    }

    return healthCheckState.alertFilterValue?.[0].id === 'without-alert'
      ? 'false'
      : 'true';
    // return [...Object.values(AlertTypeValues)] as AlertType[];
  }, [healthCheckState.alertFilterValue]);

  const userGroupSelected: number[] | undefined = useMemo(() => {
    let userGroupsId: number[] | undefined = undefined;

    if (
      healthCheckState.userParentGroupSelecteds &&
      healthCheckState.userParentGroupSelecteds?.length > 0
    ) {
      userGroupsId = healthCheckState?.userParentGroupSelecteds?.map(
        (userGroup) => Number(userGroup.id),
      );
    }

    if (
      healthCheckState.userGroupSelecteds &&
      healthCheckState.userGroupSelecteds?.length > 0
    ) {
      userGroupsId = healthCheckState?.userGroupSelecteds?.map((userGroup) =>
        Number(userGroup.id),
      );
    }

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_FILTER_BY_GROUPS,
      eventDescription: 'Health check map filter by groups',
      param1: JSON.stringify(healthCheckState?.userGroupSelecteds),
    });
    return userGroupsId;
  }, [
    healthCheckState?.userGroupSelecteds,
    healthCheckState?.userParentGroupSelecteds,
  ]);

  const filterByReading: 'true' | 'false' | undefined = useMemo(() => {
    const readingValue = healthCheckState.filterByReading?.[0]?.id;

    if (!readingValue) return undefined;

    return readingValue === 'with-reading' ? 'true' : 'false';
  }, [healthCheckState.filterByReading]);

  const queryKeyMap = [
    'activateds-devices',
    alerts,
    alertsOnly,
    userGroupSelected,
    tagOptionSelected,
    tagValueOptionSelected,
    filterByReading,
  ];

  function getDeviceMapFilterByParams(
    type: keyof DeviceMapV2Response,
    devices: DeviceMapResponse[],
    geo_filter: HealthCheckStoreState['geo_filter']
  ) {
    if (!geo_filter) return devices;

    const devicesInsideOfBounds = devices.filter((device) => {
      return (
        device.lat >= geo_filter.min_lat &&
        device.lat <= geo_filter.max_lat &&
        device.long >= geo_filter.min_long &&
        device.long <= geo_filter.max_long
      );
    })

    if (type === 'devices') {
      return devicesInsideOfBounds
    }

    if (type === 'alerts') {
      return devicesInsideOfBounds.filter((device) => device.alerts.length > 0).length
    }

    if (type === 'missing_readings') {
      return devicesInsideOfBounds.filter((device) => device.has_recently_reading === false).length
    }

    if (type === 'recent_readings') {
      return devicesInsideOfBounds.filter((device) => device.has_recently_reading === true).length
    }

    if (type === 'total_devices') {
      return devicesInsideOfBounds.length
    }

  }

  function isGeoFilterCached(
    geo_filter: HealthCheckStoreState['geo_filter'],
    filters: {
      alerts: string[] | undefined;
      alertsOnly: 'true' | 'false' | undefined;
      userGroupSelected: number[] | undefined;
      tagOptionSelected: string | undefined;
      tagValueOptionSelected: string | undefined;
      filterByReading: 'true' | 'false' | undefined;
    }
  ): DeviceMapV2Response | false {
    const allDevicesCached = queryClient.getQueriesData<DeviceMapV2Response>({
      queryKey: queryKeyMap,
    });

    const allValuesAreUndefined = allDevicesCached?.every(([, value]) => !value);

    if (!allDevicesCached || !geo_filter || allValuesAreUndefined) {
      return false;
    }

    const responseCached = allDevicesCached.find(([queryKey]) => {
      const geo_filter_cached =
        queryKey[7] as HealthCheckStoreState['geo_filter'];

      if (!geo_filter_cached) return false;

      const isInside =
        geo_filter_cached.min_lat <= geo_filter.min_lat &&
        geo_filter_cached.max_lat >= geo_filter.max_lat &&
        geo_filter_cached.min_long <= geo_filter.min_long &&
        geo_filter_cached.max_long >= geo_filter.max_long;

      const groupSelectedCached = queryKey[3] as number[] | undefined;
      const groupSelected = filters.userGroupSelected;

      const groupIsEqual = !groupSelectedCached && !groupSelected
        ? true
        : groupSelectedCached?.[0] === groupSelected?.[0];

      const isFiltered =
        queryKey[1] === filters.alerts &&
        queryKey[2] === filters.alertsOnly &&
        groupIsEqual &&
        queryKey[4] === filters.tagOptionSelected &&
        queryKey[5] === filters.tagValueOptionSelected &&
        queryKey[6] === filters.filterByReading;


      return isInside && isFiltered;
    });

    const response = responseCached ? responseCached[1] as DeviceMapV2Response : false;

    if (!response) return false;

    const responseFiltered: DeviceMapV2Response = {
      alerts: getDeviceMapFilterByParams('alerts', response.devices, geo_filter) as number,
      devices: response.devices,
      missing_readings: getDeviceMapFilterByParams('missing_readings', response.devices, geo_filter) as number,
      recent_readings: getDeviceMapFilterByParams('recent_readings', response.devices, geo_filter) as number,
      total_devices: getDeviceMapFilterByParams('total_devices', response.devices, geo_filter) as number,
    }

    return responseFiltered
  }

  const onBoundsChanged = useDebounceCallback(async () => {
    if (!map) {
      return;
    }

    const zoomMap = map.getZoom();

    // if (zoomMap && zoomMap < 10) {
    //   return;
    // }

    const boundsVisible = map.getBounds();

    if (boundsVisible) {
      const northEast = boundsVisible.getNorthEast();
      const southWest = boundsVisible.getSouthWest();

      healthCheckActions.setGeoFilter({
        min_lat: southWest.lat(),
        max_lat: northEast.lat(),
        min_long: southWest.lng(),
        max_long: northEast.lng(),
      });
    }
  }, 1000);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          Sentry.captureException(error);
        },
      );
    } else {
      Sentry.captureException('Geolocation is not supported by this browser.');
    }
  }, []);

  const clustererOptions: ClustererOptions = useMemo(
    () => ({
      maxZoom: 16,
      styles: [
        ...Array.from({ length: 20 }, (_, i) => i + 1).map((i) => ({
          url: generateClusterIcon(),
          className: 'cluster-icon',
          height: 40 * i,
          width: 40 * i,
          ...clustererStylesDefault,
        })),
      ],
    }),
    [],
  );

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      // return formatGroupList(userGroups);
      // TEMPORAIRE
      const deviceGroups = userGroups?.filter(
        (group) => group.type === 'device',
      );
      return deviceGroups?.map((group) => ({
        id: String(group.id),
        text: group.name,
        value: String(group.id),
      })) as GLSelectMultipleOptions[];
    }, [userGroups]);

  const tagOptionsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tags &&
        (tags.map((tag) => ({
          id: String(tag.key),
          text: tag.label,
          value: tag.key,
        })) as GLSelectMultipleOptions[])
      );
    }, [tags]);

  const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tagValues &&
        (tagValues.map((tagValue) => ({
          id: String(tagValue.id),
          text: tagValue.value,
        })) as GLSelectMultipleOptions[])
      );
    }, [tagValues]);

  const handleZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom() ?? 10);
    }
  };

  const jitter = (coord: number) => {
    const factor = zoom >= 18 ? 0.0001 : 0; // Aumente se precisar de mais separação
    return coord + (Math.random() * factor - factor / 2);
  };

  function onSelectUserGroup(
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ): void {
    onHandleSelectUserDeviceGroup({
      userGroupsSelecteds,
      type,
      setUserGroupSelecteds: healthCheckActions.setUserGroupSelecteds,
      setUserParentGroupsSelecteds:
        healthCheckActions.setUserParentGroupSelecteds,
      setGroupsChildrensAvailables,
    });
  }

  function onSelectTag(tagSelecteds: GLSelectMultipleOptions[]): void {
    healthCheckActions.setTagSelecteds(tagSelecteds);
  }

  function onSelectTagValue(
    tagValueSelecteds: GLSelectMultipleOptions[],
  ): void {
    healthCheckActions.setTagValuesSelecteds(tagValueSelecteds);
  }

  const { data: currentDevices, isLoading: currentDevicesIsLoading } = useQuery<
    DeviceMapV2Response | undefined
  >({
    queryKey: [...queryKeyMap, healthCheckState.geo_filter],
    queryFn: async () => {
      if (!healthCheckState.geo_filter) {
        return undefined;
      }

      const devicesCacheds = isGeoFilterCached(
        healthCheckState.geo_filter,
        {
          alerts,
          alertsOnly,
          userGroupSelected,
          tagOptionSelected,
          tagValueOptionSelected,
          filterByReading,
        }
      );

      if (devicesCacheds) return devicesCacheds

      const params: GetDeviceMapParams = {
        geo_filter: healthCheckState.geo_filter,
      };

      if (alertsOnly !== undefined) {
        params.alerts_only = alertsOnly;
      }

      if (alerts && alerts?.length > 0) {
        params.alerts = alerts as AlertType[];
      }

      if (filterByReading !== undefined) {
        params.has_recently_readings = filterByReading;
      }

      if (userGroupSelected && userGroupSelected.length > 0) {
        params.group_ids = userGroupSelected;
      }

      if (tagOptionSelected && tagOptionSelected.length > 0) {
        params.tag_key = tagOptionSelected;
      }

      if (tagValueOptionSelected && tagValueOptionSelected.length > 0) {
        params.tag_value = tagValueOptionSelected;
      }

      const response = await getDeviceMapV2(params);

      return response;
    },
  });

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles);
  }, [themeActiveState.isDarkMode]);

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [themeActiveActions]);

  useEffect(() => {
    if (
      healthCheckState.deviceSelected &&
      map &&
      healthCheckState.deviceModalIsOpen
    ) {
      const latLng = new window.google.maps.LatLng(
        healthCheckState.deviceSelected.meta.latitude,
        healthCheckState.deviceSelected.meta.longitude,
      );
      map.panTo(latLng);

      const currentZoom = map.getZoom();

      const zoomTimer = setTimeout(() => {
        if (currentZoom && currentZoom >= 18) {
          return;
        }

        map.setZoom(18);
      }, 600);
      return () => clearTimeout(zoomTimer);
    }

    // if (healthCheckState.deviceSelected && map && !healthCheckState.deviceModalIsOpen) {
    //   map.setZoom(16);
    // }
  }, [
    healthCheckState.deviceSelected,
    healthCheckState.deviceModalIsOpen,
    map,
  ]);

  useEffect(() => {
    if (map && userLocation) {
      const latLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
      map.panTo(latLng);
    }
  }, [map, userLocation]);

  useEffect(() => {
    healthCheckActions.setIsFetchingDevices(currentDevicesIsLoading);
  }, [currentDevicesIsLoading]);

  useEffect(() => {
    getUserLocation();
    if (isMobile) {
      healthCheckActions.setBigNumbersIsOpen(false);
    }
  }, []);

  useEffect(() => {
    healthCheckActions.setCurrentDateRange({
      from: new Date(from),
      to: new Date(until),
    });
  }, [from, until]);

  useEffect(() => {
    healthCheckActions.setDevicesFetchedV2(currentDevices?.devices);
    healthCheckActions.setDevicesFetchedInfoV2({
      missing_readings: currentDevices?.missing_readings || 0,
      total_devices: currentDevices?.total_devices || 0,
      recent_readings: currentDevices?.recent_readings || 0,
      alerts: currentDevices?.alerts || 0,
    });
  }, [currentDevices]);

  return {
    t,
    center,
    map,
    zoom,
    clustererOptions,
    groupsChildrensAvailables,
    deviceSelected: healthCheckState.deviceSelected,
    currentDevices: currentDevices?.devices,
    markerIcon,
    mapStyle,
    healthCheckState,
    healthCheckActions,
    isLoaded,
    isMobile,
    jitter,
    handleZoomChanged,
    onBoundsChanged,
    userGroupsFormatted,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onLoad,
    onUnmount,
  };
};
