import { useAuth } from '@group-link-one/gl-utils';
import {
  GL_COLORS,
  GL_DELETE,
  GL_EQUALIZER,
  GL_HEADPHONES,
  GL_USER_CHECK,
  IMenuOptions,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useNavigate } from 'react-router-dom';

import { usePrivileges } from '../hooks/usePrivilleges';
import { useImpersonateStore } from '../Pages/Impersonate/store/impersonateModal.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { SettingsRoutes } from './usePageLayout';
import { useMyAccountService } from '@/Services/myAccountService/useMyAccountService';
import { useQueryClient } from '@tanstack/react-query';

export const useMenuOptions = (): { menuOptions: IMenuOptions[] } => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { actions } = useImpersonateStore();
  const { rootToken, setIsPending, setUser, setRootToken, setAccessToken } =
    useAuth();
  const { features } = usePrivileges();

  const isImpersonating = localStorage.getItem('isImpersonated');

  const menuOptions: IMenuOptions[] = [
    {
      icon: <GL_USER_CHECK size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t('topbar.userModal.profile'),
      onClick: () => navigate(SettingsRoutes.OVERVIEW),
    },
    {
      icon: <GL_EQUALIZER size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t('topbar.userModal.accountSettings'),
      onClick: () => navigate(SettingsRoutes.MY_ACCOUNT),
    },
  ];

  if (features.impersonation) {
    menuOptions.push({
      icon: <GL_HEADPHONES size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t('impersonate.menuOptions.supportMode'),
      onClick: () => {
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.IMPERSONATE_USER,
          eventDescription: 'Start impersonate user',
        });
        actions.setModalSteperContentIsOpen(true);
      },
    });
  }

  if (isImpersonating) {
    menuOptions.push({
      icon: <GL_DELETE size={18} fill={GL_COLORS.DANGER} />,
      text: t('impersonate.menuOptions.leaveSupportMode'),
      colorText: GL_COLORS.DANGER,
      onClick: () => {
        setUser(undefined);
        setIsPending(true);

        queryClient.clear();
        actions.resetAll();

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.IMPERSONATE_USER_LEAVE,
          eventDescription: 'Leave impersonate user',
        });

        localStorage.removeItem('isImpersonated');
        setRootToken(rootToken);
        setAccessToken(rootToken!);

        // navigate(SettingsRoutes.OVERVIEW);
        setIsPending(false);
      },
    });
  }

  return {
    menuOptions,
  };
};
