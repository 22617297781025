import {
  DataGroup,
  GL_COLORS,
  GL_GLStation,
  GLSelectMultipleOptions,
  GLTransferListSideOptions,
  GroupsDevicesStoreState,
  useGroupsDevicesStore,
  useHandleFilterDeviceGroup,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  GetDeviceInGroupsResponse,
  GetDevicesActivatedLastReadingsResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { useAuth } from '@group-link-one/gl-utils';
import { useDeviceGroupsUtils } from '../useDeviceGroupsUtils';

const IPP_DEVICES = 200;
const IPP_DEVICES_IN_GROUP = 200;

interface GetDevicesInGroupParams {
  devicesFilteredData?: GetDevicesActivatedLastReadingsResponse[];
  text?: string;
  search?: string;
  getFromCache?: boolean;
  nextPageToken?: string;
  devicesFetched?: GetDeviceInGroupsResponse[];
}

interface DevicesAvailablesCachedParams {
  devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
  hasMore: boolean;
  nextPageToken: string | undefined;
}

interface IUseGroupDevicesTransferList {
  t: (key: string) => string;
  availableDevicesSearchIsCorrect: boolean;
  devicesInGroupSearchIsCorrect: boolean;
  devicesAvailables: DataGroup[];
  devicesAdded: DataGroup[];
  leftSideOptions: GLTransferListSideOptions;
  rightSideOptions: GLTransferListSideOptions;
  groupDevicesState: GroupsDevicesStoreState;
  tagsFormatted: GLSelectMultipleOptions[] | undefined;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  features: Features;
  onSelectUserGroup: (
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ) => void;
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  onTransferItem: (items: DataGroup[], type: 'left' | 'right') => void;
  onSelectLeftItem: (item: DataGroup) => void;
  onSelectRightItem: (item: DataGroup) => void;
  onTransferAllItemsToLeft: () => void;
  onTransferAllItemsToRight: () => void;
  onSelectTagValue: (tagValueSelecteds: GLSelectMultipleOptions[]) => void;
  onSearch: (value: string, side: 'left' | 'right') => void;
}

export const useGroupDevicesTransferList = (): IUseGroupDevicesTransferList => {
  const [devicesAvailables, setDevicesAvailables] = useState<DataGroup[]>([]);
  const [devicesAvailablesData, setDevicesAvailablesData] = useState<
    GetDevicesActivatedLastReadingsResponse[]
  >([]);

  const [groupsChildrensAvailables, setGroupsChildrensAvailables] = useState<
    GLSelectMultipleOptions[] | undefined
  >(undefined);

  const [devicesAdded, setDevicesAdded] = useState<DataGroup[]>([]);
  const [devicesAddedData, setDevicesAddedData] = useState<number[]>([]);

  const [availableDevicesSearchIsCorrect, setAvailableDevicesSearchIsCorrect] =
    useState(true);
  const [devicesInGroupSearchIsCorrect, setDevicesInGroupSearchIsCorrect] =
    useState(true);

  const { t } = useI18n();
  const { features } = usePrivileges();
  const { userGroups } = useAuth();
  const { formatGroupList, onSelectUserGroup: onHandleSelectUserGroup } =
    useHandleFilterDeviceGroup();

  const { state: groupDevicesState, actions: groupDevicesActions } =
    useGroupsDevicesStore();

  const { getDeviceGroup } = useDeviceGroupsUtils();
  const { getDevicesActivatedLastReadings, getDeviceInfoInGroup } =
    useDeviceListService();
  const { getTags, getTagValue } = useTagsService();

  const userGroupSelected: number[] | undefined = useMemo(() => {
    if (
      !groupDevicesState?.userGroupSelecteds &&
      !groupDevicesState?.userParentGroupsSelecteds
    ) {
      return [];
    }

    let userGroupsId: number[] = [];

    if (
      groupDevicesState.userParentGroupsSelecteds &&
      groupDevicesState?.userParentGroupsSelecteds?.length > 0
    ) {
      userGroupsId = groupDevicesState?.userParentGroupsSelecteds?.map(
        (userGroup) => Number(userGroup.id),
      );
    }

    if (
      groupDevicesState.userGroupSelecteds &&
      groupDevicesState?.userGroupSelecteds?.length > 0
    ) {
      userGroupsId = groupDevicesState?.userGroupSelecteds?.map((userGroup) =>
        Number(userGroup.id),
      );
    }

    return userGroupsId;
  }, [
    groupDevicesState?.userGroupSelecteds,
    groupDevicesState?.userParentGroupsSelecteds,
  ]);

  const tagSelected = useMemo(() => {
    return (
      groupDevicesState.tagSelecteds && groupDevicesState.tagSelecteds[0]?.value
    );
  }, [groupDevicesState.tagSelecteds]);

  const tagValueSelected = useMemo(() => {
    return (
      groupDevicesState.tagValueSelected &&
      groupDevicesState.tagValueSelected[0]?.text
    );
  }, [groupDevicesState.tagValueSelected, tagSelected]);

  const { data: tags } = useQuery({
    queryKey: ['get-tags'],
    queryFn: async () => {
      const response = await getTags({});

      return response.rows;
    },
  });

  const { data: tagValues } = useQuery({
    queryKey: ['get-tags-values', groupDevicesState.tagSelecteds],
    queryFn: async () => {
      if (!groupDevicesState.tagSelecteds) return;

      const response = await getTagValue({
        key: groupDevicesState.tagSelecteds[0].text,
      });

      return response.values;
    },
  });

  const isEditing = false;
  const queryClient = useQueryClient();

  const tagsFormatted: GLSelectMultipleOptions[] | undefined = useMemo(() => {
    return (
      (tags &&
        (tags.map((tag) => ({
          id: String(tag.id),
          text: tag.key,
          value: tag.label,
        })) as GLSelectMultipleOptions[])) ||
      []
    );
  }, [tags]);

  const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tagValues &&
        (tagValues.map((tagValue) => ({
          id: String(tagValue.id),
          text: tagValue.value,
        })) as GLSelectMultipleOptions[])
      );
    }, [tagValues]);

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return formatGroupList(userGroups);
    }, [userGroups]);

  function onSelectUserGroup(
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ): void {
    onHandleSelectUserGroup({
      userGroupsSelecteds,
      type,
      setUserParentGroupsSelecteds:
        groupDevicesActions.setUserParentGroupsSelecteds,
      setUserGroupSelecteds: groupDevicesActions.setUserGroupSelecteds,
      setGroupsChildrensAvailables,
    });
  }

  function onSelectTag(tagSelecteds: GLSelectMultipleOptions[]): void {
    groupDevicesActions.setTagSelecteds(tagSelecteds);
    if (tagSelecteds.length > 0) {
      getDevicesAvailables({
        tag_key: tagSelecteds[0].text,
        getFromCache: tagSelecteds.length === 0,
      });

      return;
    }

    getDevicesAvailables({
      getFromCache: true,
    });
  }

  function onSelectTagValue(
    tagValueSelecteds: GLSelectMultipleOptions[],
  ): void {
    groupDevicesActions.setTagValueSelected(tagValueSelecteds);
    if (tagValueSelecteds.length > 0) {
      getDevicesAvailables({
        tag_key:
          groupDevicesState.tagSelecteds &&
          groupDevicesState.tagSelecteds[0].text,
        tag_value: tagValueSelecteds[0].text,
        getFromCache: tagValueSelecteds.length === 0,
      });

      return;
    }

    getDevicesAvailables({
      getFromCache: true,
    });
  }

  function formatDevicesAvailables(
    currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[],
  ): DataGroup[] {
    if (!currentDevicesAvailables) {
      return [];
    }

    const devicesFormatted: DataGroup[] = currentDevicesAvailables?.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      },
    );

    return devicesFormatted;
  }

  async function getMoreDevicesAvailables(): Promise<void> {
    if (
      groupDevicesState.isFetchingMoreAvailableDevices ||
      !groupDevicesState.nextPageTokenAvailableDevices
    ) {
      return;
    }

    groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

    const response = await getDevicesActivatedLastReadings({
      next_page_token: groupDevicesState.nextPageTokenAvailableDevices,
      ipp: IPP_DEVICES,
      group_ids: userGroupSelected,
      activation_reading_failover: true,
      tag_key: tagSelected,
      tag_value: tagValueSelected,
      // device_id: searchDeviceIDAvailablesDevices,
    });

    setDevicesAvailablesData([...devicesAvailablesData, ...response.rows]);

    groupDevicesActions.setIsFetchingMoreAvailableDevices(false);
    groupDevicesActions.setNextPageTokenAvailableDevices(
      response.next_page_token,
    );

    const devicesAvailablesCached: DevicesAvailablesCachedParams | undefined =
      queryClient.getQueryData(['get-devices-availables']);

    if (!devicesAvailablesCached) {
      return;
    }

    const { devicesFiltereds: currentDevicesAvailables } =
      devicesAvailablesCached;

    const newDevicesAvailables = response.rows.filter(
      (device) =>
        groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) ===
          -1 && device.groups.length === 0,
    );

    if (currentDevicesAvailables) {
      const queryKeyDevicesInGroup = [
        'get-devices-in-group',
        groupDevicesState.groupsDevicesCardInfo.id,
      ];

      let devicesAddedCached:
        | GetDevicesActivatedLastReadingsResponse[]
        | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

      const currentDevicesAvailablesWihoutAdded =
        currentDevicesAvailables.filter(
          (device) =>
            devicesAddedCached
              ?.map((item) => item.device_id)
              .indexOf(device.device_id) === -1,
        );

      const newDevicesAvailablesFormatted: DataGroup[] =
        formatDevicesAvailables(
          currentDevicesAvailablesWihoutAdded.concat(newDevicesAvailables),
        );

      setDevicesAvailables(newDevicesAvailablesFormatted);

      const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds:
          currentDevicesAvailablesWihoutAdded.concat(newDevicesAvailables),
        hasMore: response.has_more,
        nextPageToken: response.next_page_token,
      };

      queryClient.setQueryData(
        ['get-devices-availables'],
        newCachedDevicesAvailables,
      );
    }
  }

  const valueTosearchDeviceIDAvailablesDevices = (
    search: string,
  ): string | undefined => {
    return search;
  };

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-availables-transfer-list',
          count: undefined,
          id: 1,
          name: t(
            'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t(
        'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
      ),
      canTransferLeft: groupDevicesState.selectedRightDevices.length > 0,
      canTransferRight: groupDevicesState.selectedLeftDevices.length > 0,
      onScrollCallback: async () => {
        getMoreDevicesAvailables();
      },
    };
  }, [
    groupDevicesState.devices_ids,
    groupDevicesState.isFetchingMoreAvailableDevices,
    groupDevicesState.nextPageTokenAvailableDevices,
    groupDevicesState.selectedRightDevices.length,
    groupDevicesState.selectedLeftDevices.length,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-in-group-transfer-list',
          count: groupDevicesState.isFetchingMoreDevicesInGroup
            ? undefined
            : devicesAddedData.length,
          id: 1,
          name: t(
            'groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      canTransferLeft: groupDevicesState.selectedRightDevices.length > 0,
      canTransferRight: groupDevicesState.selectedLeftDevices.length > 0,
      listName: t(
        'groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne',
      ),
      onScrollCallback: async () => {},
    };
  }, [
    groupDevicesState.isFetchingMoreDevicesInGroup,
    groupDevicesState.nextPageTokenDevicesInGroup,
    groupDevicesState.selectedRightDevices.length,
    groupDevicesState.selectedLeftDevices.length,
  ]);

  async function getDevicesAvailables({
    search,
    nextPageToken = undefined,
    devicesAlreadyFeched,
    getFromCache,
    tag_key,
    tag_value,
    group_ids,
  }: {
    search?: string;
    nextPageToken?: string;
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
    getFromCache?: boolean;
    tag_key?: string;
    tag_value?: string;
    group_ids?: number[];
  }): Promise<{
    devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
    hasMore: boolean;
    nextPageToken: string | undefined;
  }> {
    const queryKey = ['get-devices-availables'];
    if (search) {
      queryKey.push(search);
    }

    if (tag_key && tagSelected) {
      queryKey.push(tagSelected);
    }

    if (tag_value && tagValueSelected) {
      queryKey.push(tagValueSelected);
    }

    if (group_ids) {
      queryKey.push(userGroupSelected);
    }

    const devicesWithoutSearchCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKey);

    if (getFromCache && devicesWithoutSearchCached && !search) {
      const devicesFormatted: DataGroup[] = formatDevicesAvailables(
        devicesWithoutSearchCached.devicesFiltereds,
      );

      setDevicesAvailablesData(devicesWithoutSearchCached.devicesFiltereds);
      setDevicesAvailables(devicesFormatted);

      return {
        devicesFiltereds: [],
        hasMore: devicesWithoutSearchCached.hasMore,
        nextPageToken: devicesWithoutSearchCached.nextPageToken,
      };
    }

    return await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

        const options: {
          ipp: number;
          next_page_token: string | undefined;
          group_ids?: number[];
          activation_reading_failover: boolean;
          text?: string;
          tag_key?: string;
          tag_value?: string;
        } = {
          ipp: IPP_DEVICES,
          next_page_token: nextPageToken,
          group_ids: userGroupSelected,
          activation_reading_failover: true,
          tag_key: tagSelected,
          tag_value: tagValueSelected,
        };

        if (search && search.length > 0) {
          options.text = valueTosearchDeviceIDAvailablesDevices(search || '');
        }

        if (tag_key && tag_key.length > 0) {
          options.tag_key = tag_key;
        }

        if (tag_value && tag_value.length > 0) {
          options.tag_value = tagValueSelected;
        }

        if (group_ids && group_ids.length > 0) {
          options.group_ids = userGroupSelected;
        }

        const availableDevices = await getDevicesActivatedLastReadings(options);

        groupDevicesActions.setIsFetchingMoreAvailableDevices(false);

        if (
          !search &&
          availableDevices.has_more &&
          availableDevices.next_page_token
        ) {
          groupDevicesActions.setNextPageTokenAvailableDevices(
            availableDevices.next_page_token,
          );
        }

        const devicesFiltereds = availableDevices.rows.filter(
          (device) =>
            groupDevicesState.devices_ids.indexOf(device.device_id) === -1 &&
            device.groups.length === 0,
        );

        const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesFiltereds);

        setDevicesAvailables(devicesFormatted);

        if (!search) {
          setDevicesAvailablesData(devicesFiltereds);
        }

        return {
          devicesFiltereds: devicesAlreadyFeched
            ? devicesAlreadyFeched.concat(devicesFiltereds)
            : devicesFiltereds,
          hasMore: availableDevices.has_more,
          nextPageToken: availableDevices.next_page_token,
        };
      },
    });
  }

  async function getDevicesInGroup({
    devicesFilteredData,
    devicesFetched,
    text,
    search,
  }: GetDevicesInGroupParams): Promise<void> {
    if (!groupDevicesState.groupsDevicesCardInfo.id) {
      return;
    }

    const queryKey = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];

    if (search) {
      queryKey.push(search);
    }

    const devicesInGroup = await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        const dataFromDeviceGroup = await getDeviceGroup(
          Number(groupDevicesState.groupsDevicesCardInfo.id),
        );

        const { devices } = dataFromDeviceGroup;

        const options: {
          device_id: number[];
        } = {
          device_id: devices,
        };

        if (search && search.length > 0) {
          options.device_id = devices.filter((device) =>
            device.toString().includes(search),
          );
        }

        if (options.device_id.length === 0) {
          options.device_id = [0];

          setDevicesAdded([]);
        }

        const response = await getDeviceInfoInGroup(options);

        return devicesFetched ? devicesFetched.concat(response) : response;
      },
    });

    const devicesFormatted: DataGroup[] = devicesInGroup.map((device) => {
      return {
        id: String(device.device_id),
        title: String(device.device_name[0]),
        body: `ID: ${device.device_id}`,
        icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
      };
    });

    const devicesAddedIDs = devicesInGroup.map((device) => device.device_id);

    setDevicesAdded(devicesFormatted);

    if (devicesFilteredData) {
      const devicesAvailablesFiltered = devicesFilteredData.filter(
        (device) => devicesAddedIDs.indexOf(device.device_id) === -1,
      );

      setDevicesAvailablesData(devicesAvailablesFiltered);
      setDevicesAvailables(formatDevicesAvailables(devicesAvailablesFiltered));
    }

    if (!search) {
      setDevicesAddedData(devicesAddedIDs);

      groupDevicesActions.setGroupsDeviceCardInfo({
        ...groupDevicesState.groupsDevicesCardInfo,
        devices_ids: devicesAddedIDs,
      });
    }
  }

  async function getMoreDevicesInGroup({
    isFetchingMoreDevicesInGroup,
    nextPageTokenDevicesInGroup,
  }: {
    isFetchingMoreDevicesInGroup?: boolean;
    nextPageTokenDevicesInGroup?: string;
  }): Promise<void> {
    if (isFetchingMoreDevicesInGroup || !nextPageTokenDevicesInGroup) {
      return;
    }

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const response = await getDevicesActivatedLastReadings({
      ipp: IPP_DEVICES_IN_GROUP,
      next_page_token: nextPageTokenDevicesInGroup,
      object_readings: true,
      all_devices: true,
      group_ids: [Number(groupDevicesState.groupsDevicesCardInfo.id)],
      activation_reading_failover: true,
      tag_key: tagSelected,
      tag_value: tagValueSelected,
    });

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
    groupDevicesActions.setNextPageTokenDevicesInGroup(
      response.next_page_token,
    );

    const devicesInGroupCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData([
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ]);

    if (!devicesInGroupCached) {
      return;
    }

    const newDevicesAvailables = devicesInGroupCached.concat(response.rows);
    const newDevicesIDs = newDevicesAvailables.map(
      (device) => device.device_id,
    );

    const newDevicesAvailablesFormatted: DataGroup[] =
      formatDevicesAvailables(newDevicesAvailables);

    setDevicesAddedData(newDevicesIDs);
    setDevicesAdded(newDevicesAvailablesFormatted);

    queryClient.setQueryData(
      ['get-devices-in-group', groupDevicesState.groupsDevicesCardInfo.id],
      newDevicesAvailables,
    );
  }

  async function verifyIfNeedToFetchMoreDevicesAvailables(
    itemsLeft: DataGroup[],
  ) {
    if (
      itemsLeft.length < 7 &&
      groupDevicesState.nextPageTokenAvailableDevices
    ) {
      await getMoreDevicesAvailables();
    }
  }

  function onSelectLeftItem(item: DataGroup): void {
    groupDevicesActions.setSelectedLeftDevices(item);

    setDevicesAvailables((prevDevicesAvailables) =>
      prevDevicesAvailables.map((device) =>
        device.id === item.id
          ? { ...device, isChecked: !device.isChecked }
          : device,
      ),
    );
  }

  function onSelectRightItem(item: DataGroup): void {
    groupDevicesActions.setSelectedRightDevices(item);

    setDevicesAdded((prevDevicesAdded) =>
      prevDevicesAdded.map((device) =>
        device.id === item.id
          ? { ...device, isChecked: !device.isChecked }
          : device,
      ),
    );
  }

  function onTransferAllItemsToLeft(): void {
    const queryKeyDevicesInGroup = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];
    const queryKeyDevicesAvailables = ['get-devices-availables'];

    let devicesAddedCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

    const devicesAvailablesObjectCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

    let {
      devicesFiltereds: devicesAvailablesCached,
      hasMore,
      nextPageToken,
    } = devicesAvailablesObjectCached || {};

    if (!devicesAddedCached || !devicesAvailablesCached) {
      return;
    }

    const devicesThatWillBeToAdded = devicesAddedCached.filter(
      (device) =>
        devicesAvailablesCached
          .map((item) => item.device_id)
          .indexOf(device.device_id) === -1,
    );

    const allDevicesAvailales = [
      ...devicesAvailablesCached,
      ...devicesThatWillBeToAdded,
    ];

    const allDevicesAvailablesMap = new Map(
      allDevicesAvailales.map((device) => [device.device_id, device]),
    );

    const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
      devicesFiltereds: Array.from(allDevicesAvailablesMap.values()),
      hasMore: Boolean(hasMore),
      nextPageToken,
    };

    queryClient.setQueryData(
      queryKeyDevicesAvailables,
      newCachedDevicesAvailables,
    );

    const devicesRightSelected = groupDevicesState.selectedRightDevices.map(
      (device) => {
        return {
          id: String(device.id),
          title: device.title,
          body: device.body,
          icon: device.icon,
        };
      },
    );

    const devicesAddedDataFormatted: number[] = devicesRightSelected.map(
      (device) => Number(device.id),
    );

    setDevicesAvailables((prevDevicesAvailables) => [
      ...devicesRightSelected,
      ...prevDevicesAvailables,
    ]);

    setDevicesAdded((prevDevicesAdded) =>
      prevDevicesAdded.filter(
        (device) =>
          !groupDevicesState.selectedRightDevices.some(
            (selectedDevice) => selectedDevice.id === device.id,
          ),
      ),
    );

    const devicesIdsThatWillBeRemoved =
      groupDevicesState.selectedRightDevices.map((device) => device.id);

    const devicesIds = devicesAddedData.filter(
      (device) => !devicesIdsThatWillBeRemoved.includes(String(device)),
    );
    groupDevicesActions.setDevicesIds(devicesIds);

    setDevicesAddedData(devicesAddedDataFormatted);

    groupDevicesActions.setSelectedRightDevices(undefined);
  }

  function onTransferAllItemsToRight(): void {
    const queryKeyDevicesInGroup = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];
    const queryKeyDevicesAvailables = ['get-devices-availables'];

    let devicesAddedCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

    const devicesAvailablesObjectCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

    let {
      devicesFiltereds: devicesAvailablesCached,
      hasMore,
      nextPageToken,
    } = devicesAvailablesObjectCached || {};

    if (!devicesAddedCached || !devicesAvailablesCached) {
      return;
    }

    const devicesThatWillBeToAdded = devicesAddedCached.filter(
      (device) =>
        devicesAvailablesCached
          .map((item) => item.device_id)
          .indexOf(device.device_id) === -1,
    );

    const allDevicesAvailales = [
      ...devicesAvailablesCached,
      ...devicesThatWillBeToAdded,
    ];

    const allDevicesAvailablesMap = new Map(
      allDevicesAvailales.map((device) => [device.device_id, device]),
    );

    const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
      devicesFiltereds: Array.from(allDevicesAvailablesMap.values()),
      hasMore: Boolean(hasMore),
      nextPageToken,
    };

    queryClient.setQueryData(
      queryKeyDevicesAvailables,
      newCachedDevicesAvailables,
    );

    const devicesLeftSelected = groupDevicesState.selectedLeftDevices.map(
      (device) => {
        return {
          id: String(device.id),
          title: device.title,
          body: device.body,
          icon: device.icon,
        };
      },
    );

    const devicesAddedDataFormatted: number[] = devicesLeftSelected.map(
      (device) => Number(device.id),
    );

    setDevicesAdded((prevDevicesAdded) => [
      ...prevDevicesAdded,
      ...devicesLeftSelected,
    ]);

    setDevicesAvailables((prevDevicesAvailables) =>
      prevDevicesAvailables.filter(
        (device) =>
          !groupDevicesState.selectedLeftDevices.some(
            (selectedDevice) => selectedDevice.id === device.id,
          ),
      ),
    );

    groupDevicesActions.setDevicesIds([
      ...devicesAddedData,
      ...devicesAddedDataFormatted,
    ]);

    setDevicesAddedData(devicesAddedDataFormatted);

    groupDevicesActions.setSelectedLeftDevices(undefined);
  }

  async function onTransferItem(
    items: DataGroup[],
    type: 'left' | 'right',
  ): Promise<void> {
    const queryKeyDevicesInGroup = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];
    const queryKeyDevicesAvailables = ['get-devices-availables'];

    let devicesAddedCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

    const devicesAvailablesObjectCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

    let {
      devicesFiltereds: devicesAvailablesCached,
      hasMore,
      nextPageToken,
    } = devicesAvailablesObjectCached || {};

    if (!devicesAddedCached || !devicesAvailablesCached) {
      return;
    }

    if (type === 'right') {
      if (!devicesAddedCached) {
        return;
      }

      const devicesThatWillBeNotRemoved = devicesAddedCached.filter(
        (device) =>
          items.map((item) => Number(item.id)).indexOf(device.device_id) !== -1,
      );

      const devicesThatWillBeToAdded = devicesAddedCached.filter(
        (device) =>
          items.map((item) => Number(item.id)).indexOf(device.device_id) === -1,
      );

      queryClient.setQueryData(
        queryKeyDevicesInGroup,
        devicesThatWillBeNotRemoved,
      );

      if (devicesThatWillBeToAdded.length > 0 && devicesAvailablesCached) {
        const allDevicesAvailales = [
          ...devicesAvailablesCached,
          ...devicesThatWillBeToAdded,
        ];

        const allDevicesAvailablesMap = new Map(
          allDevicesAvailales.map((device) => [device.device_id, device]),
        );

        const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
          devicesFiltereds: Array.from(allDevicesAvailablesMap.values()),
          hasMore: Boolean(hasMore),
          nextPageToken,
        };

        queryClient.setQueryData(
          queryKeyDevicesAvailables,
          newCachedDevicesAvailables,
        );
      }

      const devicesIds = items.map((device) => Number(device.id));

      if (groupDevicesState.devicesInGroupSearch.length === 0) {
        groupDevicesActions.setDevicesIds(devicesIds);

        const devicesAddedDataFormatted: number[] = items.map((device) =>
          Number(device.id),
        );

        setDevicesAddedData(devicesAddedDataFormatted);
      } else {
        const allDevicesID = Array.from(
          new Set(devicesAddedData.concat(devicesIds)),
        );
        setDevicesAddedData(allDevicesID);
      }

      setDevicesAdded(items);
    }

    if (type === 'left') {
      const isToUpdateAvailablesList =
        groupDevicesState.availableDevicesSearch.length === 0 &&
        items.length < 7 &&
        groupDevicesState.nextPageTokenAvailableDevices;

      setDevicesAvailables(items);

      const currentDevicesSorted = items
        .map((item) => {
          return devicesAvailablesCached?.find(
            (device) => device.device_id === Number(item.id),
          );
        })
        .filter((item) => item);

      const devicesThatWillBeToAdded = devicesAvailablesCached?.filter(
        (device) =>
          currentDevicesSorted
            .map((item) => item?.device_id)
            .indexOf(device.device_id) === -1,
      );

      if (
        devicesThatWillBeToAdded &&
        devicesThatWillBeToAdded?.length > 0 &&
        devicesAddedCached
      ) {
        const allDevicesAdded = [
          ...devicesAddedCached,
          ...devicesThatWillBeToAdded,
        ];

        const allDevicesAddedMap = new Map(
          allDevicesAdded.map((device) => [device.device_id, device]),
        );

        queryClient.setQueryData(
          queryKeyDevicesInGroup,
          Array.from(allDevicesAddedMap.values()),
        );
      }

      let newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds: currentDevicesSorted.map(
          (item) => item as GetDevicesActivatedLastReadingsResponse,
        ),
        hasMore: Boolean(hasMore),
        nextPageToken,
      };

      if (isToUpdateAvailablesList) {
        await verifyIfNeedToFetchMoreDevicesAvailables(items);

        // função acima faz o update do cache, preciso pegar o cache atualizado
        const devicesAvailablesObjectCached:
          | DevicesAvailablesCachedParams
          | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

        const { devicesFiltereds } = devicesAvailablesObjectCached || {};
        devicesAddedCached = queryClient.getQueryData(queryKeyDevicesInGroup);

        const devicesThatWillBeToAdded = devicesFiltereds?.filter(
          (device) =>
            devicesAddedCached
              ?.map((item) => item?.device_id)
              .indexOf(device.device_id) === -1,
        );

        newCachedDevicesAvailables = {
          ...newCachedDevicesAvailables,
          devicesFiltereds: devicesThatWillBeToAdded
            ? devicesThatWillBeToAdded.map(
                (device) => device as GetDevicesActivatedLastReadingsResponse,
              )
            : [],
        };
      }

      queryClient.setQueryData(
        ['get-devices-availables'],
        newCachedDevicesAvailables,
      );

      if (groupDevicesState.devicesInGroupSearch.length > 0) {
        const lastItemFromAvailables = items.slice(-1)[0];

        const newDevicesAddedData = devicesAddedData.filter(
          (device) => device !== Number(lastItemFromAvailables.id),
        );

        groupDevicesActions.setDevicesIds(
          newDevicesAddedData.map((device) => device),
        );

        setDevicesAddedData(newDevicesAddedData);
      }
    }
  }

  function onSearch(value: string, side: 'left' | 'right'): void {
    if (side === 'left') {
      groupDevicesActions.setAvailableDevicesSearch(value);
      getDevicesAvailables({
        search: value,
        getFromCache: value.length === 0,
      });
      setAvailableDevicesSearchIsCorrect(true);
    }

    if (side === 'right') {
      groupDevicesActions.setDevicesInGroupSearch(value);

      getDevicesInGroup({
        search: value,
        getFromCache: value.length === 0,
        devicesFetched: [],
      });
      setDevicesInGroupSearchIsCorrect(true);
    }
  }

  async function initOnOpenModal({
    devicesAlreadyFeched,
    nextPageTokenParam,
  }: {
    nextPageTokenParam?: string;
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
  }): Promise<void> {
    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const { devicesFiltereds, hasMore, nextPageToken } =
      await getDevicesAvailables({
        nextPageToken: nextPageTokenParam,
        devicesAlreadyFeched,
        tag_key: tagSelected,
        tag_value: tagValueSelected,
      });

    if (devicesFiltereds.length <= 7 && hasMore) {
      initOnOpenModal({
        devicesAlreadyFeched: devicesFiltereds,
        nextPageTokenParam: nextPageToken,
      });
      return;
    }

    setDevicesAdded([]);

    await getDevicesInGroup({ devicesFilteredData: devicesFiltereds });

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
  }

  useEffect(() => {
    if (groupDevicesState.openAddDevicesModal) {
      initOnOpenModal({});
    }

    if (!groupDevicesState.openAddDevicesModal) {
      setDevicesAdded([]);
    }

    groupDevicesActions.setAvailableDevicesSearch('');
    groupDevicesActions.setDevicesInGroupSearch('');
  }, [groupDevicesState.openAddDevicesModal, isEditing]);

  // useEffect(() => {
  //   groupDevicesActions.setDevicesIds(devicesAddedData);
  // }, [devicesAddedData]);

  useEffect(() => {
    if (userGroupSelected.length > 0) {
      getDevicesAvailables({
        getFromCache: false,
        group_ids: userGroupSelected,
      });
    }
  }, [userGroupSelected]);

  useEffect(() => {
    if (groupDevicesState.tagSelecteds || groupDevicesState.tagValueSelected) {
      getDevicesAvailables({
        tag_key: tagSelected,
        tag_value: tagValueSelected,
        getFromCache: false,
      });
    }
  }, [groupDevicesState.tagSelecteds, groupDevicesState.tagValueSelected]);

  useEffect(() => {
    getMoreDevicesInGroup({
      isFetchingMoreDevicesInGroup:
        groupDevicesState.isFetchingMoreDevicesInGroup,
      nextPageTokenDevicesInGroup:
        groupDevicesState.nextPageTokenDevicesInGroup,
    });
  }, [
    groupDevicesState.nextPageTokenDevicesInGroup,
    groupDevicesState.isFetchingMoreDevicesInGroup,
  ]);

  return {
    t,
    availableDevicesSearchIsCorrect,
    devicesInGroupSearchIsCorrect,
    devicesAvailables,
    devicesAdded,
    leftSideOptions,
    rightSideOptions,
    groupDevicesState,
    tagsFormatted,
    userGroupsFormatted,
    features,
    groupsChildrensAvailables,
    tagValuesFormatted,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onTransferItem,
    onSelectLeftItem,
    onSelectRightItem,
    onTransferAllItemsToLeft,
    onTransferAllItemsToRight,
    onSearch,
  };
};
