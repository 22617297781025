import {
  GL_PLUS,
  GLBox,
  GLButton,
  GLExtendedTextField,
} from '@group-link-one/grouplink-components';

import { useTagsRightContent } from './useTagsRightContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useGLFlags } from '@group-link-one/gl-utils';

export const TagsRightContent = (): JSX.Element => {
  const { isMobile, actions, inputSearchRef, t, logEventAnalytics } =
    useTagsRightContent();

  const { showFeature } = useGLFlags();

  return (
    <GLBox justify="flex-end" gap={20}>
      <GLExtendedTextField ref={inputSearchRef} />

      {showFeature('action_btn_create_new_device_tag') && (
        <GLButton
          text={isMobile ? '' : t('tags.content.rightContent.btnCreateNewTag')}
          align="center"
          variant="fill"
          weight={600}
          padding
          style={{
            width: !isMobile ? 'fit-content' : '100%',
          }}
          icon={<GL_PLUS />}
          onClick={() => {
            logEventAnalytics({
              eventName: FBAnalyticsEventTitles.TAGS_CREATE,
              eventDescription: 'Start tag creation',
            });
            actions.setModalType('creation');
            actions.setModalCreateTagIsOpen(true);
          }}
        />
      )}
    </GLBox>
  );
};
