import { useI18n } from '@group-link-one/grouplink-components';

import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../../stores/tags.store';
import { useModalAssignValuesToTagContent } from '../Content/useModalAssignValuesToTagContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useHandleCache } from '@/hooks/useHandleCache';

interface IUseModalRemoveValue {
  t: (key: string) => string;
  tagsStoreState: TagsStoreState;
  tagsStoreActions: TagsStoreActions;
  onRemoveValue: () => Promise<void | unknown>;
}

export const useModalRemoveValue = (): IUseModalRemoveValue => {
  const { logEventAnalytics } = useModalAssignValuesToTagContent();
  const { invalidateCache } = useHandleCache();
  const { deleteTagValue } = useTagsService();

  const { state: tagsStoreState, actions: tagsStoreActions } = useTagsStore();

  const { t } = useI18n();

  async function onRemoveValue(): Promise<void | unknown> {
    if (tagsStoreState.activeTagValueToDelete) {
      const activeTagValue = tagsStoreState.activeTagValueToDelete;

      try {
        await deleteTagValue(Number(activeTagValue.id));
        invalidateCache([
          'get-tag-values',
          tagsStoreState.modalType === 'edition',
          tagsStoreState.activeTag?.key.id,
        ]);
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.TAGS_MANAGE_DELETE_VALUE,
          eventDescription: 'End Tag Value deleted',
        });
        tagsStoreActions.setActiveTagValueToDelete(undefined);
      } catch (error) {
        return error;
      }
    }
  }

  return {
    t,
    tagsStoreState,
    tagsStoreActions,
    onRemoveValue,
  };
};
