import {
  AlertType,
  AlertTypeValues,
  Colors,
  GL_COLORS,
  GLPaginationStoreActions,
  GLPaginationStoreState,
  OnInfiniteScrollParams,
  RegisterDeviceStoreActions,
  TabProps,
  useDeviceListStore as useGLDeviceListStore,
  useGLPagination,
  useI18n,
  useInfiniteScroll,
  useRegisterDeviceStore,
  useToast,
} from '@group-link-one/grouplink-components';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useEffect, useMemo, useRef } from 'react';

import { useExtractURLSearch } from '../../../hooks/useExtractURLSearch';
import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  DeviceMeasurementCategory,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';
import {
  DetailsDeviceStoreActions,
  useDetailsDeviceStore,
} from '../store/details-device-store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import * as Sentry from '@sentry/react';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { getOptionsByApplication } from '../utils/getOptionsByApplication';
import { useAuth, User } from '@group-link-one/gl-utils';
import {
  DeviceListStoreActions,
  useDeviceListStore,
} from '../store/device-list-store';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';

export const DEVICE_LIST_IPP = 50;
type CacheKeyMap = DeviceMeasurementCategory | 'none';

interface IUseDeviceListAllDevices {
  activeTab: TabProps | undefined;
  currentDeviceList:
    | GetDevicesActivatedLastReadingsResponse[]
    | GetDevicesPendingActivationResponse[];
  deviceList: GetDevicesActivatedLastReadingsResponse[] | undefined;
  deviceListIsErrored: boolean;
  deviceListIsLoading: boolean;
  currentDeviceListIsLoading: boolean;
  paginationState: GLPaginationStoreState;
  paginationActions: GLPaginationStoreActions;
  user: User | undefined;
  listContainerRef: React.RefObject<HTMLDivElement>;
  tabs: TabProps[];
  features: Features;
  devicesCount: number;
  detailsDeviceActions: DetailsDeviceStoreActions;
  registerDeviceActions: RegisterDeviceStoreActions;
  deviceListActions: DeviceListStoreActions;
  streetLightValues: { label: string; color: string }[];
  isStreetLight: boolean;
  isToShowDeviceList: boolean;
  isToShowEmptyState: boolean;
  isToShowError: boolean;
  isToShowStreetLightLegend: boolean;
  getMoreDevicesOnScroll: () => Promise<void>;
  onInfiniteScroll: ({
    callback,
    event,
    scrollTrigger,
  }: OnInfiniteScrollParams) => Promise<void>;
}

export const useDeviceListAllDevices = (): IUseDeviceListAllDevices => {
  const { t } = useI18n();
  const { logEventAnalytics } = useFBAnalytics();
  const { user } = useAuth();

  const { getDevicesActivatedLastReadings, getDevicesPendingActivation } =
    useDeviceListService();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const { actions: registerDeviceActions } = useRegisterDeviceStore();
  const { actions: deviceListActions } = useDeviceListStore();
  const { state: deviceListStateGL } = useGLDeviceListStore();

  const queryClient = useQueryClient();

  const { getURLParam, setURLParams } = useExtractURLSearch();
  const { features } = usePrivileges();

  const queryDeviceID = getURLParam('q');
  const queryTabActive = getURLParam('tab');

  const { addToast } = useToast();
  const { onInfiniteScroll } = useInfiniteScroll();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const searchDeviceID = useMemo(() => {
    if (
      paginationState.search.length === 0 &&
      queryDeviceID &&
      queryDeviceID.length > 0
    ) {
      return isNaN(Number(queryDeviceID)) ? undefined : [Number(queryDeviceID)];
    }

    if (paginationState.search.length === 0) {
      return undefined;
    }

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_SEARCH,
      eventDescription: 'Start Search Device',
      param1: paginationState.search,
    });

    return isNaN(Number(paginationState.search))
      ? undefined
      : [Number(paginationState.search)];
  }, [paginationState.search, queryDeviceID]);

  const textSearch = useMemo(() => {
    if (
      paginationState.search.length === 0 &&
      queryDeviceID &&
      queryDeviceID.length > 0
    ) {
      return queryDeviceID;
    }

    if (paginationState.search.length === 0) {
      return undefined;
    }

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_SEARCH,
      eventDescription: 'Start Search Device',
      param1: paginationState.search,
    });

    return paginationState.search;
  }, [paginationState.search, queryDeviceID]);

  const useCasesSelected: DeviceMeasurementCategory | undefined =
    useMemo(() => {
      return (
        deviceListStateGL?.useCaseOptionsSelecteds?.map(
          (useCase) => useCase.id as DeviceMeasurementCategory,
        )[0] || undefined
      );
    }, [deviceListStateGL?.useCaseOptionsSelecteds]);

  const userGroupSelected: number[] | undefined = useMemo(() => {
    if (
      !deviceListStateGL?.userGroupSelecteds &&
      !deviceListStateGL?.userParentGroupsSelecteds
    ) {
      return [];
    }

    let userGroupsId: number[] = [];

    if (
      deviceListStateGL.userParentGroupsSelecteds &&
      deviceListStateGL?.userParentGroupsSelecteds?.length > 0
    ) {
      userGroupsId = deviceListStateGL?.userParentGroupsSelecteds?.map(
        (userGroup) => Number(userGroup.id),
      );
    }

    if (
      deviceListStateGL.userGroupSelecteds &&
      deviceListStateGL?.userGroupSelecteds?.length > 0
    ) {
      userGroupsId = deviceListStateGL?.userGroupSelecteds?.map((userGroup) =>
        Number(userGroup.id),
      );
    }

    return userGroupsId;
  }, [
    deviceListStateGL?.userGroupSelecteds,
    deviceListStateGL?.userParentGroupsSelecteds,
  ]);

  const tagOptionSelected: string | undefined = useMemo(() => {
    if (!deviceListStateGL?.tagSelecteds) {
      return undefined;
    }
    return deviceListStateGL?.tagSelecteds[0]?.id;
  }, [deviceListStateGL?.tagSelecteds]);

  const tagValueOptionSelected: string | undefined = useMemo(() => {
    if (!tagOptionSelected) return undefined;

    if (!deviceListStateGL?.tagValueSelected) return undefined;

    return deviceListStateGL?.tagValueSelected[0]?.text;
  }, [deviceListStateGL?.tagValueSelected, tagOptionSelected]);

  const lampStatusSelected: 'on' | 'off' | undefined = useMemo(() => {
    if (
      !deviceListStateGL?.lampStatusSelected ||
      deviceListStateGL?.lampStatusSelected.length === 0
    ) {
      return undefined;
    }

    /**
     * Filtrar por aceso
     * alerts = 'LIGHT_OUTPUT_STATUS'
     *
     * Filtrar pelos desligados
     * not_alerts = 'LIGHT_OUTPUT_STATUS'
     */

    const lampStatusMap: Record<string, 'on' | 'off'> = {
      '1': 'on',
      '2': 'off',
    };

    const lampStatusID = deviceListStateGL?.lampStatusSelected[0]?.id;

    return lampStatusID in lampStatusMap
      ? lampStatusMap[lampStatusID]
      : undefined;
  }, [deviceListStateGL?.lampStatusSelected]);

  // const filterAlerts: Partial<GetDevicesActivatedLastReadingsParams> | undefined = useMemo(() => {
  //   const filterAlertValue = deviceListStateGL?.alertSelected?.[0]?.id
  //   const isFilterByWithAlert = filterAlertValue === '1'
  //   const allAlerts = Object.values(AlertTypeValues) as AlertType[]

  //   if (!filterAlertValue) return undefined

  //   if (isFilterByWithAlert) {
  //     return { alerts: allAlerts }
  //   }

  //   return {
  //     not_alerts: allAlerts
  //   }
  // }, [deviceListStateGL.alertSelected]);

  const from = useMemo(() => {
    if (!deviceListStateGL?.range?.from) {
      return undefined;
    }
    return format(deviceListStateGL?.range?.from || new Date(), 'yyyy-MM-dd');
  }, [deviceListStateGL?.range?.from]);

  const to = useMemo(() => {
    if (!deviceListStateGL?.range?.to) {
      return undefined;
    }
    return format(deviceListStateGL?.range?.to || new Date(), 'yyyy-MM-dd');
  }, [deviceListStateGL?.range?.to]);

  const cacheKeyByUseCase = useMemo(() => {
    const useCaseSelected =
      deviceListStateGL?.useCaseOptionsSelecteds?.map(
        (useCase) => useCase.id as DeviceMeasurementCategory,
      )[0] || undefined;

    const cacheKeyMap: Record<CacheKeyMap, string> = {
      energy: 'energy-devices',
      gas: 'gas-devices',
      pressure: 'pressure-devices',
      water: 'water-devices',
      light: 'light-devices',
      none: 'all-use-cases',
    };

    return useCaseSelected && useCaseSelected in cacheKeyMap
      ? cacheKeyMap[useCaseSelected]
      : 'all-devices';
  }, [deviceListStateGL?.useCaseOptionsSelecteds]);

  const cacheKeyParam = useMemo(() => {
    let cacheKeyFormatted = '';
    const userGroupSelectedText =
      userGroupSelected && userGroupSelected.length > 0
        ? userGroupSelected.join('-')
        : 'all-groups';

    if (cacheKeyByUseCase) cacheKeyFormatted += cacheKeyByUseCase + '-';
    if (userGroupSelectedText) cacheKeyFormatted += userGroupSelectedText + '-';
    if (textSearch) cacheKeyFormatted += textSearch + '-';
    if (from) cacheKeyFormatted += from + '-';
    if (to) cacheKeyFormatted += to;
    if (tagOptionSelected) cacheKeyFormatted += tagOptionSelected + '-';
    if (tagValueOptionSelected)
      cacheKeyFormatted += tagValueOptionSelected + '-';
    if (lampStatusSelected) cacheKeyFormatted += lampStatusSelected + '-';
    // if (filterAlerts && filterAlerts.alerts) cacheKeyFormatted += 'with-filter-alerts-'
    // if (filterAlerts && filterAlerts.not_alerts) cacheKeyFormatted += 'without-filter-alerts-';

    if (activeTab?.id === 1) {
      cacheKeyFormatted += '-all-devices';
    }
    if (activeTab?.id === 2) {
      cacheKeyFormatted += '-without-readings';
    }
    if (activeTab?.id === 3) {
      cacheKeyFormatted += '-pending-devices';
    }

    return cacheKeyFormatted;
  }, [
    cacheKeyByUseCase,
    userGroupSelected,
    textSearch,
    from,
    to,
    activeTab?.id,
    tagOptionSelected,
    tagValueOptionSelected,
    lampStatusSelected,
    // filterAlerts
  ]);

  const isStreetLight = user?.use_case === 'util_light';
  const isTabActivatedOrWithoutReadings = Boolean(
    activeTab?.id && [1, 2].includes(activeTab?.id),
  );
  const isToShowStreetLightLegend =
    isStreetLight && isTabActivatedOrWithoutReadings;

  const queryKeyDevicesActivated = [
    'devices-activated-last-readings',
    textSearch,
    useCasesSelected,
    userGroupSelected,
    from,
    to,
    tagOptionSelected,
    tagValueOptionSelected,
    lampStatusSelected || 'no-status',
    // filterAlerts || "undef-alerts"
  ];

  const queryKeyDevicesActivatedWithoutReadings = [
    'devices-activated-last-readings-without-readings',
    textSearch,
    useCasesSelected,
    userGroupSelected,
    from,
    to,
    tagOptionSelected,
    tagValueOptionSelected,
    lampStatusSelected || 'no-status',
    // filterAlerts || "alerts"
  ];

  const streetLightValues = [
    {
      label: t('deviceList.legend.lampsLit'),
      color: getOptionsByApplication({ type: 'GLUtilitiesLight' }).color,
    },
    {
      label: t('deviceList.legend.lampsOff'),
      color: getOptionsByApplication({ type: 'GLUtilitiesLight', off: true })
        .color,
    },
    {
      label: t('deviceList.legend.withAlert'),
      color: GL_COLORS.DANGER,
    },
  ];

  function getOptionsToLastReading({
    params,
    textSearchParam,
    useCasesSelectedParam,
    lampStatusSelected,
  }: {
    params: GetDevicesActivatedLastReadingsParams;
    textSearchParam: string | undefined;
    useCasesSelectedParam: DeviceMeasurementCategory | undefined;
    lampStatusSelected: 'on' | 'off' | undefined;
  }): GetDevicesActivatedLastReadingsParams {
    const options: GetDevicesActivatedLastReadingsParams = {
      ipp: DEVICE_LIST_IPP,
      next_page_token: undefined,
      object_readings: true,
      all_devices: true,
      activation_reading_failover: true,
      ...params,
    };

    const useCaseSelectedIsStreetLight =
      user?.use_case === 'util_light' ||
      (useCasesSelectedParam && useCasesSelectedParam === 'light');

    options.device_id = undefined;
    options.text = textSearchParam;

    if (useCaseSelectedIsStreetLight) {
      options.application = 'GLUtilitiesLight';

      if (lampStatusSelected && lampStatusSelected === 'on') {
        // options.alerts = 'LIGHT_PHOTOCELL_STATUS';
        options.alerts = ['LIGHT_OUTPUT_STATUS'];
      }

      if (lampStatusSelected && lampStatusSelected === 'off') {
        options.not_alerts = ['LIGHT_OUTPUT_STATUS'];
        // options.not_alerts = 'LIGHT_PHOTOCELL_STATUS';
        // options.alerts = undefined;
      }

      if (!lampStatusSelected) {
        options.alerts = undefined;
      }
    } else {
      options.device_measurement_category = useCasesSelectedParam;
    }

    return options;
  }

  const {
    data: deviceList,
    isLoading: deviceListIsLoading,
    isError: deviceListIsErrored,
  } = useQuery({
    queryKey: queryKeyDevicesActivated,
    enabled: activeTab?.id === 1 && !detailsDeviceState.modalIsOpen,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      const options: GetDevicesActivatedLastReadingsParams =
        getOptionsToLastReading({
          params: {
            group_ids: userGroupSelected,
            activated_at_since: from,
            activated_at_until: to,
            has_readings: true,
            tag_key: tagOptionSelected,
            tag_value: tagValueOptionSelected,
            activation_reading_failover: true,
            // ...filterAlerts
          },
          textSearchParam: textSearch,
          useCasesSelectedParam: useCasesSelected,
          lampStatusSelected,
        });

      try {
        const response = await getDevicesActivatedLastReadings(options);

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
          cacheKeyParam: cacheKeyParam,
        });

        return response.rows;
      } catch (err: unknown) {
        Sentry.captureException(err);
        if (err instanceof AxiosError) {
          addToast({
            type: 'error',
            title: 'Device ID not found',
            message: 'Please enter a valid Device ID',
          });
        }
      }
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: deviceListWithoutReadings,
    isLoading: deviceListIsLoadingWithoutReadings,
    isError: deviceListIsErroredWithoutReadings,
  } = useQuery({
    queryKey: queryKeyDevicesActivatedWithoutReadings,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      const options: GetDevicesActivatedLastReadingsParams =
        getOptionsToLastReading({
          params: {
            group_ids: userGroupSelected,
            activated_at_since: from,
            activated_at_until: to,
            has_readings: false,
            tag_key: tagOptionSelected,
            tag_value: tagValueOptionSelected,
            activation_reading_failover: true,
            // ...filterAlerts
          },
          textSearchParam: textSearch,
          useCasesSelectedParam: useCasesSelected,
          lampStatusSelected,
        });

      try {
        const response = await getDevicesActivatedLastReadings(options);

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
          cacheKeyParam: cacheKeyParam,
        });

        return response.rows;
      } catch (err: unknown) {
        Sentry.captureException(err);
        if (err instanceof AxiosError) {
          addToast({
            type: 'error',
            title: 'Device ID not found',
            message: 'Please enter a valid Device ID',
          });
        }
      }
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: pendingDeviceList,
    isLoading: pendingDeviceListIsLoading,
    isError: pendingDeviceListIsErrored,
  } = useQuery({
    queryKey: ['devices-pendings-last-readings', searchDeviceID],
    enabled: activeTab?.id === 3,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      try {
        const response = await getDevicesPendingActivation({
          ipp: DEVICE_LIST_IPP,
          next_page_token: undefined,
          device_id: searchDeviceID,
        });

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
        });

        return response.rows;
      } catch (err: unknown) {
        Sentry.captureException(err);
        if (err instanceof AxiosError) {
          addToast({
            type: 'error',
            title: 'Device not found',
            message: 'Please review your search',
          });
        }
      }
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  async function getMoreDevicesOnScroll(): Promise<void> {
    const activeTabIdMap = {
      'tab-1': {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesActivatedLastReadings(options);
        },
        queryKey: queryKeyDevicesActivated,
        getOptions: (nextPageToken: string) =>
          getOptionsToLastReading({
            params: {
              group_ids: userGroupSelected,
              activated_at_since: from,
              activated_at_until: to,
              has_readings: true,
              tag_key: tagOptionSelected,
              tag_value: tagValueOptionSelected,
              activation_reading_failover: true,
              next_page_token: nextPageToken,
            },
            textSearchParam: textSearch,
            useCasesSelectedParam: useCasesSelected,
            lampStatusSelected,
          }),
      },
      'tab-2': {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesActivatedLastReadings(options);
        },
        queryKey: queryKeyDevicesActivatedWithoutReadings,
        getOptions: (nextPageToken: string) =>
          getOptionsToLastReading({
            params: {
              group_ids: userGroupSelected,
              activated_at_since: from,
              activated_at_until: to,
              has_readings: false,
              tag_key: tagOptionSelected,
              tag_value: tagValueOptionSelected,
              activation_reading_failover: true,
              next_page_token: nextPageToken,
            },
            textSearchParam: textSearch,
            useCasesSelectedParam: useCasesSelected,
            lampStatusSelected,
          }),
      },
      'tab-3': {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesPendingActivation(options);
        },
        queryKey: ['devices-pendings-last-readings', searchDeviceID],
        getOptions: (nextPageToken: string) => ({
          ipp: DEVICE_LIST_IPP,
          next_page_token: nextPageToken,
          device_id: searchDeviceID,
        }),
      },
    };

    if (paginationState.isFetchingInfiniteScroll) {
      return;
    }

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken(
        activeTab?.id && [1, 2].includes(activeTab?.id)
          ? cacheKeyParam
          : undefined,
      );

    if (!nextPageToken) {
      return;
    }

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const options: GetDevicesActivatedLastReadingsParams =
      tabMapActive.getOptions(nextPageToken);

    paginationActions.setIsFetchingInfiniteScroll(true);

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
      cacheKeyParam:
        activeTab?.id && [1, 2].includes(activeTab?.id)
          ? cacheKeyParam
          : undefined,
    });

    const currentDeviceList:
      | GetDevicesActivatedLastReadingsResponse[]
      | GetDevicesPendingActivationResponse[]
      | undefined = queryClient.getQueryData(tabMapActive.queryKey);

    if (!currentDeviceList) {
      return;
    }

    queryClient.setQueryData(tabMapActive.queryKey, [
      ...currentDeviceList,
      ...response.rows,
    ]);
  }

  function invalidateAllQueries(): void {
    queryClient.invalidateQueries({
      queryKey: ['devices-activated-last-readings'],
    });
  }

  const currentDeviceList = useMemo(() => {
    const activeTabIdMap = {
      'tab-1': deviceList,
      'tab-2': deviceListWithoutReadings,
      'tab-3': pendingDeviceList,
    };

    return (
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap] ||
      []
    );
  }, [activeTab?.id, deviceList, pendingDeviceList, deviceListWithoutReadings]);

  const currentDeviceListIsLoading = useMemo(() => {
    const activeTabIdMap = {
      'tab-1': deviceListIsLoading,
      'tab-2': deviceListIsLoadingWithoutReadings,
      'tab-3': pendingDeviceListIsLoading,
    };

    return activeTabIdMap[
      `tab-${activeTab?.id}` as keyof typeof activeTabIdMap
    ];
  }, [
    activeTab?.id,
    deviceListIsLoading,
    pendingDeviceListIsLoading,
    deviceListIsLoadingWithoutReadings,
  ]);

  const currentDeviceListIsErrored = useMemo(() => {
    const activeTabIdMap = {
      'tab-1': deviceListIsErrored,
      'tab-2': deviceListIsErroredWithoutReadings,
      'tab-3': pendingDeviceListIsErrored,
    };

    return activeTabIdMap[
      `tab-${activeTab?.id}` as keyof typeof activeTabIdMap
    ];
  }, [
    activeTab?.id,
    deviceListIsErrored,
    pendingDeviceListIsErrored,
    deviceListIsErroredWithoutReadings,
  ]);

  const isToShowDeviceList =
    !currentDeviceListIsLoading &&
    !currentDeviceListIsLoading &&
    currentDeviceList &&
    currentDeviceList.length > 0;

  const isToShowEmptyState =
    !currentDeviceListIsErrored &&
    currentDeviceList &&
    currentDeviceList.length === 0;

  const isToShowError =
    !currentDeviceListIsLoading && currentDeviceListIsErrored;

  useEffect(() => {
    const tabActivatedDevices = paginationState.tabs.find(
      (tab) => tab.id === 1,
    );
    const tabWithoutReadings = paginationState.tabs.find((tab) => tab.id === 2);
    const tabPendingDevices = paginationState.tabs.find((tab) => tab.id === 3);

    if (tabActivatedDevices) {
      paginationActions.tabsActions.setOnClick(tabActivatedDevices, () => {
        paginationActions.setActiveTab(1);
        setURLParams({ tab: '1' });
      });
    }

    if (tabWithoutReadings) {
      paginationActions.tabsActions.setOnClick(tabWithoutReadings, () => {
        paginationActions.setActiveTab(2);
        setURLParams({ tab: '2' });
      });
    }

    if (tabPendingDevices) {
      paginationActions.tabsActions.setOnClick(tabPendingDevices, () => {
        paginationActions.setActiveTab(3);
        setURLParams({ tab: '3' });
      });
    }
  }, []);

  useEffect(() => {
    invalidateAllQueries();

    if (queryDeviceID) {
      paginationActions.setSearch(queryDeviceID);
    }

    if (queryTabActive) {
      paginationActions.setActiveTab(Number(queryTabActive));
    }
  }, []);

  useEffect(() => {
    paginationActions.setIsLoading(deviceListIsLoading);
  }, [deviceListIsLoading]);

  useEffect(() => {
    setURLParams({ q: paginationState.search });
  }, [paginationState.search]);

  return {
    user,
    features,
    activeTab,
    currentDeviceList,
    deviceList,
    deviceListIsErrored,
    deviceListIsLoading,
    currentDeviceListIsLoading,
    detailsDeviceActions,
    deviceListActions,
    registerDeviceActions,
    paginationState,
    paginationActions,
    listContainerRef,
    tabs: paginationState.tabs,
    devicesCount: 0,
    streetLightValues,
    isStreetLight,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    isToShowStreetLightLegend,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  };
};
