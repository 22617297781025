import {
  GL_COLORS,
  GL_GLStation,
  GLBox,
  GLList,
  GLLoading,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { DeviceListCard } from '../Cards/DeviceListCard';
import {
  DeviceListAllDevicesContainer,
  DeviceListEmpty,
} from './DeviceListContentStyle';
import { useDeviceListAllDevices } from './useDeviceListContent';

export function DeviceListContent(): JSX.Element {
  const {
    user,
    activeTab,
    features,
    currentDeviceList,
    paginationState,
    listContainerRef,
    detailsDeviceActions,
    registerDeviceActions,
    deviceListActions,
    tabs,
    streetLightValues,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    isToShowStreetLightLegend,
    isStreetLight,
    currentDeviceListIsLoading,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  } = useDeviceListAllDevices();

  return (
    <DeviceListAllDevicesContainer
      ref={listContainerRef}
      gap={30}
      direction="column"
      height="100%"
    >
      <GLList
        tabs={tabs}
        tabActive={activeTab?.name || ''}
        hasFixedHeaderAnimation={false}
        isLoading={currentDeviceListIsLoading}
        mode="infinit-scroll"
        type={isToShowEmptyState || isToShowError ? 'empty' : 'list'}
        listName="Device List"
        onScroll={(event) =>
          onInfiniteScroll({ event, callback: getMoreDevicesOnScroll })
        }
        filtersBottom={
          isToShowStreetLightLegend ? (
            <GLBox gap={30} width="fit-content">
              {streetLightValues.map((item) => (
                <GLBox key={item.label} gap={10} align='center'>
                  <GLBox
                    width={6}
                    height={6}
                    style={{
                      backgroundColor: item.color,
                    }}
                  />
                  <GLTypography
                    text={item.label}
                    color="FONT_COLOR"
                    fontSize={3}
                    weight={600}
                    as="p"
                    style={{ whiteSpace: 'nowrap' }}
                  />
                </GLBox>
              ))}
            </GLBox>
          ) : undefined
        }
        content={
          <>
            {isToShowDeviceList &&
              currentDeviceList!.map((item) => (
                <DeviceListCard
                  key={item.device_id}
                  item={item}
                  user={user}
                  detailsDeviceActions={detailsDeviceActions}
                  registerDeviceActions={registerDeviceActions}
                  deviceListActions={deviceListActions}
                  features={features}
                  isStreetLight={isStreetLight}
                />
              ))}

            {isToShowEmptyState && (
              <DeviceListEmpty>
                <div>
                  <GL_GLStation
                    size={30}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                </div>

                <GLTypography
                  text="No GL device found"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={5}
                  weight={600}
                  as="p"
                />
              </DeviceListEmpty>
            )}

            {isToShowError && (
              <DeviceListEmpty>
                <div>
                  <GL_GLStation
                    size={30}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                </div>

                <GLTypography
                  text="No GL device found"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={5}
                  weight={600}
                  as="p"
                />
              </DeviceListEmpty>
            )}

            {paginationState.isFetchingInfiniteScroll && (
              <GLBox
                align="center"
                justify="center"
                style={{ marginBlock: 20 }}
              >
                <GLLoading type="dots" size="primary" />
              </GLBox>
            )}
          </>
        }
      />
    </DeviceListAllDevicesContainer>
  );
}
