import { useQueryClient } from '@tanstack/react-query';

import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import {
  GetTagRow,
  Key,
} from '../../../../Services/tagsService/useTagsService.type';
import { useTagsStore } from '../../stores/tags.store';
import * as Sentry from '@sentry/react';

interface ManageTagClick {
  onManageTagClick: (tag: GetTagRow) => Promise<void>;
}

export const useManageTagClick = (): ManageTagClick => {
  const { actions: tagsStoreActions, state: tagsStoreState } = useTagsStore();

  const { getTagById } = useTagsService();
  const queryClient = useQueryClient();

  async function onManageTagClick(tag: GetTagRow): Promise<void> {
    tagsStoreActions.setDataActiveTagIsLoading(true);
    tagsStoreActions.setActiveTagId(tag.id);

    await queryClient.fetchQuery({
      queryKey: ['get-tag-by-id', tag.id],
      queryFn: async () => {
        try {
          const response = await getTagById({ key: tag.key });

          setTimeout(() => {
            tagsStoreActions.setActiveTag({
              ...response,
              key: tag.key as unknown as Key,
              is_strict: tagsStoreState.activeTag?.is_strict!,
            });
          }, 500);
        } catch (error: unknown) {
          Sentry.captureException(error);
          tagsStoreActions.setActiveTag(undefined);
          return Promise.reject(error);
        } finally {
          tagsStoreActions.setDataActiveTagIsLoading(false);
        }
      },
    });
  }

  return {
    onManageTagClick,
  };
};
