import {
  DataGroup,
  GL_COLORS,
  GL_USER_CHECK,
  GLBulletPoint,
  GLSelectMultipleOptions,
  i18n,
  IGroupsDevice,
} from '@group-link-one/grouplink-components';
import React, { ReactElement } from 'react';
import { create } from 'zustand';

export type TipsInfo = {
  title: string;
  icon: ReactElement;
  content: ReactElement;
};

export interface GroupsDevicesStoreState {
  openModal: boolean;
  openMakeDefaultModal: boolean;
  openDeleteModal: boolean;
  openAddDevicesModal: boolean;
  isLoading: boolean;
  isDeleteGroupLoading: boolean;
  groupsDevicesCardInfo: IGroupsDevice;
  customers_ids: number[];
  devices_ids: number[];

  isStrict: boolean;

  selectedLeftDevices: DataGroup[];
  selectedRightDevices: DataGroup[];

  userGroupSelecteds: GLSelectMultipleOptions[] | undefined;
  userParentGroupsSelecteds: GLSelectMultipleOptions[] | undefined;

  tagSelecteds: GLSelectMultipleOptions[] | undefined;
  tagValueSelected: GLSelectMultipleOptions[] | undefined;

  nextPageTokenAvailableDevices: string | undefined;
  isFetchingMoreAvailableDevices: boolean;
  availableDevicesSearch: string;

  nextPageTokenDevicesInGroup: string | undefined;
  isFetchingMoreDevicesInGroup: boolean;
  devicesInGroupSearch: string;

  tipsInfo: TipsInfo;
}

export interface GroupsDevicesStoreActions {
  setOpenModal: (open: boolean) => void;
  openMakeDefaultModal: (open: boolean) => void;
  openDeleteModal: (open: boolean) => void;
  setOpenDeleteModal: (open: boolean) => void;
  setOpenMakeDefaultModal: (open: boolean) => void;
  setOpenAddDevicesModal: (open: boolean) => void;

  setIsStrict: (isStrict: boolean) => void;

  setIsLoading: (loading: boolean) => void;
  setIsDeleteGroupLoading: (loading: boolean) => void;
  setGroupsDeviceCardInfo: (info: IGroupsDevice) => void;
  setCustomersIds: (ids: number[]) => void;
  setDevicesIds: (ids: number[]) => void;

  setNextPageTokenAvailableDevices: (token: string | undefined) => void;
  setIsFetchingMoreAvailableDevices: (loading: boolean) => void;
  setAvailableDevicesSearch: (search: string) => void;

  setNextPageTokenDevicesInGroup: (token: string | undefined) => void;
  setIsFetchingMoreDevicesInGroup: (loading: boolean) => void;
  setDevicesInGroupSearch: (search: string) => void;

  setSelectedLeftDevices: (device: DataGroup | undefined) => void;
  setSelectedRightDevices: (device: DataGroup | undefined) => void;

  setUserGroupSelecteds: (
    userGroupSelecteds: GLSelectMultipleOptions[] | undefined,
  ) => void;
  setUserParentGroupsSelecteds: (
    userParentGroupsSelecteds: GLSelectMultipleOptions[] | undefined,
  ) => void;

  setTagSelecteds: (
    tagSelecteds: GLSelectMultipleOptions[] | undefined,
  ) => void;
  setTagValueSelected: (
    tagValueSelected: GLSelectMultipleOptions[] | undefined,
  ) => void;

  setTipsInfo: (info: TipsInfo) => void;

  resetGroupsDevicesState: () => void;
  resetAddDevicesState: () => void;
  resetConfigs: () => void;
}

interface GroupsDevicesStore {
  state: GroupsDevicesStoreState;
  actions: GroupsDevicesStoreActions;
}

export const useGroupsDevicesStore = create<GroupsDevicesStore>((set) => ({
  state: {
    openModal: false,
    openMakeDefaultModal: false,
    openDeleteModal: false,
    openAddDevicesModal: false,
    isDeleteGroupLoading: false,
    isLoading: false,

    isStrict: false,
    selectedLeftDevices: [],
    selectedRightDevices: [],
    userGroupSelecteds: undefined,
    userParentGroupsSelecteds: undefined,
    tagSelecteds: undefined,
    tagValueSelected: undefined,

    groupsDevicesCardInfo: {
      id: 0,
      name: '',
      description: '',
      devices_count: 0,
      level: 0,
      devices_ids: [],
    },
    customers_ids: [],
    devices_ids: [],
    nextPageTokenAvailableDevices: undefined,
    nextPageTokenDevicesInGroup: undefined,
    availableDevicesSearch: '',
    devicesInGroupSearch: '',

    tipsInfo: {
      title: i18n.t('groupsDevices.modal.tips.groupName.title'),
      icon: <GL_USER_CHECK size={24} fill={GL_COLORS.ACCENT_COLOR} />,
      content: (
        <div>
          <GLBulletPoint
            list={[
              {
                text: i18n.t('groupsDevices.modal.tips.groupName.list.one'),
                color: 'FONT_COLOR',
              },
              {
                text: i18n.t('groupsDevices.modal.tips.groupName.list.two'),
                color: 'FONT_COLOR',
              },
            ]}
          />
        </div>
      ),
    },

    isFetchingMoreAvailableDevices: false,
    isFetchingMoreDevicesInGroup: false,
  },
  actions: {
    openMakeDefaultModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openMakeDefaultModal: open,
        },
      })),

    setOpenMakeDefaultModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openMakeDefaultModal: open,
        },
      })),

    setSelectedLeftDevices: (device: DataGroup | undefined) =>
      set((state) => ({
        state: {
          ...state.state,
          selectedLeftDevices: device
            ? state.state.selectedLeftDevices
                .filter((d) => d.id !== device?.id)
                .concat(
                  state.state.selectedLeftDevices?.some(
                    (d) => d.id === device?.id,
                  )
                    ? []
                    : [{ ...device, isChecked: true }],
                )
            : [],
        },
      })),

    setSelectedRightDevices: (device: DataGroup | undefined) =>
      set((state) => ({
        state: {
          ...state.state,
          selectedRightDevices: device
            ? state.state.selectedRightDevices
                .filter((d) => d.id !== device?.id)
                .concat(
                  state.state.selectedRightDevices?.some(
                    (d) => d.id === device?.id,
                  )
                    ? []
                    : [{ ...device, isChecked: true }],
                )
            : [],
        },
      })),

    setUserGroupSelecteds: (userGroupSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          userGroupSelecteds,
        },
      })),

    setUserParentGroupsSelecteds: (userParentGroupsSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          userParentGroupsSelecteds,
        },
      })),

    setTagSelecteds: (tagSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          tagSelecteds,
        },
      })),

    setTagValueSelected: (tagValueSelected) =>
      set((state) => ({
        state: {
          ...state.state,
          tagValueSelected,
        },
      })),

    setOpenModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openModal: open,
        },
      })),

    setIsStrict: (isStrict: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          isStrict,
        },
      })),

    openDeleteModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openRoleModal: open,
        },
      })),

    setOpenDeleteModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openDeleteModal: open,
        },
      })),

    setOpenAddDevicesModal: (open: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          openAddDevicesModal: open,
        },
      })),

    setIsLoading: (loading: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          isLoading: loading,
        },
      })),

    resetGroupsDevicesState: () =>
      set((state) => ({
        state: {
          ...state.state,
          groupsDevicesCardInfo: {
            id: 0,
            name: '',
            description: '',
            devices_count: 0,
            level: 0,
            devices_ids: [],
          },
        },
      })),

    setIsDeleteGroupLoading: (loading: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          isDeleteGroupLoading: loading,
        },
      })),

    setGroupsDeviceCardInfo: (info: IGroupsDevice) =>
      set((state) => ({
        state: {
          ...state.state,
          groupsDevicesCardInfo: info,
        },
      })),

    setCustomersIds: (ids: number[]) =>
      set((state) => ({
        state: {
          ...state.state,
          customers_ids: ids,
        },
      })),

    setDevicesIds: (ids: number[]) =>
      set((state) => ({
        state: {
          ...state.state,
          devices_ids: ids,
        },
      })),

    setNextPageTokenAvailableDevices: (token: string | undefined) =>
      set((state) => ({
        state: {
          ...state.state,
          nextPageTokenAvailableDevices: token,
        },
      })),

    setNextPageTokenDevicesInGroup: (token: string | undefined) =>
      set((state) => ({
        state: {
          ...state.state,
          nextPageTokenDevicesInGroup: token,
        },
      })),

    setIsFetchingMoreAvailableDevices: (loading: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          isFetchingMoreAvailableDevices: loading,
        },
      })),

    setIsFetchingMoreDevicesInGroup: (loading: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          isFetchingMoreDevicesInGroup: loading,
        },
      })),

    setAvailableDevicesSearch: (search: string) =>
      set((state) => ({
        state: {
          ...state.state,
          availableDevicesSearch: search,
        },
      })),

    setDevicesInGroupSearch: (search: string) =>
      set((state) => ({
        state: {
          ...state.state,
          devicesInGroupSearch: search,
        },
      })),

    setTipsInfo: (info: TipsInfo) =>
      set((state) => ({
        state: {
          ...state.state,
          tipsInfo: info,
        },
      })),

    resetAddDevicesState: () =>
      set((state) => ({
        state: {
          ...state.state,
          devices_ids: [],
          nextPageTokenAvailableDevices: undefined,
          nextPageTokenDevicesInGroup: undefined,
          availableDevicesSearch: '',
          devicesInGroupSearch: '',
          isFetchingMoreAvailableDevices: false,
          isFetchingMoreDevicesInGroup: false,
          openAddDevicesModal: false,
          selectedLeftDevices: [],
          selectedRightDevices: [],
        },
      })),

    resetConfigs: () =>
      set((state) => ({
        state: {
          ...state.state,
          customers_ids: [],
          nextPageTokenAvailableDevices: undefined,
          nextPageTokenDevicesInGroup: undefined,
          availableDevicesSearch: '',
          devicesInGroupSearch: '',
          isFetchingMoreAvailableDevices: false,
          isFetchingMoreDevicesInGroup: false,
          selectedLeftDevices: [],
          selectedRightDevices: [],
          userGroupSelecteds: undefined,
          userParentGroupsSelecteds: undefined,
          groupsDevicesCardInfo: {
            id: 0,
            name: '',
            description: '',
            devices_count: 0,
            level: 0,
            devices_ids: [],
          },
          isStrict: false,
          openModal: false,
          devices_ids: [],
          isDeleteGroupLoading: false,
          isLoading: false,
          openAddDevicesModal: false,
          openDeleteModal: false,
          openMakeDefaultModal: false,
        },
      })),
  },
}));
