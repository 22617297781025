import { GL_COLORS, GLBox } from '@group-link-one/grouplink-components';
import styled from 'styled-components';

export const HealthCheckBigNumbersContainer = styled(GLBox)`
  > div {
    /* flex: 1 !important; */
  }

  .big-numbers-container {
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    gap: 10px;

    display: grid;
    grid-template-rows: repeat(4, 1fr);
    padding: 0px;

    max-height: 100%;

    > div {
      height: 100% !important;
      max-height: 125px;
    }

    &.sm {
      gap: 5px;
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .health-check-button-hide-show {
    background-color: ${GL_COLORS.BACKGROUND_SECONDARY};
    border: 1px solid ${GL_COLORS.BORDER_COLOR};
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: -20px;
    right: -21px;

    padding: 10px;
    border-radius: 8px 0 0 8px;

    transition: all 0.4s;

    &.outside {
      right: -60px;
      transform: rotate(180deg);
    }
  }
`;
