import { UseCase } from "@group-link-one/gl-utils";
import { Colors, GL_COLORS, useI18n } from "@group-link-one/grouplink-components";

interface UseCaseColor {
  backgroundColor: string;
  textColor: keyof typeof GL_COLORS;
  iconColor: keyof typeof GL_COLORS;
}

interface BigNumbersColors {
  [key: string]: {
    [useCase in UseCase]: UseCaseColor
  }
}

export const useColorsByUseCase = () => {
  const { t } = useI18n();

  const bigNumbersText = {
    totalActivated: t('deviceList.bigNumbers.one.title'),
    totalPending: t('deviceList.bigNumbers.two.title'),
    totalDecommissioned: t('deviceList.bigNumbers.three.title'),
    totalDevices: t('deviceList.bigNumbers.four.title'),
  }

  const bigNumbersColors: BigNumbersColors = {
    [bigNumbersText.totalActivated]: {
      util_water: {
        backgroundColor: Colors.LIGHT_BLUE,
        textColor: 'NAVY',
        iconColor: 'NAVY'
        // backgroundColor: Colors.LIGHT_GREEN,
        // textColor: 'ULTRADARK_GREY_SECONDARY',
        // iconColor: 'ULTRADARK_GREY_SECONDARY',
      },
      util_kwh: {
        backgroundColor: Colors.LIGHT_BLUE,
        textColor: 'NAVY',
        iconColor: 'NAVY'
        // backgroundColor: Colors.LIGHT_GREEN,
        // textColor: 'ULTRADARK_GREY_SECONDARY',
        // iconColor: 'ULTRADARK_GREY_SECONDARY',
      },
      util_rms: {
        backgroundColor: Colors.LIGHT_BLUE,
        textColor: 'NAVY',
        iconColor: 'NAVY'
        // backgroundColor: Colors.LIGHT_GREEN,
        // textColor: 'ULTRADARK_GREY_SECONDARY',
        // iconColor: 'ULTRADARK_GREY_SECONDARY',
      },
      util_light: {
        backgroundColor: Colors.LIGHT_BLUE,
        textColor: 'NAVY',
        iconColor: 'NAVY'
      }
    },
    [bigNumbersText.totalPending]: {
      util_water: {
        backgroundColor: GL_COLORS.CUSTOM_MODAL_BACKGROUND,
        iconColor: "NAVY",
        textColor: "NAVY"
        // backgroundColor: Colors.NAVY,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_kwh: {
        backgroundColor: GL_COLORS.CUSTOM_MODAL_BACKGROUND,
        iconColor: "NAVY",
        textColor: "NAVY"
        // backgroundColor: Colors.NAVY,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_rms: {
        backgroundColor: GL_COLORS.CUSTOM_MODAL_BACKGROUND,
        iconColor: "NAVY",
        textColor: "NAVY"
        // backgroundColor: Colors.NAVY,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_light: {
        backgroundColor: GL_COLORS.CUSTOM_MODAL_BACKGROUND,
        iconColor: "NAVY",
        textColor: "NAVY"
      },
    },
    [bigNumbersText.totalDecommissioned]: {
      util_water: {
        backgroundColor: GL_COLORS.PURPLE,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: GL_COLORS.ACCENT_COLOR,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_kwh: {
        backgroundColor: GL_COLORS.PURPLE,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: GL_COLORS.ACCENT_COLOR,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_rms: {
        backgroundColor: GL_COLORS.PURPLE,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: GL_COLORS.ACCENT_COLOR,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_light: {
        backgroundColor: GL_COLORS.PURPLE,
        iconColor: "WHITE",
        textColor: "WHITE"
      }
    },
    [bigNumbersText.totalDevices]: {
      util_water: {
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: Colors.PURPLE,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_kwh: {
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: Colors.PURPLE,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_rms: {
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        iconColor: "WHITE",
        textColor: "WHITE"
        // backgroundColor: Colors.PURPLE,
        // iconColor: "WHITE",
        // textColor: "WHITE"
      },
      util_light: {
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        iconColor: "WHITE",
        textColor: "WHITE"
      }
    }
  }

  return { bigNumbersColors, bigNumbersText };
}
