import {
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_IDEA,
  GL_PADLOCK,
  GLBox,
  GLButton,
  GLCheckbox,
  GLDropdown,
  GLTextField,
  GLTipsCard,
  GLTransferList,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { useDevicesSteps } from './useDevicesStep';
import { RestrictContainerSelect } from '@/Pages/Tags/ModalCreateTag/Content/Steps/AddValues/Style';

export const DevicesStep = (): JSX.Element => {
  const {
    t,
    devicesAddeds,
    availableDevicesSearchIsCorrect,
    devicesAvailables,
    groupDevicesState,
    leftSideOptions,
    rightSideOptions,
    tagsStoreActions,
    tagsStoreState,
    groupDevicesActions,
    onSearch,
    onTransferItem,
    onAssignDeviceToTag,
    onSelectLeftItem,
    onSelectRightItem,
    onTransferAllItemsToLeft,
    onTransferAllItemsToRight,
  } = useDevicesSteps();

  return (
    <GLBox direction="row">
      <GLBox direction="column" gap={50}>
        <GLTypography
          text={t('tags.content.modal.manageDevices.description')}
          style={{
            marginTop: 50,
          }}
        />
        <GLBox direction={'column'}>
          <GLTypography
            text={t('tags.content.modal.manageDevices.values.title')}
            weight={600}
          />

          <GLBox direction="row" gap={80}>
            <GLDropdown.Root width="50%">
              <GLDropdown.Input
                icon={<GL_CHEVRON_DOWN fill={GL_COLORS.FONT_COLOR} size={16} />}
                value={tagsStoreState.selectedTagValue?.value}
                placeholder={t(
                  'tags.content.modal.assignTagToDevice.form.selectValue.placeholder',
                )}
              />

              {tagsStoreState?.activeTagValues?.length !== 0 ? (
                <GLDropdown.BoxOptions>
                  {tagsStoreState.activeTagValues.map(({ id, value }) => (
                    <GLDropdown.Option
                      text={value}
                      hasToShowSelected={
                        id === tagsStoreState.selectedTagValue?.id
                      }
                      onClick={() =>
                        tagsStoreActions.setSelectedTagValue({
                          id,
                          value,
                        })
                      }
                      key={id}
                      identifier="tagValue"
                      currentValue="tagValue"
                    />
                  ))}
                </GLDropdown.BoxOptions>
              ) : (
                <GLDropdown.BoxOptions
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 20,
                  }}
                >
                  <GLTypography
                    text={t('tags.content.modal.manageDevices.values.empty')}
                  />
                </GLDropdown.BoxOptions>
              )}
            </GLDropdown.Root>

            {/* <RestrictContainerSelect
              style={{
                width: '470px',
              }}
            >
              <GLBox>
                <GLBox>
                  <GLTypography
                    color={'FONT_COLOR'}
                    text={t(
                      'tags.content.modal.manageDevices.values.keepValues',
                    )}
                    weight={600}
                  />
                </GLBox>

                <GLCheckbox
                  iconType="check"
                  checked={tagsStoreState.preserveExistingTags}
                  onChange={(e) =>
                    tagsStoreActions.setPreserveExistingTags(e.target.checked)
                  }
                />
              </GLBox>

              <GLBox>
                <GLTypography
                  color={'FONT_COLOR'}
                  fontSize={3}
                  text={t(
                    'tags.content.modal.manageDevices.values.keepValuesDescription',
                  )}
                />
              </GLBox>
            </RestrictContainerSelect> */}
          </GLBox>
        </GLBox>

        <GLBox
          direction={'column'}
          style={{
            marginTop: 20,
          }}
        >
          <GLBox direction={'column'}>
            <GLTypography
              text={t('tags.content.modal.manageDevices.list.title')}
              weight={600}
            />

            <GLTransferList
              leftSideList={devicesAvailables}
              leftSideOptions={leftSideOptions}
              leftSideIsFetching={
                groupDevicesState.isFetchingMoreAvailableDevices
              }
              leftSideSearchHasFailure={true}
              leftSideTexts={{
                search: t(
                  'groupsDevices.addDevicesModal.transferList.leftSide.search',
                ),
                emptyTitle:
                  groupDevicesState.availableDevicesSearch.length === 0
                    ? t(
                        'tags.content.modal.assignTagToDevice.form.devices.emptyAvailablesDevices.title',
                      )
                    : t(
                        'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.title',
                      ),
                emptyBody:
                  groupDevicesState.availableDevicesSearch.length === 0
                    ? t('')
                    : t(
                        'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.description',
                      ),
              }}
              rightSideList={devicesAddeds}
              rightSideOptions={rightSideOptions}
              rightSideTooltipIsOpen={false}
              rightSideTooltipText={undefined}
              rightSideIsFetching={
                groupDevicesState.isFetchingMoreDevicesInGroup
              }
              rightSideTexts={{
                search: t(
                  'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
                ),
                emptyTitle:
                  groupDevicesState.devicesInGroupSearch.length === 0
                    ? t(
                        'tags.content.modal.assignTagToDevice.form.devices.emptyAddedDevice.title',
                      )
                    : t(
                        'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
                      ),
                emptyBody:
                  groupDevicesState.devicesInGroupSearch.length === 0
                    ? t(
                        'tags.content.modal.assignTagToDevice.form.devices.emptyAddedDevice.description',
                      )
                    : t(
                        'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
                      ),
              }}
              onTransferItemToLeft={(items) => onTransferItem(items, 'left')}
              onTransferItemToRight={(items) => onTransferItem(items, 'right')}
              onSelectLeftItem={onSelectLeftItem}
              onSelectRightItem={onSelectRightItem}
              onTransferAllItemsToLeft={onTransferAllItemsToLeft}
              onTransferAllItemsToRight={onTransferAllItemsToRight}
              onSearch={(value, side) => onSearch(value, side)}
            />
          </GLBox>
        </GLBox>

        <GLBox
          width={'100%'}
          justify={'flex-end'}
          style={{
            paddingBottom: 100,
          }}
        >
          <GLButton
            variant="outline"
            text={t('tags.content.modal.assignTagToDevice.form.btnCancel')}
            align="center"
            style={{
              width: 'fit-content',
            }}
            weight={600}
            padding
            border
            onClick={() => {
              tagsStoreActions.setModalAssignToDeviceIsOpen(false);
            }}
          />

          <GLButton
            variant="fill"
            text={t('tags.content.modal.assignTagToDevice.form.btnAssign')}
            align="center"
            style={{
              width: 'fit-content',
            }}
            weight={600}
            padding
            onClick={() => onAssignDeviceToTag()}
          />
        </GLBox>
      </GLBox>

      <GLBox
        width={'504px'}
        style={{
          marginTop: 80,
        }}
      >
        <GLTipsCard
          icon={<GL_IDEA fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
          title={t('tags.content.modal.manageDevices.tip.title')}
          size="small"
          type="default"
          hasAnimation
          content={
            <GLTypography
              text={t('tags.content.modal.manageDevices.tip.description')}
            />
          }
        />
      </GLBox>
    </GLBox>
  );
};
