import { useAuth } from '@group-link-one/gl-utils';
import {
  BigNumberProps,
  Colors,
  GL_CLOCK,
  GL_COLORS,
  GL_IDEA,
  GL_LOGIN,
  GL_SCALE,
  GL_THUNDERBOLT,
  GL_USER,
  useActiveLanguageDateFns,
  useI18n,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { GLBigNumberLastReading } from '../../../../images/DeviceList/bigNumbers/BigNumberLastReading';
import AverageImg from '../../../../images/DeviceList/bigNumbers/details-big-number-clock.svg';
import EnergyImg from '../../../../images/DeviceList/bigNumbers/details-big-number-energy.svg';
import PeakPeriodImg from '../../../../images/DeviceList/bigNumbers/details-big-number-scale.svg';
import VisitingImg from '../../../../images/DeviceList/bigNumbers/details-big-number-user.svg';
import RealPowerImg from '../../../../images/DeviceList/bigNumbers/LightPower.svg';
import PowerFactorImg from '../../../../images/DeviceList/bigNumbers/PowerFactor.svg';
import StreetLightImg from '../../../../images/DeviceList/bigNumbers/idea.svg';
import { convertDailyReading } from '../../../../utils/convertDailyReading';
import { UseCase } from '../../../EventList/Content/Columns/AllColumns';
import { useDetailsDeviceStore } from '../../store/details-device-store';
import { getOptionsByApplication } from '../../utils/getOptionsByApplication';
import { useGetDailyReadings } from '../Graphs/useGetDailyReadings';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { LastReadingSeries, Serie, Series } from '@/Services/deviceListService/useDeviceListService.types';
import { useGetStreetLightValues } from '@/hooks/useGetStreetLightValues';

interface IUseDeviceDetailsContent {
  bigNumbers: BigNumberProps[];
  features: Features;
  userUseCase: UseCase;
  isLoading: boolean;
  isStreetLight: boolean;
}

export const useDeviceDetailsContent = (): IUseDeviceDetailsContent => {
  const { state: detailsDeviceState } = useDetailsDeviceStore();
  const { t } = useI18n();
  const { getAverage, formatReadings } = useGetDailyReadings();
  const langActive = useActiveLanguageDateFns();
  const isSM = useMediaQuery('(max-width: 660px)');

  const { features } = usePrivileges();
  const { user } = useAuth();

  const userUseCase = user?.use_case as UseCase;

  const isStreetLight = userUseCase === 'util_light';

  const { streetLightValues } = useGetStreetLightValues({
    device: detailsDeviceState.device,
    channelActive: detailsDeviceState.channelActive || 0,
  })

  function getLastReading(): string {
    if (
      !detailsDeviceState.currentData ||
      detailsDeviceState.currentData.length === 0 ||
      !detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1]
        .reading
    ) {
      return '--';
    }

    const lastReading =
      detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1]
        .reading;
    return String(lastReading);
  }

  function getDailyConsumption(): string {
    if (
      !detailsDeviceState.currentData ||
      detailsDeviceState.currentData.length === 0 ||
      !detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1]
        .reading
    ) {
      return '--';
    }

    const { readingsFormatted } = formatReadings(
      detailsDeviceState.currentData,
    );

    const dailyConsumption = convertDailyReading(readingsFormatted);

    if (dailyConsumption[dailyConsumption.length - 1].reading === 0) {
      return '--';
    }

    return dailyConsumption[dailyConsumption.length - 1].reading.toFixed(2);
  }

  function getLatestReadingReverseEnergy(): string {
    if (!detailsDeviceState.device) {
      return '--';
    }
    const { channels } = detailsDeviceState.device;

    const currentChannel = channels.find(
      (channel) => channel.channel === detailsDeviceState.channelActive,
    );
    if (!currentChannel) {
      return '--';
    }

    const lastReadings = currentChannel.last_reading;

    if (typeof lastReadings === 'number') {
      return '--';
    }
    const energyReverse = lastReadings.series.find(
      (serie) => serie.serie === 'energy_reverse',
    );

    return energyReverse?.value || '--';
  }

  function getAverageConsumption(): string {
    if (
      !detailsDeviceState.currentData ||
      detailsDeviceState.currentData.length === 0
    ) {
      return '--';
    }

    // 🔹 Ordena as leituras por data
    const readings = detailsDeviceState.currentData
      .filter((reading) => reading.reading !== null) // Remove leituras inválidas
      .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()); // Ordena por data crescente

    if (readings.length === 0) {
      return '--';
    }

    // 🔹 Buscar a primeira leitura válida no período
    let firstReading = readings[0].reading;
    let firstDate = new Date(readings[0].time);

    // Se a primeira leitura do período for nula, buscar a anterior mais próxima
    const allReadings = detailsDeviceState.channelZero || []; // Todas as leituras disponíveis
    const previousReadings = allReadings
      .filter(
        (reading) =>
          new Date(reading.time) < firstDate && reading.reading !== null,
      )
      .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()); // Ordena do mais recente para o mais antigo

    if (!firstReading && previousReadings.length > 0) {
      firstReading = previousReadings[0].reading;
      firstDate = new Date(previousReadings[0].time);
    }

    // 🔹 Buscar a última leitura válida no período
    let lastReading = readings[readings.length - 1].reading;
    let lastDate = new Date(readings[readings.length - 1].time);

    // Se não houver leitura no último dia, usar a última leitura conhecida
    const lastDeviceReading =
      detailsDeviceState.device?.channels[0]?.last_reading?.series?.find(
        (serie) => serie.serie === 'water',
      )?.value;

    if (!lastReading && lastDeviceReading) {
      lastReading = Number(lastDeviceReading);
    }

    // 🔹 Calcula o intervalo de dias
    const daysDiff = Math.ceil(
      (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24),
    );

    // Evita divisão por zero caso haja apenas uma leitura no período
    if (daysDiff <= 0) {
      return '--';
    }

    // 🔹 Cálculo da média
    const average = (lastReading - firstReading) / daysDiff;

    return average.toFixed(2);
  }

  function getPeakPeriod(): string {
    if (!detailsDeviceState.currentData) {
      return '';
    }

    const { readingsFormatted } = formatReadings(
      detailsDeviceState.currentData,
    );

    const dailyConsumption = convertDailyReading(readingsFormatted);

    const readingAreTheSame = dailyConsumption.every(
      (reading) => reading.reading === dailyConsumption[0].reading,
    );

    if (readingAreTheSame) {
      return '--';
    }

    const dayWithPeriodMaxReading = dailyConsumption.find(
      (reading) =>
        reading.reading ===
        Math.max(...dailyConsumption.map((readingMax) => readingMax.reading)),
    );

    return dayWithPeriodMaxReading
      ? format(dayWithPeriodMaxReading.time, 'EEE, MMM d', {
        locale: langActive,
      })
      : '';
  }

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: (
        <GL_LOGIN
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
          size={24}
        />
      ),
      iconRight: (
        <GLBigNumberLastReading
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
        />
      ),
      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? 'none' : 'block',
      },
      title: t('deviceList.detailsModal.ut.bigNumbers.one.title'),
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
      }).unit
        })`,
      count: getLastReading(),
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription',
      ),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberLastReading,
    },
    {
      iconLeft: <GL_CLOCK fill={GL_COLORS.PURPLE} size={24} />,
      iconRight: <img src={AverageImg} alt="" />,
      iconRightStyle: {
        bottom: -14,
        right: 30,
        display: isSM ? 'none' : 'block',
      },
      title: t('deviceList.detailsModal.ut.bigNumbers.three.title'),
      type: isSM ? 'sm' : 'lg',
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
      }).unit
        })`,
      textColor: 'FONT_COLOR_DARK_GREY',
      count: getAverageConsumption(),
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.three.tooltipDescription',
      ),
      hasBorder: true,
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear:
        features.deviceList.detailsModal.showBigNumberAverageConsumption,
    },
    {
      iconLeft: <GL_SCALE fill={GL_COLORS.NAVY} size={24} />,
      iconRight: <img src={PeakPeriodImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: 15,
        display: isSM ? 'none' : 'block',
      },
      title: t('deviceList.detailsModal.ut.bigNumbers.four.title'),
      type: isSM ? 'sm' : 'lg',
      textColor: 'FONT_COLOR_DARK_GREY',
      count: getPeakPeriod(),
      countColor: 'FONT_COLOR',
      countSize: 6.5,
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.four.tooltipDescription',
      ),
      hasBorder: true,
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberPeakPeriod,
    },
  ];

  if (userUseCase === 'util_water' || userUseCase === 'util_light') {
    bigNumbers.splice(1, 0, {
      iconLeft: <GL_USER fill={Colors.LEMON_GREEN} size={24} />,
      iconRight: <img src={VisitingImg} alt="" />,
      tooltipZIndex: 1,
      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? 'none' : 'block',
      },
      title: t('deviceList.detailsModal.ut.bigNumbers.two.title'),
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory:
          detailsDeviceState.device?.meta.device_measurement_category ||
          'water',
      }).unit
        })`,
      textColor: 'FONT_COLOR_DARK_GREY',
      type: isSM ? 'sm' : 'lg',
      count: getDailyConsumption(),
      countColor: 'FONT_COLOR',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.two.tooltipDescription',
      ),
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      hasBorder: true,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear:
        features.deviceList.detailsModal.showBigNumberDailyConsumption,
    });
  }

  if (userUseCase === 'util_light') {
    bigNumbers.splice(0, 4);

    const activeEnergy: BigNumberProps = {
      iconLeft: (
        <GL_IDEA
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
          size={24}
        />
      ),

      iconRight: <img src={StreetLightImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.activeEnergy.title'),
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
      }).unit
        })`,
      count: streetLightValues.active_energy,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.activeEnergy.tooltipDescriptionSL',
      ),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberActiveEnergy,
    };

    const reactiveEnergy: BigNumberProps = {
      iconLeft: (
        <GL_IDEA
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
          size={24}
        />
      ),

      iconRight: <img src={StreetLightImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.reactiveEnergy.title'),
      subTitle: '(VArh)',
      count: streetLightValues.reactive_energy,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.reactiveEnergy.tooltipDescriptionSL',
      ),
      hasBorder: true,
      hasShadow: false,
      tooltipZIndex: 1,
      tooltipPosition: 'top-center',
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberReactiveEnergy,
    };

    const rmsVoltagem: BigNumberProps = {
      iconLeft: (
        <GL_THUNDERBOLT
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
          size={24}
        />
      ),

      iconRight: <img src={EnergyImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.voltage.title'),
      subTitle: '(V)',
      count: streetLightValues.rms_voltage,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.voltage.tooltipDescription',
      ),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberRmsVoltage,
    };

    const rmsCurrent: BigNumberProps = {
      iconLeft: (
        <GL_THUNDERBOLT
          fill={
            getOptionsByApplication({
              type: detailsDeviceState.device?.application,
              deviceMeasurementCategory:
                detailsDeviceState.device?.meta.device_measurement_category ||
                'water',
            }).color
          }
          size={24}
        />
      ),

      iconRight: <img src={EnergyImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.current.title'),
      subTitle: '(A)',
      count: streetLightValues.rms_current,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.current.tooltipDescription',
      ),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberRmsCurrent,
    };

    const realPower: BigNumberProps = {
      iconLeft: <img className='image-left' src={RealPowerImg} alt="" />,
      iconRight: <img className='image-right' src={RealPowerImg} alt="" />,

      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.realPower.title'),
      subTitle: '(W)',
      count: streetLightValues.real_power,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t('deviceList.detailsModal.ut.bigNumbers.realPower.tooltipDescription'),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberRmsCurrent,
    };

    const powerFactor: BigNumberProps = {
      iconLeft: <img className='image-left' src={PowerFactorImg} alt="" />,
      iconRight: <img className='image-right' src={PowerFactorImg} alt="" />,

      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? 'none' : 'block',
      },

      title: t('deviceList.detailsModal.ut.bigNumbers.powerFactor.title'),
      count: streetLightValues.power_factor,
      type: isSM ? 'sm' : 'lg',
      countColor: 'FONT_COLOR',
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: 'FONT_COLOR_DARK_GREY',
      tooltipText: t('deviceList.detailsModal.ut.bigNumbers.powerFactor.tooltipDescription'),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: 'top-center',
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberRmsCurrent,
    };

    bigNumbers.push(
      activeEnergy,
      reactiveEnergy,
      rmsVoltagem,
      rmsCurrent,
      realPower,
      powerFactor,
    );
  }

  if (userUseCase === 'util_kwh') {
    bigNumbers.splice(1, 0, {
      iconLeft: <GL_THUNDERBOLT fill={Colors.LEMON_GREEN} size={24} />,
      iconRight: <img src={EnergyImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? 'none' : 'block',
      },
      title: t('deviceList.detailsModal.ut.bigNumbers.reverseEnergy.title'),
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
      }).unit
        })`,
      textColor: 'FONT_COLOR_DARK_GREY',
      type: isSM ? 'sm' : 'lg',
      count: getLatestReadingReverseEnergy(),
      countColor: 'FONT_COLOR',
      tooltipText: t(
        'deviceList.detailsModal.ut.bigNumbers.reverseEnergy.tooltipDescription',
      ),
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      hasBorder: true,
      tooltipZIndex: 1,
      tooltipTextStyle: {
        whiteSpace: 'nowrap',
      },
      isToAppear: features.deviceList.detailsModal.showBigNumberReverseEnergy,
    });
  }

  const filteredBigNumbers = bigNumbers.filter(
    (bigNumber) => bigNumber.isToAppear,
  );

  return {
    bigNumbers: filteredBigNumbers,
    features,
    isLoading: detailsDeviceState.isLoading,
    isStreetLight,
    userUseCase,
  };
};
