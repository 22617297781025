import {
  GL_COLORS,
  GL_TRASHCAN,
  GLModalSteperContent,
} from '@group-link-one/grouplink-components';

import { useModalCreateTagContent } from './useModalCreateTagContent';
import { Identify } from './Steps/Identify/identify';
import { AddValues } from './Steps/AddValues/addValues';

const contentsPerStep = {
  identify: <Identify />,
  addValues: <AddValues />,
};

export const ModalCreateTagContent = (): JSX.Element => {
  const {
    onSubmit,
    deleteTag,
    createTagActions,
    createTagState,
    state,
    t,
    functionToVerifyIfCanAdvanceMap,
  } = useModalCreateTagContent();

  return (
    <GLModalSteperContent
      sendForm={onSubmit}
      updateForm={() => {}}
      isLoading={false}
      hasActionButton={state.modalType === 'edition'}
      buttonActionOptions={{
        variant: 'outline',
        align: 'center',
        text: t('tags.content.list.card.actions.deleteTag'),
        weight: 600,
        colorIconDynamically: true,
        icon: <GL_TRASHCAN fill={GL_COLORS.NAVY} size={18} />,
        onClick: deleteTag,
        style: {
          textWrap: 'nowrap',
          marginLeft: -60,
        },
      }}
      contentsPerStep={contentsPerStep}
      state={createTagState}
      actions={createTagActions}
      functionToVerifyIfCanAdvanceMap={functionToVerifyIfCanAdvanceMap}
    />
  );
};
