import { useAuth } from '@group-link-one/gl-utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerService } from '../../../../Services/customerService/useCustomerService';
import { useImpersonateContentStore } from '../../store/impersonateContent.store';
import { useImpersonateStore } from '../../store/impersonateModal.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import {
  GLModalSteperContentStoreActions,
  GLModalSteperContentStoreState,
} from '@group-link-one/grouplink-components';
import * as Sentry from '@sentry/react';
import { SettingsRoutes } from '@/layouts/usePageLayout';
import { useQueryClient } from '@tanstack/react-query';

interface IUseImpersonateContent {
  functionToVerifyIfCanAdvanceMap: Record<string, () => boolean>;
  state: GLModalSteperContentStoreState;
  actions: GLModalSteperContentStoreActions;
  impersonateCustomer: () => Promise<void>;
  impersonateContentActions: {
    setOrgErrorMessage: (message: string) => void;
    setUserErrorMessage: (message: string) => void;
    resetAll: () => void;
  };
}

export const useImpersonateContent = (): IUseImpersonateContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { setIsPending, accessToken, setRootToken, setAccessToken } = useAuth();
  const { customerImpersonation } = useCustomerService();
  const navigate = useNavigate();
  const { state, actions } = useImpersonateStore();
  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();
  const queryClient = useQueryClient();

  function verifyIfCanAdvanceToUsers(): boolean {
    if (impersonateContentState.organizationId === 0) {
      impersonateContentActions.setOrgErrorMessage(
        'Please select an organization',
      );

      return true;
    }

    impersonateContentActions.setOrgErrorMessage('');
    return false;
  }

  const functionToVerifyIfCanAdvanceMap = {
    organization: verifyIfCanAdvanceToUsers,
    user: () => false,
  };

  const impersonateCustomer = async (): Promise<void> => {
    if (impersonateContentState.userId === 0) {
      return;
    }

    setRootToken(accessToken!);
    actions.setIsLoading(true);
    setIsPending(true);

    customerImpersonation({
      customer_id: Number(impersonateContentState.userId),
    })
      .then((res) => {
        setAccessToken(`Bearer ${res.access_token}`);
        localStorage.setItem('isImpersonated', 'true');

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.IMPERSONATE_USER,
          eventDescription: 'End impersonate user',
        });

        queryClient.clear();

        actions.resetAll();
        impersonateContentActions.resetAll();
        // navigate(SettingsRoutes.OVERVIEW);
        setIsPending(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        actions.setIsLoading(false);
        setIsPending(false);
      });
  };

  useEffect(() => {
    if (impersonateContentState.userId === 0) {
      impersonateContentActions.setUserErrorMessage('Please select an user');
    }
  }, []);

  return {
    functionToVerifyIfCanAdvanceMap,
    state,
    actions,
    impersonateCustomer,
    impersonateContentActions,
  };
};
