import {
  formatDateDistanceText,
  GL_COLORS,
  GL_MENU_DOTS,
  GL_TAG,
  GLCardTemplate,
  GLCircleButton,
  GLDropdown,
  GLTooltip,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { GetTagRowMap } from '../../../Services/tagsService/useTagsService.type';
import { useManageTagClick } from '../GLModalAssignValuesToTag/Content/useManageTagClick';
import { useTagCard } from './useTagCard';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

export const TagCard = ({
  key_name,
  label,
  id,
  description,
  is_strict,
  created_at,
}: GetTagRowMap): JSX.Element => {
  const {
    showFeature,
    t,
    logEventAnalytics,
    languageDnsActive,
    actions,
    createTagActions,
    isMobile,
  } = useTagCard();
  const { onManageTagClick } = useManageTagClick();

  return isMobile ? (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root style={{ height: '100%' }}>
        <GLCardTemplate.Header direction="row" justify="space-between">
          <GLCardTemplate.Column justify="flex-start" width={75}>
            <GLCardTemplate.Column direction="row" justify="flex-start">
              <GLCardTemplate.Column width={15}>
                <GLCircleButton
                  text={<GL_TAG fill={GL_COLORS.FONT_COLOR_VARIANT} />}
                  bgColor={GL_COLORS.ACCENT_COLOR}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column direction="column" width={100}>
                <GLTypography
                  text={key_name}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                  style={{
                    maxWidth: 100,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                />

                <GLTypography
                  text={formatDateDistanceText(
                    created_at.toString(),
                    languageDnsActive,
                  )}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column direction="column">
                {label && (
                  <>
                    <GLTypography
                      text={t('tags.content.list.card.label')}
                      fontSize={3}
                      weight={600}
                    />

                    <GLTypography
                      text={label}
                      style={{
                        maxWidth: 50,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />
                  </>
                )}
              </GLCardTemplate.Column>
            </GLCardTemplate.Column>
          </GLCardTemplate.Column>

          <GLCardTemplate.Actions
            width={10}
            disappearOnHover={false}
            justify="flex-end"
            align="center"
            gap={35}
          >
            <GLDropdown.Root
              style={{
                zIndex: 1000,
              }}
            >
              <GLDropdown.Button icon={<GL_MENU_DOTS />} />

              <GLDropdown.BoxOptions width={190} position="bottom-right">
                {showFeature('show_btn_manage_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.manageTag')}
                    onClick={async () => {
                      actions.setActiveTagId(id);
                      await onManageTagClick({
                        id,
                        key: key_name,
                        label,
                        description,
                      });

                      actions.setModalAssignValuesToTagIsOpen(true);
                      logEventAnalytics({
                        eventName:
                          FBAnalyticsEventTitles.TAGS_MANAGE_CREATE_VALUE,
                        eventDescription: 'Start tag management',
                      });
                    }}
                    hasToShowSelected={false}
                  />
                )}

                {showFeature('show_btn_edit_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.editTag')}
                    onClick={async () => {
                      actions.setModalType('edition');
                      actions.setActiveTagId(id);
                      createTagActions.setTextCreationVariable('Save changes');
                      actions.setActiveTag({
                        key: {
                          id: id,
                          key: key_name,
                          description,
                          label,
                        },
                        values: [],
                        is_strict,
                      });
                      logEventAnalytics({
                        eventName: FBAnalyticsEventTitles.TAGS_UPDATE,
                        eventDescription: 'Start tag edition',
                      });
                      actions.setModalCreateTagIsOpen(true);
                    }}
                    hasToShowSelected={false}
                    identifier="edit-tag"
                    currentValue="edit-tag"
                  />
                )}

                {showFeature('show_btn_assign_to_devices') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.assignToDevices')}
                    identifier="assign-to-devices"
                    currentValue="assign-to-devices"
                    onClick={async () => {
                      actions.setActiveTagId(id);
                      await onManageTagClick({
                        id,
                        key: key_name,
                        label,
                        description,
                      });
                      actions.setModalAssignToDeviceIsOpen(true);
                      logEventAnalytics({
                        eventName:
                          FBAnalyticsEventTitles.TAGS_ASSIGN_TO_DEVICES,
                        eventDescription: 'Start assign tag to devices',
                      });
                    }}
                    hasToShowSelected={false}
                  />
                )}

                {showFeature('show_btn_delete_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.deleteTag')}
                    color="DANGER"
                    onClick={() => {
                      actions.setActiveTagId(id);
                      actions.setActiveTag({
                        key: {
                          id: id,
                          key: key_name,
                          description,
                          label,
                        },
                        values: [],
                        is_strict,
                      });
                      actions.setModalDeleteTagIsOpen(true);
                      logEventAnalytics({
                        eventName: FBAnalyticsEventTitles.TAGS_DELETE,
                        eventDescription: 'Start tag deletion',
                      });
                    }}
                    hasToShowSelected={false}
                    identifier="delete-tag"
                    currentValue="delete-tag"
                  />
                )}
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          </GLCardTemplate.Actions>
        </GLCardTemplate.Header>
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  ) : (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root>
        <GLCardTemplate.Header direction="row" justify="space-between">
          <GLCardTemplate.Column justify="flex-start" width={75}>
            <GLCardTemplate.Column direction="row" justify="flex-start">
              <GLCardTemplate.Column width={15}>
                <GLCircleButton
                  text={<GL_TAG fill={GL_COLORS.FONT_COLOR_VARIANT} />}
                  bgColor={GL_COLORS.ACCENT_COLOR}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column direction="column" width={100}>
                <GLTypography
                  text={key_name}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                />

                <GLTypography
                  text={formatDateDistanceText(
                    created_at.toString(),
                    languageDnsActive,
                  )}
                />
              </GLCardTemplate.Column>
            </GLCardTemplate.Column>

            <GLCardTemplate.Column direction="column">
              {label && (
                <>
                  <GLTypography
                    text={t('tags.content.list.card.label')}
                    fontSize={3}
                    weight={600}
                  />

                  <GLTypography text={label} />
                </>
              )}
            </GLCardTemplate.Column>

            <GLCardTemplate.Column direction="column">
              {description && (
                <GLCardTemplate.Column direction="column">
                  <GLTypography
                    text={t('tags.content.list.card.description')}
                    fontSize={3}
                    weight={600}
                  />

                  <GLTooltip text={description} position="top-left">
                    <GLTypography
                      text={description}
                      style={{
                        maxWidth: 200,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />
                  </GLTooltip>
                </GLCardTemplate.Column>
              )}
            </GLCardTemplate.Column>
          </GLCardTemplate.Column>

          <GLCardTemplate.Actions
            width={10}
            disappearOnHover={false}
            justify="flex-end"
            align="center"
            gap={35}
            style={{ right: 20 }}
          >
            <GLDropdown.Root
              style={{
                zIndex: 1000,
              }}
            >
              <GLDropdown.Button icon={<GL_MENU_DOTS />} />

              <GLDropdown.BoxOptions width={190} position="bottom-right">
                {showFeature('show_btn_manage_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.manageTag')}
                    onClick={async () => {
                      actions.setActiveTagId(id);
                      await onManageTagClick({
                        id,
                        key: key_name,
                        label,
                        description,
                      });

                      actions.setModalAssignValuesToTagIsOpen(true);
                      logEventAnalytics({
                        eventName:
                          FBAnalyticsEventTitles.TAGS_MANAGE_CREATE_VALUE,
                        eventDescription: 'Start tag management',
                      });
                    }}
                    hasToShowSelected={false}
                  />
                )}

                {showFeature('show_btn_edit_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.editTag')}
                    onClick={async () => {
                      actions.setModalType('edition');
                      createTagActions.setTextCreationVariable('Save changes');
                      actions.setActiveTag({
                        key: {
                          id: id,
                          key: key_name,
                          description,
                          label,
                        },
                        values: [],
                        is_strict,
                      });
                      logEventAnalytics({
                        eventName: FBAnalyticsEventTitles.TAGS_UPDATE,
                        eventDescription: 'Start tag edition',
                      });
                      actions.setModalCreateTagIsOpen(true);
                    }}
                    hasToShowSelected={false}
                    identifier="edit-tag"
                    currentValue="edit-tag"
                  />
                )}

                {showFeature('show_btn_assign_to_devices') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.assignToDevices')}
                    onClick={async () => {
                      actions.setActiveTagId(id);
                      await onManageTagClick({
                        id,
                        key: key_name,
                        label,
                        description,
                      });
                      actions.setModalAssignToDeviceIsOpen(true);
                      logEventAnalytics({
                        eventName:
                          FBAnalyticsEventTitles.TAGS_ASSIGN_TO_DEVICES,
                        eventDescription: 'Start assign tag to devices',
                      });
                    }}
                    hasToShowSelected={false}
                  />
                )}

                {showFeature('show_btn_delete_tag') && (
                  <GLDropdown.Option
                    text={t('tags.content.list.card.actions.deleteTag')}
                    color="DANGER"
                    onClick={() => {
                      actions.setActiveTagId(id);
                      actions.setActiveTag({
                        key: {
                          id: id,
                          key: key_name,
                          description,
                          label,
                        },
                        values: [],
                        is_strict,
                      });
                      actions.setModalDeleteTagIsOpen(true);
                      logEventAnalytics({
                        eventName: FBAnalyticsEventTitles.TAGS_DELETE,
                        eventDescription: 'Start tag deletion',
                      });
                    }}
                    hasToShowSelected={false}
                    identifier="delete-tag"
                    currentValue="delete-tag"
                  />
                )}
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          </GLCardTemplate.Actions>
        </GLCardTemplate.Header>
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
};
