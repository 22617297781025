import { GLReports } from '@group-link-one/grouplink-components';

import { useGLFlags } from '@group-link-one/gl-utils';
import { useModalContent } from './hooks/useModalContent';
import { useModalDeleteReport } from './hooks/useModalDeleteReport';
import { useReportsContent } from './hooks/useReportsContent';

export const ReportsListContent = (): JSX.Element => {
  const {
    reports,
    reportsIsLoading,
    reportsRefetch,
    isLoadingReportsAvailables,
    reportsAvailables,
    tagsAvailables,
    tagValues,
  } = useReportsContent();
  const { showFeature } = useGLFlags();
  const { createReport, isPending, getDevicesActivated } = useModalContent();
  const { isPending: deleteIsPending, onDelete } = useModalDeleteReport();

  return (
    <GLReports
      reportsContentOptions={{ reports, reportsIsLoading, reportsRefetch }}
      reportTypeOptions={{
        isLoadingReportsAvailables,
        reportsAvailables,
        tagsAvailables,
        tagValues,
      }}
      modalCreateReportContentOptions={{
        hasPermissionToCreate: showFeature('action_btn_new_report'),
        maxDateFrom: 150, // Add the maxDateFrom property with the appropriate value
        createReport,
        isPending,
        updateReport: () => {},
        getDevicesActivated,
        platform: 'utilities',
      }}
      modalDeleteReportOptions={{ onDelete, deleteIsPending }}
      flagsOptions={{
        showCreateReportButton: showFeature('action_btn_new_report'),
      }}
    />
  );
};
