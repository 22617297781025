import { GL_COLORS } from '@group-link-one/grouplink-components';
import styled from 'styled-components';

export const RestrictContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${GL_COLORS.BORDER_COLOR};
  border-radius: 16px;
  padding: 20px;
  width: 580px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
