import { i18n, IColumns, useI18n } from '@group-link-one/grouplink-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import * as yup from 'yup';

import { useGLFlags } from '@group-link-one/gl-utils';
import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import {
  GetTagRow,
  TagValue,
  TagValues,
} from '../../../../Services/tagsService/useTagsService.type';
import { TagsStoreState, useTagsStore } from '../../stores/tags.store';
import { useManageTagClick } from './useManageTagClick';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';

export const tagValueSchema = yup.object().shape({
  tagValue: yup.string().required('Tag value is required'),
});

const tagColumnsTable: IColumns[] = [
  {
    columnName: i18n.t(
      'tags.content.modal.manageTag.valuesContent.table.column',
    ),
    width: 20,
  },
];

interface IUseModalAssignValuesToTagContent {
  tableContentHeight: number;
  refParentTable: React.RefObject<HTMLDivElement>;
  errors: FieldErrors<{
    tagValue: string;
  }>;
  tags: GetTagRow[] | undefined;
  tagsStoreState: TagsStoreState;
  tagColumnsTable: IColumns[];
  tagValues: TagValues | undefined;
  inputSearchRef: React.RefObject<HTMLInputElement>;
  t: (key: string) => string;
  handleSubmit: UseFormHandleSubmit<
    {
      tagValue: string;
    },
    undefined
  >;
  register: UseFormRegister<{
    tagValue: string;
  }>;
  onManageTagClick: (tag: GetTagRow) => Promise<void>;
  onSubmitTagValue: (data: { tagValue: string }) => Promise<void | unknown>;
  onEditTagValue: (tagValue: TagValue) => void;
  onDeleteTagValue: (tagValue: TagValue) => void;
  showFeature: (featureName: string) => boolean;
  refetch: () => void;
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
}

export const useModalAssignValuesToTagContent =
  (): IUseModalAssignValuesToTagContent => {
    const { logEventAnalytics } = useFBAnalytics();
    const { t } = useI18n();
    const { createTagValue } = useTagsService();
    const { showFeature } = useGLFlags();
    const [tableContentHeight, setTableContentHeight] = useState<number>(0);

    const { getTagValue, getTags } = useTagsService();
    const refParentTable = useRef<HTMLDivElement>(null);

    const inputSearchRef = useRef<HTMLInputElement>(null);

    const {
      handleSubmit,
      register,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(tagValueSchema),
    });

    const { state: tagsStoreState, actions: tagsStoreActions } = useTagsStore();

    const { onManageTagClick } = useManageTagClick();

    const { data: tags } = useQuery({
      queryKey: ['get-tags-all', tagsStoreState.search],
      queryFn: async () => {
        const response = await getTags({
          key: tagsStoreState.search || undefined,
        });

        return response.rows;
      },
    });

    const { data: tagValues, refetch } = useQuery({
      queryKey: ['get-tags-values', tagsStoreState.activeTagId],
      queryFn: async () => {
        if (!tagsStoreState.activeTagId) {
          return undefined;
        }
        const response = await getTagValue({
          tag_key_id: Number(tagsStoreState.activeTagId),
        });

        tagsStoreActions.setActiveTagValues(response.values);

        return response;
      },
    });

    async function onSubmitTagValue(data: {
      tagValue: string;
    }): Promise<void | unknown> {
      if (tagsStoreState.activeTag) {
        try {
          await createTagValue({
            tag_key_id: Number(tagsStoreState.activeTagId),
            value: data.tagValue,
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.TAGS_MANAGE_CREATE_VALUE,
            eventDescription: 'Tag value created',
          });
          refetch();
        } catch (error) {
          return error;
        }
        reset();
      }
    }

    function onEditTagValue(tagValue: TagValue): void {
      tagsStoreActions.setActiveTagValueToEdit(tagValue);
    }

    function onDeleteTagValue(tagValue: TagValue): void {
      tagsStoreActions.setActiveTagValueToDelete(tagValue);
    }

    const handleInputChange = useDebounceCallback(
      async (event: KeyboardEvent) => {
        const input = event.target as HTMLInputElement;

        const value = input.value.replace(/ /g, '_');

        input.value = value;

        tagsStoreActions.setSearch(value);
      },
      500,
    );

    useEffect(() => {
      if (!inputSearchRef.current) {
        return;
      }

      inputSearchRef.current.addEventListener('keyup', handleInputChange);
      return () => {
        if (!inputSearchRef.current) {
          return;
        }

        inputSearchRef.current.removeEventListener('keyup', handleInputChange);
      };
    }, []);

    useEffect(() => {
      setTimeout(() => {
        if (refParentTable.current) {
          setTableContentHeight(refParentTable.current.clientHeight - 70);
        }
      }, 500);
    }, [refParentTable.current]);

    return {
      tableContentHeight,
      refParentTable,
      errors,
      tags,
      tagsStoreState,
      tagColumnsTable,
      tagValues,
      inputSearchRef,
      t,
      handleSubmit,
      register,
      onManageTagClick,
      onSubmitTagValue,
      onEditTagValue,
      onDeleteTagValue,
      showFeature,
      refetch,
      logEventAnalytics,
    };
  };
