import {
  GL_COLORS,
  GL_DELETE,
  GL_DELETE_SMALL,
  GLBox,
  GLCheckbox,
  GLDropFile,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { useCsvTransfer } from './useCsvTransfer';

export function CsvTransfer(): JSX.Element {
  const {
    groupDevicesState,
    invalidDevicesID,
    devicesIDChecked,
    validDevicesID,
    onDropCsv,
    onCheckDevice,
    t,
  } = useCsvTransfer();

  return (
    <GLBox direction="column" style={{ marginTop: 30 }}>
      <GLDropFile
        failure={false}
        containerStyle={{
          width: '60%',
          minWidth: 350,
        }}
        title={t('groupsDevices.modal.csv.dropFile.title')}
        supportedFormats={{
          'text/csv': ['.csv'],
        }}
        supportedFormatsMessage="CSV"
        onChange={(e, result) => {
          if (result && typeof result === 'string') {
            onDropCsv(result);
          }
        }}
        isToReset={groupDevicesState.openAddDevicesModal}
        // failure={!!errors.android?.credentials_json?.message}
        // isToReset={!!fileANDROID}
        // errorMessage={errors?.android?.credentials_json?.message}
      />

      {validDevicesID.length > 0 && (
        <GLBox
          gap={20}
          direction="column"
          style={{ marginTop: 30 }}
          width="70%"
        >
          <GLTypography
            text={`${t('groupsDevices.modal.csv.devices.valids')} (${devicesIDChecked.length})`}
            fontSize={4}
            color="FONT_COLOR_DARK_GREY"
            as="p"
            style={{ textTransform: 'uppercase' }}
          />

          <GLBox
            style={{
              flexWrap: 'wrap',
            }}
            justify="flex-start"
            gap={20}
          >
            {validDevicesID.map((device, index) => (
              <GLBox
                key={device}
                width="fit-content"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 20 }}
                transition={{
                  duration: 0.2,
                  delay: index * 0.1,
                }}
              >
                <GLCheckbox
                  iconType="check"
                  text={String(device)}
                  checked={devicesIDChecked.some(
                    (deviceId) => deviceId === device,
                  )}
                  onChange={(e) => onCheckDevice(device, e.target.checked)}
                />
              </GLBox>
            ))}
          </GLBox>
        </GLBox>
      )}

      {invalidDevicesID.length > 0 && (
        <GLBox
          gap={20}
          direction="column"
          style={{ marginTop: 30 }}
          width="70%"
        >
          <GLTypography
            text={`${t('groupsDevices.modal.csv.devices.invalids')} (${invalidDevicesID.filter((device) => !validDevicesID.includes(device)).length})`}
            fontSize={4}
            color="FONT_COLOR_DARK_GREY"
            as="p"
            style={{ textTransform: 'uppercase' }}
          />

          <GLBox
            style={{
              flexWrap: 'wrap',
            }}
            justify="flex-start"
            gap={20}
          >
            {invalidDevicesID
              .filter((device) => !validDevicesID.includes(device))
              .map((device, index) => (
                <GLBox
                  key={device}
                  width="fit-content"
                  align="center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 20 }}
                  transition={{
                    duration: 0.2,
                    delay: index * 0.1,
                  }}
                >
                  <GL_DELETE_SMALL fill={GL_COLORS.DANGER} size={12} />

                  <GLTypography
                    text={String(device)}
                    fontSize={4}
                    color="FONT_COLOR_DARK_GREY"
                    as="p"
                    style={{ marginLeft: 5 }}
                  />
                </GLBox>
              ))}
          </GLBox>
        </GLBox>
      )}
    </GLBox>
  );
}
