import { GLDetailedModal } from '@group-link-one/grouplink-components';

import { ModalAssignToDeviceContent } from './Content/ModalAssignToDeviceContent';
import { useModalAssignToDevice } from './useModalAssignToDevice';

export function ModalAssignToDevice(): JSX.Element {
  const { t } = useModalAssignToDevice();
  const { actions, state } = useModalAssignToDevice();

  return (
    <GLDetailedModal
      open={state.modalAssignToDeviceIsOpen}
      title={t('tags.content.modal.manageDevices.title')}
      width="100%"
      heigth="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      onClose={() => {
        actions.setModalAssignToDeviceIsOpen(false);
        actions.reset();
      }}
      content={<ModalAssignToDeviceContent />}
    />
  );
}
