import { PaginateResponseProps } from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  ActivatedDevicesResponseDto,
  BignumbersParams,
  DeviceMapResponse,
  DeviceMapV2Response,
  DeviceStatusDto,
  GetDeviceHealthCheckCountParams,
  GetDeviceHealthCheckCountResponse,
  GetDeviceInfoParams,
  GetDeviceInfoResponse,
  GetDeviceInGroupParams,
  GetDeviceInGroupsResponse,
  GetDeviceMapParams,
  GetDevicesActivatedDailyReadingsParams,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesCountParams,
  GetDevicesCountResponse,
  GetDevicesPendingActivationParams,
  GetDevicesPendingActivationResponse,
  GetDeviceStatusParams,
  PanelInfoResponseDto,
  RegisterDeviceBody,
  RegisterDeviceResponse,
  UpdateDeviceNameParams,
  UpdateDeviceParams,
  UseDeviceListService,
} from './useDeviceListService.types';
import { Sessions } from '../loginSecurityService/useLoginSecurityService';

export const useDeviceListService = (): UseDeviceListService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getDevicesActivatedLastReadings = async ({
    ...params
  }: GetDevicesActivatedLastReadingsParams): Promise<
    PaginateResponseProps<GetDevicesActivatedLastReadingsResponse[]>
  > => {
    const response = await httpPrivate.get('/devices-activated/last-reading', {
      params,
    });

    return response.data;
  };

  const getDevicesActivatedDailyReadings = async ({
    ...params
  }: GetDevicesActivatedDailyReadingsParams): Promise<GetDevicesActivatedDailyReadingsResponse> => {
    const response = await httpPrivate.get('/devices-activated/daily-reading', {
      params,
    });

    return response.data;
  };

  const getDevicesPendingActivation = async ({
    ...params
  }: GetDevicesPendingActivationParams): Promise<
    PaginateResponseProps<GetDevicesPendingActivationResponse[]>
  > => {
    const response = await httpPrivate.get('/devices-pending-activation', {
      params,
    });

    return response.data;
  };

  const getDevicesCount = async (
    params?: GetDevicesCountParams,
  ): Promise<GetDevicesCountResponse> => {
    const response = await httpPrivate.get('/device-activation/count', {
      params,
    });

    return response.data;
  };

  const getDevicesActivateds =
    async (): Promise<ActivatedDevicesResponseDto> => {
      const response = await httpPrivate.get('/device-activation/activated');
      return response.data;
    };

  const updateDevice = async (params: UpdateDeviceParams): Promise<void> => {
    const response = await httpPrivate.patch('/device-activation', params);
    return response.data;
  };

  const getBignumbers = async (
    params: BignumbersParams,
  ): Promise<PanelInfoResponseDto> => {
    const response = await httpPrivate.get('my-organization/count-panel', {
      params,
    });

    return response.data;
  };

  const updateDeviceName = async (
    params: UpdateDeviceNameParams,
  ): Promise<void> => {
    const response = await httpPrivate.patch('/sr/device-name', params);

    return response.data;
  };

  const getDeviceMap = async (
    data: GetDeviceMapParams,
  ): Promise<DeviceMapResponse[]> => {
    const response = await httpPrivate.post(
      '/device-activation/device/map',
      data,
    );

    return response.data;
  };

  const getDeviceMapV2 = async (
    data: GetDeviceMapParams,
  ): Promise<DeviceMapV2Response> => {
    const response = await httpPrivate.post(
      '/device-activation/device/map/v2',
      data,
    );

    return response.data;
  };

  const getDevicesHealthCheckCount = async (
    params: GetDeviceHealthCheckCountParams,
  ): Promise<GetDeviceHealthCheckCountResponse> => {
    const response = await httpPrivate.get('/device-activation/status/counts', {
      params,
    });

    return response.data;
  };

  const getDeviceStatus = async (
    params: GetDeviceStatusParams,
  ): Promise<Sessions<DeviceStatusDto>> => {
    const response = await httpPrivate.get('/device-activation/device-status', {
      params,
    });

    return response.data;
  };

  const registerDevice = async (
    body: RegisterDeviceBody,
  ): Promise<RegisterDeviceResponse> => {
    const response = await httpPrivate.post('/device-activation', body);

    return response.data;
  };

  const deviceDecomission = async (device_id: number): Promise<void> => {
    const response = await httpPrivate.post('/device-decommission', {
      device_id,
    });
    return response.data;
  };

  const getDeviceInfo = async ({
    device_id,
    object_readings,
    service,
    since,
    supportsMv,
    until,
  }: GetDeviceInfoParams): Promise<GetDeviceInfoResponse> => {
    const response = await httpPrivate.get('/device-activation/device-info', {
      params: {
        device_id,
        object_readings,
        service,
        since,
        supportsMv,
        until,
      },
    });

    return response.data;
  };

  const getDeviceInfoInGroup = async ({
    device_id,
  }: GetDeviceInGroupParams): Promise<GetDeviceInGroupsResponse[]> => {
    const response = await httpPrivate.get('/device-activation/device-name', {
      params: {
        device_id,
      },
    });

    return response.data;
  };

  return {
    getDevicesActivatedLastReadings,
    getDevicesActivatedDailyReadings,
    getDevicesCount,
    getDevicesActivateds,
    getDevicesPendingActivation,
    getBignumbers,
    getDevicesHealthCheckCount,
    getDeviceMap,
    getDeviceMapV2,
    getDeviceStatus,
    updateDevice,
    updateDeviceName,
    registerDevice,
    deviceDecomission,
    getDeviceInfo,
    getDeviceInfoInGroup,
  };
};
