import {
  MeasurementCategory,
  useAuth,
  UseCase,
} from '@group-link-one/gl-utils';
import {
  BigNumberProps,
  Colors,
  DeviceListStoreState,
  GL_CHECK,
  GL_COLORS,
  GL_GAS,
  GL_GLStation,
  GL_IDEA,
  GL_INTERNET,
  GL_LOGIN,
  GL_THUNDERBOLT,
  GL_WATER,
  GLSelectMultipleOptions,
  i18n,
  useDeviceListStore as useGLDeviceListStore,
  useGLPagination,
  useHandleFilterDeviceGroup,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { DebouncedState, useMediaQuery } from 'usehooks-ts';

import TotalDevicesImg from '../../images/DeviceList/icon-internet.svg';
import TotalActivatedDevicesImg from '@/images/DeviceList/bigNumbers/devices-activated.svg';
import TotalPendingDevicesImg from '@/images/DeviceList/bigNumbers/devices-pending.svg';
import TotalDeactivatedDevicesImg from '@/images/DeviceList/bigNumbers/devices-deactivated.svg';

import { useDeviceListService } from '../../Services/deviceListService/useDeviceListService';
import {
  BignumbersParams,
  BignumbersResponse,
  BigNumberTypes,
  PanelInfoDto,
} from '../../Services/deviceListService/useDeviceListService.types';
import { useDetailsDeviceStore } from './store/details-device-store';
import {
  DeviceListStoreActions,
  DeviceListStoreState as DeviceListStoreStateLocal,
  useDeviceListStore,
} from './store/device-list-store';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { useGLFlags } from '@group-link-one/gl-utils';
import { useNewStreetLightBigNumbers } from './BigNumbers/useNewStreetLightBigNumbers';
import { useColorsByUseCase } from './BigNumbers/useColorsByUseCase';
import { User } from '@group-link-one/gl-utils';

export const useCasesMap = [
  {
    id: 'gas',
    text: i18n.t('deviceList.selectUseCase.gas'),
    icon: <GL_GAS size={14} fill={Colors.ORANGE} />,
  },
  {
    id: 'water',
    text: i18n.t('deviceList.selectUseCase.water'),
    icon: <GL_WATER size={14} fill={GL_COLORS.ACCENT_COLOR} />,
  },
  {
    id: 'energy',
    text: i18n.t('deviceList.selectUseCase.energy'),
    icon: <GL_THUNDERBOLT size={14} fill={Colors.PURPLE} />,
  },
  {
    id: 'light',
    // text: i18n.t("deviceList.selectUseCase.energy"),
    text: 'Street Light',
    icon: <GL_IDEA size={14} fill="#00DCFF" />,
  },
];

interface IUserDeviceListBody {
  deviceListStateGL: DeviceListStoreState;
  deviceListState: DeviceListStoreStateLocal;
  deviceListActions: DeviceListStoreActions;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  bigNumbers: BigNumberProps[];
  features: Features;
  requests: {
    lastReading: boolean;
    getBignumberCountsDeviceList: boolean;
  };
  t: (key: string) => string;
  isOpen: boolean;
  isStreetLight: boolean;
  user: User | undefined;
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  showFeature: (feature: string) => boolean;
  onSelectUseCase: (useCaseOptionsSelecteds: GLSelectMultipleOptions[]) => void;
  onSelectUserGroup: (
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ) => void;
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  onSelectTagValue: (tagValueSelecteds: GLSelectMultipleOptions[]) => void;
  onSelectFilterLampIsLitOrNot: (
    lampStatusSelected: GLSelectMultipleOptions[],
  ) => void;
  onSelectAlert: (alertSelected: GLSelectMultipleOptions[]) => void;
}

const validUseCases: UseCase[] = [
  'util_kwh',
  'util_light',
  'util_rms',
  'util_water',
];

export const useDeviceListBody = (): IUserDeviceListBody => {
  const [infoCounts, setInfoCounts] = useState({
    activated: 0,
    pending: 0,
    decommissioned: 0,
    lampsOn: 0,
    lampsOff: 0,
    lampsWithAlarm: 0,
    totalDevices: 0,
  });

  const [groupsChildrensAvailables, setGroupsChildrensAvailables] = useState<
    GLSelectMultipleOptions[] | undefined
  >(undefined);

  const isSM = useMediaQuery('(max-width: 660px)');
  const { getBignumbers } = useDeviceListService();
  const { user, userGroups } = useAuth();
  const { getTags, getTagValue } = useTagsService();
  const { t } = useI18n();
  const { state } = useDetailsDeviceStore();
  const { requests, features } = usePrivileges();
  const { showFeature } = useGLFlags();

  const { bigNumbersSL } = useNewStreetLightBigNumbers();
  const { bigNumbersColors, bigNumbersText } = useColorsByUseCase();

  const isStreetLight = user?.use_case === 'util_light';

  const { state: deviceListState, actions: deviceListActions } =
    useDeviceListStore();

  const { state: deviceListStateGL, actions: deviceListActionsGL } =
    useGLDeviceListStore();

  const { formatGroupList, onSelectUserGroup: onHandleSelectUserGroup } =
    useHandleFilterDeviceGroup();

  const { state: paginationState } = useGLPagination();

  const { data: bignumbersResponse, isLoading: bigNumbersIsLoading } = useQuery(
    {
      queryKey: ['bignumbers-device-list', user?.use_case || ''],
      queryFn: async () => {
        const params: (keyof typeof BigNumberTypes)[] = [
          'activated_devices',
          'decommissioned_devices',
          'pending_devices',
        ];

        if (isStreetLight) {
          params.push(
            'light_total_lamps_on',
            'light_total_lamps_off',
            'light_total_lamps_with_alarm',
          );
        }

        const response = await getBignumbers({
          org_id: user!.org.id!,
          type: params,
        });

        return response.rows;
      },
      enabled: requests.getBignumberCountsDeviceList,
    },
  );

  const { data: tags } = useQuery({
    queryKey: ['get-tags'],
    enabled:
      showFeature('show_device_tags_page') &&
      showFeature('show_tags_device_list'),
    queryFn: async () => {
      const response = await getTags({
        key: undefined,
      });

      return response.rows;
    },
  });

  const { data: tagValues } = useQuery({
    queryKey: ['get-tags-values', deviceListStateGL.tagSelecteds],
    queryFn: async () => {
      if (!deviceListStateGL.tagSelecteds) return [];

      const response = await getTagValue({
        key: deviceListStateGL.tagSelecteds[0].id,
      });

      return response.values;
    },
  });

  const findBigNumber = (bignumbers: BigNumberProps[], title: string) => {
    const bignumbersFiltered = bignumbers.find((item) => item.title === title);
    return bignumbersFiltered;
  };

  const bigNumbers: BigNumberProps[] = useMemo(() => {
    const useCase =
      validUseCases.includes(user?.use_case as UseCase) && user
        ? user.use_case
        : 'util_water';

    const all: BigNumberProps[] = [
      {
        iconRight: <img src={TotalActivatedDevicesImg} alt="" />,
        iconRightStyle: {
          bottom: -10,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.one.title'),
        count: infoCounts.activated,
        type: isSM ? 'sm' : 'lg',
        backgroundColor:
          bigNumbersColors[bigNumbersText.totalActivated][useCase]
            .backgroundColor,
        textColor:
          bigNumbersColors[bigNumbersText.totalActivated][useCase].textColor,
        helpIconColor:
          bigNumbersColors[bigNumbersText.totalActivated][useCase].textColor,
        tooltipText: t('deviceList.bigNumbers.one.tooltipDescription'),
        tooltipPosition: 'top-right',
        tooltipZIndex: 2,
        tooltipWidth: isSM ? 250 : 320,
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.activatedDevices,
      },
      {
        iconRight: <img src={TotalPendingDevicesImg} alt="" />,
        iconRightStyle: {
          bottom: -30,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.two.title'),
        count: infoCounts.pending,
        type: isSM ? 'sm' : 'lg',
        backgroundColor:
          bigNumbersColors[bigNumbersText.totalPending][useCase]
            .backgroundColor,
        textColor:
          bigNumbersColors[bigNumbersText.totalPending][useCase].textColor,
        tooltipText: t('deviceList.bigNumbers.two.tooltipDescription'),
        helpIconColor:
          bigNumbersColors[bigNumbersText.totalPending][useCase].textColor,
        tooltipZIndex: 2,
        tooltipWidth: isSM ? 250 : 320,
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.pendingDevices,
      },
      {
        iconRight: <img src={TotalDeactivatedDevicesImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.three.title'),
        count: infoCounts.decommissioned,
        type: isSM ? 'sm' : 'lg',

        backgroundColor:
          bigNumbersColors[bigNumbersText.totalDecommissioned][useCase]
            .backgroundColor,
        textColor:
          bigNumbersColors[bigNumbersText.totalDecommissioned][useCase]
            .textColor,
        tooltipText: t('deviceList.bigNumbers.three.tooltipDescription'),
        helpIconColor:
          bigNumbersColors[bigNumbersText.totalDecommissioned][useCase]
            .textColor,
        tooltipZIndex: 2,
        tooltipWidth: isSM ? 250 : 320,
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.deactivatedDevices,
      },
      {
        iconRight: <img src={TotalDevicesImg} alt="" />,
        iconRightStyle: {
          bottom: isStreetLight ? 40 : 0,
          right: isSM ? -40 : 20,
          transform: isSM
            ? 'scale(0.85)'
            : isStreetLight
              ? 'scale(1.5)'
              : 'none',
        },
        title: t('deviceList.bigNumbers.four.title'),
        count: infoCounts.totalDevices,
        type: isSM ? 'sm' : 'lg',
        backgroundColor:
          bigNumbersColors[bigNumbersText.totalDevices][useCase]
            .backgroundColor,
        textColor:
          bigNumbersColors[bigNumbersText.totalDevices][useCase].textColor,
        tooltipText: t('deviceList.bigNumbers.four.tooltipDescription'),
        tooltipPosition: 'top-right',
        helpIconColor:
          bigNumbersColors[bigNumbersText.totalDevices][useCase].textColor,
        tooltipZIndex: 2,
        tooltipWidth: isSM ? 250 : 320,
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.totalDevices,
      },
    ];

    const bigNumbersFiltered = all.filter((item) => item.isToAppear);

    if (isStreetLight) {
      const allDevicesBN = findBigNumber(
        bigNumbersFiltered,
        t('deviceList.bigNumbers.four.title'),
      );
      const activatedDevicesBN = findBigNumber(
        bigNumbersFiltered,
        t('deviceList.bigNumbers.one.title'),
      );
      const pendingDevicesBN = findBigNumber(
        bigNumbersFiltered,
        t('deviceList.bigNumbers.two.title'),
      );
      const decommissionedDevicesBN = findBigNumber(
        bigNumbersFiltered,
        t('deviceList.bigNumbers.three.title'),
      );

      const bigNumbersToStreetLight = [];

      if (allDevicesBN) bigNumbersToStreetLight.push(allDevicesBN);
      if (activatedDevicesBN) bigNumbersToStreetLight.push(activatedDevicesBN);
      if (decommissionedDevicesBN)
        bigNumbersToStreetLight.push(decommissionedDevicesBN);
      if (pendingDevicesBN) bigNumbersToStreetLight.push(pendingDevicesBN);

      bigNumbersToStreetLight.splice(
        1,
        0,
        ...bigNumbersSL({
          alarmsCount: infoCounts.lampsWithAlarm,
          lampsOffCount: infoCounts.lampsOff,
          lampsLitCount: infoCounts.lampsOn,
          isLoading: bigNumbersIsLoading,
          isSM,
        }),
      );

      return bigNumbersToStreetLight.map((item) => ({
        ...item,
        tooltipText:
          item?.tooltipText +
          `. ${t('deviceList.bigNumbers.indicatorUpdated')}`,
      })) as BigNumberProps[];
    }

    return bigNumbersFiltered;
  }, [infoCounts, isSM, bigNumbersIsLoading, features]);

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      // return formatGroupList(userGroups);
      // TEMPORAIRE
      const deviceGroups = userGroups?.filter(
        (group) => group.type === 'device',
      );
      return deviceGroups?.map((group) => ({
        id: String(group.id),
        text: group.name,
        value: String(group.id),
      })) as GLSelectMultipleOptions[];
    }, [userGroups]);

  const tagOptionsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tags &&
        (tags.map((tag) => ({
          id: String(tag.key),
          text: tag.label,
        })) as GLSelectMultipleOptions[])
      );
    }, [tags]);

  const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tagValues &&
        (tagValues.map((tagValue) => ({
          id: String(tagValue.id),
          text: tagValue.value,
        })) as GLSelectMultipleOptions[])
      );
    }, [tagValues]);

  function onSelectUseCase(
    useCaseOptionsSelecteds: GLSelectMultipleOptions[],
  ): void {
    deviceListActionsGL.setUseCaseOptionsSelecteds(useCaseOptionsSelecteds);
  }

  function onSelectUserGroup(
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ): void {
    onHandleSelectUserGroup({
      userGroupsSelecteds,
      type,
      setUserParentGroupsSelecteds:
        deviceListActionsGL.setUserParentGroupsSelecteds,
      setUserGroupSelecteds: deviceListActionsGL.setUserGroupSelecteds,
      setGroupsChildrensAvailables,
    });
  }

  function onSelectTag(tagSelecteds: GLSelectMultipleOptions[]): void {
    deviceListActionsGL.setTagSelecteds(tagSelecteds);
  }

  function onSelectTagValue(
    tagValueSelecteds: GLSelectMultipleOptions[],
  ): void {
    deviceListActionsGL.setTagValueSelected(tagValueSelecteds);
  }

  function onSelectFilterLampIsLitOrNot(
    lampStatusSelected: GLSelectMultipleOptions[],
  ): void {
    deviceListActionsGL.setLampStatusSelected(lampStatusSelected);
  }

  function onSelectAlert(alertSelected: GLSelectMultipleOptions[]): void {
    deviceListActionsGL.setAlertSelected(alertSelected);
  }

  function calculeBigNumber(
    bignumbersResponse: PanelInfoDto[] | undefined,
    type: keyof typeof BigNumberTypes,
  ): number {
    return parseInt(
      (
        bignumbersResponse?.find((item: PanelInfoDto) => item.type === type)
          ?.count || '0'
      ).toString(),
      10,
    );
  }

  useEffect(() => {
    if (bignumbersResponse) {
      const activated = calculeBigNumber(
        bignumbersResponse,
        'activated_devices',
      );
      const pending = calculeBigNumber(bignumbersResponse, 'pending_devices');
      const decommissioned = calculeBigNumber(
        bignumbersResponse,
        'decommissioned_devices',
      );
      const lampsOn = calculeBigNumber(
        bignumbersResponse,
        'light_total_lamps_on',
      );
      const lampsOff = calculeBigNumber(
        bignumbersResponse,
        'light_total_lamps_off',
      );
      const lampsWithAlarm = calculeBigNumber(
        bignumbersResponse,
        'light_total_lamps_with_alarm',
      );

      const totalDevices = activated + pending + decommissioned;

      setInfoCounts({
        activated,
        pending,
        decommissioned,
        lampsOn,
        lampsOff,
        lampsWithAlarm,
        totalDevices,
      });
    }
  }, [bignumbersResponse]);

  useEffect(() => {
    deviceListActions.resetAll();

    const useCaseOptionsAvailables = useCasesMap.filter((useCase) =>
      user?.device_measurement_categories?.includes(
        useCase.id as MeasurementCategory,
      ),
    );

    if (
      useCaseOptionsAvailables.length > 1 &&
      paginationState.activeTabId === 1
    ) {
      deviceListActionsGL.setUseCaseOptions(useCaseOptionsAvailables);
    } else {
      deviceListActionsGL.setUseCaseOptionsSelecteds(undefined);
    }
  }, [user, paginationState.activeTabId]);

  return {
    requests,
    features,
    t,
    showFeature,
    groupsChildrensAvailables,
    deviceListStateGL,
    deviceListState,
    deviceListActions,
    userGroupsFormatted,
    user,
    bigNumbers,
    isStreetLight,
    isOpen: state.modalIsOpen,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUseCase,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onSelectFilterLampIsLitOrNot,
    onSelectAlert,
  };
};
