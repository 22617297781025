import { useMediaQuery } from 'usehooks-ts';
import { useExtractURLSearch } from '../../../hooks/useExtractURLSearch';
import { Features, usePrivileges } from '../../../hooks/usePrivilleges';
import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import { GetDeviceInfoResponse, GetDevicesActivatedLastReadingsResponse } from '../../../Services/deviceListService/useDeviceListService.types';
import { useDeviceListStore } from '../store/device-list-store';

import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { useHandleCache } from '@/hooks/useHandleCache';
import { DeviceListCardProps } from './DeviceListCard';
import { CriticalityLevel } from '@group-link-one/grouplink-components';

interface IUseDeviceListCard {
  isMobile: boolean;
  getCriticalityLevel: (hours: number) => CriticalityLevel | undefined;
  onAddressClick: (lat: number, lng: number) => void;
  onDetailsClick: (
    item: GetDevicesActivatedLastReadingsResponse,
  ) => Promise<void>;
  onEditClick: (item: GetDevicesActivatedLastReadingsResponse) => Promise<void>;
  onRegisterDeviceClick: () => void;
  onClickDecomission: (item: GetDevicesActivatedLastReadingsResponse) => void;
}

type UseDeviceListCardPropsToOmit = 'item' | 'features' | 'user';

export const useDeviceListCard = ({
  detailsDeviceActions,
  registerDeviceActions,
  deviceListActions,
  isStreetLight
}: Omit<DeviceListCardProps, UseDeviceListCardPropsToOmit>): IUseDeviceListCard => {

  const { logEventAnalytics } = useFBAnalytics();
  const { getDeviceInfo } = useDeviceListService();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { setURLParams } = useExtractURLSearch();
  const { getData } = useHandleCache()

  async function getDeviceInfoData(device_id: number): Promise<GetDeviceInfoResponse | undefined> {
    const queryKey = ['device-info', device_id];

    const response = await getData<GetDeviceInfoResponse>({
      queryKey,
      callback: async () => await getDeviceInfo({ device_id }),
    })

    return response;
  }

  function onAddressClick(lat: number, lng: number): void {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  }

  async function onDetailsClick(
    item: GetDevicesActivatedLastReadingsResponse,
  ): Promise<void> {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_SEE_DETAILS,
      eventDescription: 'Start See Details Device',
    });

    const response = await getDeviceInfoData(item.device_id);

    if (!response) return;

    detailsDeviceActions.setDevice({
      ...item,
      tags: response?.tags,
    });
    detailsDeviceActions.setModalIsOpen(true);

    setURLParams({
      q: String(item.device_id),
    });
  }

  async function onEditClick(
    item: GetDevicesActivatedLastReadingsResponse,
  ): Promise<void> {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_EDIT,
      eventDescription: 'Start Edit Device',
    });

    deviceListActions.setModalType('edit');

    const response = await getDeviceInfoData(item.device_id);
    if (!response) return;

    registerDeviceActions.setDeviceId(response.device_id);
    registerDeviceActions.setDeviceName(item?.channels[0]?.name || '');
    registerDeviceActions.setRemoteId(item?.channels[0]?.remote_id || '');
    registerDeviceActions.setDeviceMeasurementCategory(
      item.meta.device_measurement_category,
    );
    registerDeviceActions.setAddressSelectedInfo({
      address:
        response.device_location.display_address ||
        response.device_location.address ||
        '',
      lat: response.device_location.lat,
      lng: response.device_location.long,
      cep: response.device_location.zipcode,
      country: response.device_location.country || '',
      city: response.device_location.city || '',
      state: response.device_location.state || '',
      number: response.device_location.number || '',
    });
  }

  function onRegisterDeviceClick(): void {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_REGISTER,
      eventDescription: 'Start Register Device',
    });
    deviceListActions.setModalType('register');
  }

  function onClickDecomission(
    item: GetDevicesActivatedLastReadingsResponse,
  ): void {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_DECOMISSION,
      eventDescription: 'Start Decomission Device',
    });
    deviceListActions.setDeviceId(item.device_id);
    deviceListActions.setModalDecomissionIsOpen(true);
  }

  function getCriticalityLevel(hours: number): CriticalityLevel | undefined {

    if (!isStreetLight) return undefined;

    if (hours < 12) return undefined;
    if (hours >= 12 && hours < 24) return 'warning';
    if (hours >= 24) return 'critical';

    return undefined;
  }

  return {
    isMobile,
    getCriticalityLevel,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    onRegisterDeviceClick,
    onClickDecomission,
  };
};
