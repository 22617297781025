import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';
import {
  useActiveLanguageDateFns,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { useGLFlags } from '@group-link-one/gl-utils';
import { Locale } from 'date-fns';
import { TagsStoreActions, useTagsStore } from '../stores/tags.store';
import {
  CreateTagStoreActions,
  useCreateTagStore,
} from '../stores/createTag.store';
import { useMediaQuery } from 'usehooks-ts';

interface TagCardProps {
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
  showFeature: (featureName: string) => boolean;
  t: (key: string) => string;
  actions: TagsStoreActions;
  createTagActions: CreateTagStoreActions;
  languageDnsActive: Locale;
  isMobile: boolean;
}

export const useTagCard = (): TagCardProps => {
  const { actions } = useTagsStore();
  const { actions: createTagActions } = useCreateTagStore();
  const { logEventAnalytics } = useFBAnalytics();
  const languageDnsActive = useActiveLanguageDateFns();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useI18n();

  const { showFeature } = useGLFlags();

  return {
    logEventAnalytics,
    showFeature,
    actions,
    languageDnsActive,
    t,
    createTagActions,
    isMobile,
  };
};
