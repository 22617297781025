import {
  GLModalSteperContentStoreActions,
  GLModalSteperContentStoreState,
  i18n,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import {
  CreateTagBody,
  TagValues,
  UpdateTagBody,
} from '../../../../Services/tagsService/useTagsService.type';
import { useTagsContent } from '../../Content/useTagsContent';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../../stores/tags.store';
import { useMediaQuery } from 'usehooks-ts';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useCreateTagStore } from '../../stores/createTag.store';
import { AxiosError } from 'axios';

type CreateTagForm = {
  keyName: string | undefined;
  label: string;
  description?: string;
  isStrict?: boolean;
  values: string[];
};

type UpdateTagForm = {
  label: string;
  description?: string;
  isStrict?: boolean;
  values: string[];
};

type OnSubmitData = {
  keyName?: string | undefined;
  label: string;
  description?: string;
  isStrict?: boolean;
  values: string[];
};

interface UseModalCreateTagContent {
  actions: TagsStoreActions;
  state: TagsStoreState;
  onSubmit: (data: OnSubmitData) => Promise<void>;
  updateTagSelected: (data: UpdateTagForm) => Promise<void>;
  isLoading: boolean;
  t: (key: string) => string;
  verifyButtonText: () => string;
  refetchTagValues: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<void, Error>>;
  isMobile: boolean;
  createTagActions: GLModalSteperContentStoreActions;
  createTagState: GLModalSteperContentStoreState;
  deleteTag: () => Promise<void>;
  functionToVerifyIfCanAdvanceMap: any;
}

export const useModalCreateTagContent = (): UseModalCreateTagContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { actions, state } = useTagsStore();
  const { actions: createTagActions, state: createTagState } =
    useCreateTagStore();

  const { createTag, updateTag, getTagValue } = useTagsService();
  const { refetch } = useTagsContent();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { addToast } = useToast();

  const { mutateAsync: createNewTag } = useMutation({
    mutationFn: async ({
      description,
      keyName,
      label,
      values,
    }: CreateTagForm) => {
      setIsLoading(true);
      const creationBody: CreateTagBody = {
        key_name: keyName || '',
        description: description || '',
        label: label || '',
        is_strict: state.isStrict || false,
        values,
      };

      await createTag(creationBody);
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.createTagSuccess.title'),
        message: t('tags.content.toasts.createTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_CREATE,
        eventDescription: 'End tag creation',
      });

      refetch();
      actions.setModalCreateTagIsOpen(false);
      createTagActions.resetAll();
      actions.reset();
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);

      if (error instanceof AxiosError) {
        const errorType = error.response?.data?.error;
        if (errorType === 'KEY_ALREADY_EXISTS') {
          addToast({
            type: 'error',
            title: t('tags.content.toasts.tagAlreadyExists.title'),
            message: t('tags.content.toasts.tagAlreadyExists.description'),
          });
        }

        if (
          error.response?.data?.message[0] ===
          'values must contain at least 1 elements'
        ) {
          addToast({
            type: 'error',
            title: t('tags.content.toasts.valueNotAdded.title'),
            message: t('tags.content.toasts.valueNotAdded.description'),
          });
        }
      }

      return { ok: false };
    },
  });

  const { mutateAsync: updateTagSelected } = useMutation({
    mutationFn: async ({
      label,
      description,
      isStrict,
      values,
    }: UpdateTagForm) => {
      const editionBody: UpdateTagBody = {
        description: description || state.activeTag?.key.description || '',
        label: label || state.activeTag?.key.label || '',
        is_strict: state.isStrict,
        values,
      };

      await updateTag({
        id: Number(state.activeTag?.key.id),
        data: editionBody,
      });
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.updateTagSuccess.title'),
        message: t('tags.content.toasts.updateTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_UPDATE,
        eventDescription: 'End tag edition',
      });
      refetch();
      actions.setModalCreateTagIsOpen(false);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  async function deleteTag() {
    actions.setModalDeleteTagIsOpen(true);
  }

  function verifyButtonText(): string {
    return state.modalType === 'creation'
      ? t('tags.content.modal.createNewTag.btnCreate')
      : t('tags.content.modal.editTag.btnSave');
  }

  async function onSubmit(): Promise<void> {
    if (state.modalType === 'creation') {
      if (createTagState.stepActive === 1 && state.tagValues.length === 0) {
        actions.setTagValuesError(
          t('tags.content.modal.createNewTag.errors.tagValues'),
        );

        addToast({
          type: 'error',
          title: t('tags.content.toasts.valueNotAdded.title'),
          message: t('tags.content.toasts.valueNotAdded.description'),
        });

        return;
      }

      return await createNewTag({
        keyName: state.keyName,
        label: state.label || '',
        description: state.description,
        isStrict: state.isStrict,
        values: state.tagValues.map((tagValue) => tagValue.value || ''),
      });
    }

    return await updateTagSelected({
      label: state.label || '',
      description: state.description,
      isStrict: state.isStrict,
      values: state.tagValues.map((tagValue) => tagValue.value || ''),
    });
  }

  const functionToVerifyIfCanAdvanceMap = {
    identify: verifyIfCanAdvanceToAddValues,
    addValues: () => false,
  };

  function verifyIfCanAdvanceToAddValues() {
    const { keyName, label } = state;
    const hasError = !keyName || !label;

    if (!keyName) {
      actions.setKeyNameError(
        t('tags.content.modal.createNewTag.errors.keyName'),
      );
    }

    if (!label) {
      actions.setLabelError(t('tags.content.modal.createNewTag.errors.label'));
    }

    return hasError;
  }

  const { data: tagValues, refetch: refetchTagValues } = useQuery({
    queryKey: [
      'get-tag-values',
      state.modalType === 'edition',
      state.activeTag?.key.id,
    ],
    queryFn: async () => {
      if (!state.activeTag?.key.id) return;

      const response = await getTagValue({
        tag_key_id: Number(state.activeTag?.key.id),
      });

      actions.setTagValuesToAutoComplete(
        response.values.map((tagValue) => ({
          id: tagValue.id,
          value: tagValue.value,
        })),
      );
    },
  });

  useEffect(() => {
    if (state.keyName) actions.setKeyNameError('');

    if (state.label) actions.setLabelError('');

    if (state.tagValues.length > 0) actions.setTagValuesError('');
  }, [state.keyName, state.label, state.tagValues]);

  useEffect(() => {
    if (state.modalType === 'edition') {
      actions.setKeyName(state.activeTag?.key.key || '');
      actions.setLabel(state.activeTag?.key.label || '');
      actions.setDescription(state.activeTag?.key.description || '');
      actions.setIsStrict(state.activeTag?.is_strict || false);
      actions.setTagValuesToAutoComplete(tagValues || []);
    }
  }, [state.modalType]);

  return {
    actions,
    state,
    isLoading,
    t,
    isMobile,
    createTagActions,
    createTagState,
    functionToVerifyIfCanAdvanceMap,
    onSubmit,
    updateTagSelected,
    verifyButtonText,
    refetchTagValues,
    deleteTag,
  };
};
