import { useAuth } from '@group-link-one/gl-utils';
import { IColumns } from '@group-link-one/grouplink-components';

import { useEnergyColumns } from './Energy';
import { useWaterColumns } from './Water';
import { useStreetLightColumns } from './StreetLight';

export type UseCase = 'util_water' | 'util_kwh' | 'util_light';

export interface AllColumns {
  columnName: string;
  hasActions?: boolean;
  useCases: UseCase[];
}

export const useAllColumns = (): IColumns[] => {
  const { user } = useAuth();

  const userUseCase = user?.use_case as UseCase;

  if (!userUseCase) {
    return [];
  }

  const energyColumns = useEnergyColumns();
  const waterColumns = useWaterColumns();
  const streetlightColumns = useStreetLightColumns();

  const columns: Record<UseCase, IColumns[]> = {
    util_water: waterColumns,
    util_kwh: energyColumns,
    util_light: streetlightColumns,
  };

  return columns[userUseCase];
};
