import {
  GL_COLORS,
  GLBox,
  GLCardTemplate,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';

import { useEventListCard } from '../useEventListCard';
import { StreetLightCardProps } from './StreetLightCard.types';

export function StreetLightCard({ event }: StreetLightCardProps): JSX.Element | null {
  const { t, formatTime, formatEnergyValue, isMobile } = useEventListCard();

  if (!event) {
    return null;
  }

  return (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root borderRadius="0px">
        {!isMobile ? (
          <GLCardTemplate.Header>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={15}
            >
              <GLTypography
                text={event.remoteId || '--'}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={35}
            >
              <GLTypography text={event.displayAddress || '--'} color={'FONT_COLOR'} />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={15}
            >
              <GLTypography
                text={String(
                  format(event.time!, 'dd/MM/yyyy - HH:mm:ss'),
                )}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={15}
            >
              <GLTypography
                text={event.activeEnergyAccum || '--'}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={15}

            >
              <GLTypography
                text={event.reactiveEnergyAccum || '--'}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
              width={10}
            >
              <GLTypography
                text={String(event.alarmsCount || '--')}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>
          </GLCardTemplate.Header>
        ) : (
          <>
            <GLCardTemplate.Header direction="row">
              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t('eventList.columns.device_id')}
                  color={'FONT_COLOR_DARK_GREY'}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  fontSize={3}
                  text={String(event.deviceId)}
                  weight={600}
                  color={'FONT_COLOR'}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t('eventList.columns.created_at')}
                  color={'FONT_COLOR_DARK_GREY'}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  text={String(
                    format(event.time!, 'dd/MM/yyyy - HH:mm:ss'),
                  )}
                  color={'FONT_COLOR'}
                  fontSize={3}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Arrow />
            </GLCardTemplate.Header>

            <GLCardTemplate.Content>
              <GLBox
                direction="column"
                style={{
                  padding: 15,
                  backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                  flexWrap: 'wrap',
                }}
                gap={20}
                // gap={40}
              >
                <GLBox>
                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                    // width="fit-content"
                  >
                    <GLTypography
                      text={t('eventList.columns.remoteId')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={event.remoteId || '--'}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                    // width="fit-content"
                  >
                    <GLTypography
                      text={t('eventList.columns.address')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={event.displayAddress || '--'}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                    // width="fit-content"
                  >
                    <GLTypography
                      text={t('eventList.columns.activeEnergyAccum')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={event.activeEnergyAccum || '--'}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>
                </GLBox>

                <GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                    // width="fit-content"
                  >
                    <GLTypography
                      text={t('eventList.columns.reactiveEnergyAccum')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={event.reactiveEnergyAccum || '--'}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                    // width="fit-content"
                  >
                    <GLTypography
                      text={t('eventList.columns.alarmsCount')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={String(event.alarmsCount || '--')}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox>
                    <></>
                  </GLBox>
                </GLBox>
              </GLBox>
            </GLCardTemplate.Content>
          </>
        )}
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
}
