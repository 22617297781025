import {
  GLHeaderNavListItemProps,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth, useGLFlags } from '@group-link-one/gl-utils';
import { usePrivileges } from '../hooks/usePrivilleges';

export enum SettingsRoutes {
  DEFAULT = '/settings',
  OVERVIEW = '/settings/overview',
  MY_ACCOUNT = '/settings/my-account',
  LOGIN_SECURITY = '/settings/login-security',
  USERS = '/settings/users',
  GROUPS_USERS = '/settings/groups-users',
  ORGANIZATIONS = '/settings/organizations',
  APPS = '/settings/apps',
  AUDIT = '/settings/audit',
  TAGS = '/settings/tags',
}

export enum HealthCheckRoutes {
  MAP = '/health-check/map',
  DEFAULT = '/health-check',
}

type TypeLayout = 'settings' | 'health-check';

interface PageLayout {
  getHeaderNavList: (type: TypeLayout) => GLHeaderNavListItemProps[];
}

export function usePageLayout(): PageLayout {
  const { t } = useI18n();
  const { flags } = useGLFlags();
  const { pages } = usePrivileges();
  const navigate = useNavigate();

  const settings: GLHeaderNavListItemProps[] = useMemo(() => {
    const items: GLHeaderNavListItemProps[] = [
      {
        id: 1,
        title: t('pages.deviceOverview.overview'),
        path: SettingsRoutes.OVERVIEW,
        isToAppear: pages.settings.overview,
        onClick: () => navigate(SettingsRoutes.OVERVIEW),
      },
      {
        id: 2,
        title: t('pages.settings.myAccount'),
        path: SettingsRoutes.MY_ACCOUNT,
        isToAppear: pages.settings.myAccount,
        onClick: () => navigate(SettingsRoutes.MY_ACCOUNT),
      },
      {
        id: 3,
        title: t('pages.settings.loginSecurity'),
        path: SettingsRoutes.LOGIN_SECURITY,
        isToAppear: pages.settings.loginSecurity,
        onClick: () => navigate(SettingsRoutes.LOGIN_SECURITY),
      },
      {
        id: 4,
        title: t('users.subtitle'),
        path: SettingsRoutes.USERS,
        isToAppear: pages.settings.users,
        onClick: () => navigate(SettingsRoutes.USERS),
      },
      {
        id: 5,
        title: t('audit.title'),
        path: SettingsRoutes.AUDIT,
        isToAppear: pages.settings.audit,
        onClick: () => navigate(SettingsRoutes.AUDIT),
      },
      {
        id: 6,
        title: t('groupsUsers.subtitle'),
        path: SettingsRoutes.GROUPS_USERS,
        isToAppear: pages.settings.groupsUsers,
        onClick: () => navigate(SettingsRoutes.GROUPS_USERS),
      },
      {
        id: 7,
        title: t('Tags'),
        path: SettingsRoutes.TAGS,
        isToAppear: pages.settings.tags,
        onClick: () => navigate(SettingsRoutes.TAGS),
      },
    ];

    return items.filter((item) => item.isToAppear);
  }, [flags, pages.settings]);

  const healthCheck: GLHeaderNavListItemProps[] = useMemo(() => {
    const items = [
      {
        id: 1,
        title: 'Overview',
        isToAppear: true,
        path: HealthCheckRoutes.DEFAULT,
      },
    ];

    return items.filter((item) => item.isToAppear);
  }, [flags]);

  function getHeaderNavList(type: TypeLayout): GLHeaderNavListItemProps[] {
    switch (type) {
      case 'settings':
        return settings;
      case 'health-check':
        return healthCheck;
      default:
        return [];
    }
  }

  return {
    getHeaderNavList,
  };
}
