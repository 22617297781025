import {
  DataGroup,
  GL_COLORS,
  GL_USER,
  GLTransferListSideOptions,
  GroupsUsersStoreState,
  useGroupsUsersStore,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useCustomerService } from '../../../../Services/customerService/useCustomerService';
import { CustomersOnGroup } from '../../../../Services/customerService/useCustomerService.types';
import { useOrganizationUsersService } from '../../../../Services/organizationUsersService/useOrganizationUsers';
import { IUserResponse } from '../../../../Services/organizationUsersService/useOrganizationUsers.types';
import { GetDevicesActivatedLastReadingsResponse } from '@/Services/deviceListService/useDeviceListService.types';

const IPP_CUSTOMERS = 15;

interface DevicesAvailablesCachedParams {
  devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
  hasMore: boolean;
  nextPageToken: string | undefined;
}

interface IUseGroupUsersTransferList {
  t: (key: string) => string;
  customersAvailables: DataGroup[];
  customersAddeds: DataGroup[];
  leftSideOptions: GLTransferListSideOptions;
  rightSideOptions: GLTransferListSideOptions;
  groupsUsersState: GroupsUsersStoreState;
  onTransferItem: (items: DataGroup[], type: 'left' | 'right') => void;
  onSelectLeftItem: (item: DataGroup) => void;
  onSelectRightItem: (item: DataGroup) => void;
  onTransferAllItemsToLeft: () => void;
  onTransferAllItemsToRight: () => void;
  onSearch: (value: string, side: 'left' | 'right') => void;
}

export const useGroupUsersTransferList = (): IUseGroupUsersTransferList => {
  const [customersAvailables, setCustomersAvailables] = useState<DataGroup[]>(
    [],
  );
  const [customersAvailablesData, setCustomersAvailablesData] = useState<
    IUserResponse[]
  >([]);

  const [customersAddeds, setCustomersAddeds] = useState<DataGroup[]>([]);
  const [customersAddedsData, setCustomersAddedsData] = useState<number[]>([]);

  const { t } = useI18n();
  const { getGroupById } = useCustomerService();
  const { getUsers } = useOrganizationUsersService();

  const { state: groupsUsersState, actions: groupsUsersActions } =
    useGroupsUsersStore();

  const isEditing = !!groupsUsersState.groupsUsersCardInfo?.id;
  const queryClient = useQueryClient();

  function formatCustomersAvailables(
    currentCustomersAvailables: IUserResponse[],
  ): DataGroup[] {
    if (!currentCustomersAvailables) {
      return [];
    }

    const customersFormatted: DataGroup[] = currentCustomersAvailables?.map(
      (customer) => {
        return {
          id: String(customer.id),
          title: customer.name,
          body: customer.email,
          icon: <GL_USER fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      },
    );

    return customersFormatted;
  }

  async function getMoreCustomersAvailables(): Promise<void> {
    if (
      groupsUsersState.isFetchingMoreAvailableUsers ||
      !groupsUsersState.nextPageTokenAvailableUsers
    ) {
      return;
    }

    groupsUsersActions.setIsFetchingMoreAvailableUsers(true);

    const response = await getUsers({
      // org_id: user?.org.id,
      next_page_token: groupsUsersState.nextPageTokenAvailableUsers,
      ipp: IPP_CUSTOMERS,
    });

    setCustomersAvailablesData([...customersAvailablesData, ...response.rows]);

    groupsUsersActions.setIsFetchingMoreAvailableUsers(false);
    groupsUsersActions.setNextPageTokenAvailableUsers(response.next_page_token);

    const currentCustomersAvailables: IUserResponse[] | undefined =
      queryClient.getQueryData(['get-customers-availables']);

    const newCustomersAvailables = response.rows.filter(
      (customer) => groupsUsersState.customers_ids.indexOf(customer.id) === -1,
    );

    if (currentCustomersAvailables) {
      const currentCustomersAvailablesWihoutAddeds =
        currentCustomersAvailables.filter(
          (customer) =>
            groupsUsersState.customers_ids.indexOf(customer.id) === -1,
        );

      const newCustomersAvailablesFormatted: DataGroup[] =
        formatCustomersAvailables(
          currentCustomersAvailablesWihoutAddeds.concat(newCustomersAvailables),
        );

      setCustomersAvailables(newCustomersAvailablesFormatted);

      queryClient.setQueryData(
        ['get-customers-availables'],
        currentCustomersAvailablesWihoutAddeds.concat(newCustomersAvailables),
      );
    }
  }

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-availables-transfer-list',
          count: undefined,
          id: 1,
          name: t('groupsUsers.modal.transferList.leftSide.tabs.tabOne'),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t('groupsUsers.modal.transferList.leftSide.tabs.tabOne'),
      canTransferLeft: groupsUsersState.selectedRightUsers.length > 0,
      canTransferRight: groupsUsersState.selectedLeftUsers.length > 0,
      onScrollCallback: async () => {
        getMoreCustomersAvailables();
      },
    };
  }, [
    groupsUsersState.customers_ids,
    groupsUsersState.isFetchingMoreAvailableUsers,
    groupsUsersState.nextPageTokenAvailableUsers,
    groupsUsersState.selectedRightUsers.length,
    groupsUsersState.selectedLeftUsers.length,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-in-group-transfer-list',
          count: undefined,
          id: 1,
          name: t('groupsUsers.modal.transferList.rightSide.tabs.tabOne'),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      listName: t('groupsUsers.modal.transferList.rightSide.tabs.tabOne'),
      canTransferLeft: groupsUsersState.selectedRightUsers.length > 0,
      canTransferRight: groupsUsersState.selectedLeftUsers.length > 0,
      onScrollCallback: async () => {},
    };
  }, [
    groupsUsersState.selectedRightUsers.length,
    groupsUsersState.selectedLeftUsers.length,
  ]);

  async function getCustomersAvailables(
    isEditingParam?: boolean,
  ): Promise<void> {
    await queryClient.fetchQuery({
      queryKey: ['get-customers-availables'],
      queryFn: async () => {
        groupsUsersActions.setIsFetchingMoreAvailableUsers(true);

        const customers = await getUsers({
          // org_id: user!.org.id,
          next_page_token: undefined,
          ipp: IPP_CUSTOMERS,
          search: groupsUsersState.availableUsersSearch,
        });

        groupsUsersActions.setIsFetchingMoreAvailableUsers(false);

        if (customers.has_more && customers.next_page_token) {
          groupsUsersActions.setNextPageTokenAvailableUsers(
            customers.next_page_token,
          );
        }

        const customersFiltereds = customers.rows.filter(
          (customer) =>
            groupsUsersState.customers_ids.indexOf(customer.id) === -1,
        );

        const customersFormatted: DataGroup[] =
          formatCustomersAvailables(customersFiltereds);

        setCustomersAvailablesData(customersFiltereds);

        if (!isEditingParam) {
          setCustomersAvailables(customersFormatted);
        }

        return customersFiltereds;
      },
    });
  }

  async function getCustomersInGroup(): Promise<void> {
    await queryClient.fetchQuery({
      queryKey: ['get-customers-in-group'],
      queryFn: async () => {
        groupsUsersActions.setIsFetchingMoreUsersInGroup(true);

        const customersInGroup = await getGroupById({
          id: groupsUsersState.groupsUsersCardInfo!.id,
        });

        groupsUsersActions.setIsFetchingMoreUsersInGroup(false);

        const customersFormatted: DataGroup[] = customersInGroup.customers.map(
          (customer) => {
            return {
              id: String(customer.id),
              title: customer.name,
              body: customer.last_name,
              icon: <GL_USER fill={GL_COLORS.FONT_COLOR_VARIANT} />,
            };
          },
        );

        const customersAddedsIDs = customersInGroup.customers.map(
          (customer) => customer.id,
        );

        const customersAvailablesFiltered = customersAvailablesData.filter(
          (customer) => customersAddedsIDs.indexOf(customer.id) === -1,
        );

        setCustomersAddedsData(customersAddedsIDs);
        setCustomersAddeds(customersFormatted);

        setCustomersAvailablesData(customersAvailablesFiltered);
        setCustomersAvailables(
          formatCustomersAvailables(customersAvailablesFiltered),
        );

        groupsUsersActions.setCustomersIds(customersAddedsIDs);

        return customersInGroup.customers;
      },
    });
  }

  function onTransferAllItemsToLeft(): void {
    const devicesRightSelected = groupsUsersState.selectedRightUsers.map(
      (device) => {
        return {
          id: String(device.id),
          title: device.title,
          body: device.body,
          icon: device.icon,
        };
      },
    );

    const devicesAddedsDataFormatted: number[] = devicesRightSelected.map(
      (device) => Number(device.id),
    );

    setCustomersAvailables((prevDevicesAvailables) => [
      ...devicesRightSelected,
      ...prevDevicesAvailables,
    ]);

    setCustomersAddeds((prevDevicesAddeds) =>
      prevDevicesAddeds.filter(
        (device) =>
          !groupsUsersState.selectedRightUsers.some(
            (selectedDevice) => selectedDevice.id === device.id,
          ),
      ),
    );

    const devicesIdsThatWillBeRemoved = groupsUsersState.selectedRightUsers.map(
      (device) => device.id,
    );

    const devicesIds = customersAddedsData.filter(
      (device) => !devicesIdsThatWillBeRemoved.includes(String(device)),
    );

    groupsUsersActions.setCustomersIds(devicesIds);

    setCustomersAddedsData(devicesAddedsDataFormatted);

    groupsUsersActions.setSelectedRightUsers(undefined);
  }

  function onTransferAllItemsToRight(): void {
    const devicesLeftSelected = groupsUsersState.selectedLeftUsers.map(
      (device) => {
        return {
          id: String(device.id),
          title: device.title,
          body: device.body,
          icon: device.icon,
        };
      },
    );

    const devicesAddedsDataFormatted: number[] = devicesLeftSelected.map(
      (device) => Number(device.id),
    );

    setCustomersAddeds((prevDevicesAddeds) => [
      ...prevDevicesAddeds,
      ...devicesLeftSelected,
    ]);

    setCustomersAvailables((prevDevicesAvailables) =>
      prevDevicesAvailables.filter(
        (device) =>
          !groupsUsersState.selectedLeftUsers.some(
            (selectedDevice) => selectedDevice.id === device.id,
          ),
      ),
    );

    groupsUsersActions.setCustomersIds([
      ...customersAddeds.map((customer) => Number(customer.id)),
      ...devicesAddedsDataFormatted,
    ]);

    setCustomersAddedsData(devicesAddedsDataFormatted);

    groupsUsersActions.setSelectedLeftUsers(undefined);
  }

  function onSelectLeftItem(item: DataGroup): void {
    groupsUsersActions.setSelectedLeftUsers(item);

    setCustomersAvailables((prevDevicesAvailables) =>
      prevDevicesAvailables.map((device) =>
        device.id === item.id
          ? { ...device, isChecked: !device.isChecked }
          : device,
      ),
    );
  }

  function onSelectRightItem(item: DataGroup): void {
    groupsUsersActions.setSelectedRightUsers(item);

    setCustomersAddeds((prevDevicesAddeds) =>
      prevDevicesAddeds.map((device) =>
        device.id === item.id
          ? { ...device, isChecked: !device.isChecked }
          : device,
      ),
    );
  }

  function onTransferItem(items: DataGroup[], type: 'left' | 'right'): void {
    if (type === 'right') {
      if (groupsUsersState.usersInGroupSearch.length === 0) {
        const customersIds = items.map((customer) => Number(customer.id));
        groupsUsersActions.setCustomersIds(customersIds);

        const customersAddedsDataFormatted: CustomersOnGroup[] = items.map(
          (customer) => {
            return {
              id: Number(customer.id),
              name: customer.title || '',
              last_name: typeof customer.body === 'string' ? customer.body : '',
            };
          },
        );

        //setCustomersAddedsData(customersAddedsDataFormatted);
      }

      setCustomersAddeds(items);
    }

    if (type === 'left') {
      setCustomersAvailables(items);

      const currentCustomersSorted = items
        .map((item) => {
          return customersAvailablesData.find(
            (customer) => customer.id === Number(item.id),
          );
        })
        .filter((item) => item);

      queryClient.setQueryData(
        ['get-customers-availables'],
        currentCustomersSorted,
      );

      // if (groupsUsersState.usersInGroupSearch.length > 0) {
      //   const lastItemFromAvailables = items.slice(-1)[0];

      //   const newCustomersAddedsData = customersAddedsData.filter(
      //     (customer) => customer.id !== Number(lastItemFromAvailables.id),
      //   );

      //   groupsUsersActions.setCustomersIds(
      //     newCustomersAddedsData.map((customer) => customer.id),
      //   );

      //   setCustomersAddedsData(newCustomersAddedsData);
      // }
    }
  }

  function onSearch(value: string, side: 'left' | 'right'): void {
    if (side === 'left') {
      groupsUsersActions.setAvailableUsersSearch(value);
    }

    if (side === 'right') {
      groupsUsersActions.setUsersInGroupSearch(value);
    }
  }

  useEffect(() => {
    if (groupsUsersState.openModal) {
      getCustomersAvailables(isEditing);
      setCustomersAddeds([]);

      if (isEditing) {
        getCustomersInGroup();
      }
    }

    if (!groupsUsersState.openModal) {
      setCustomersAddeds([]);
    }
  }, [groupsUsersState.openModal, isEditing]);

  useEffect(() => {
    getCustomersAvailables();
  }, [groupsUsersState.availableUsersSearch]);

  useEffect(() => {
    // filterUsersInGroupBySearch();
  }, [groupsUsersState.usersInGroupSearch]);

  return {
    t,
    customersAvailables,
    customersAddeds,
    leftSideOptions,
    rightSideOptions,
    groupsUsersState,
    onTransferAllItemsToLeft,
    onTransferAllItemsToRight,
    onSelectLeftItem,
    onSelectRightItem,
    onTransferItem,
    onSearch,
  };
};
