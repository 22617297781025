import {
  GLDateRangeType,
  GLRelativeListOption,
  GLSelectMultipleOptions,
  i18n,
} from '@group-link-one/grouplink-components';
import { subDays } from 'date-fns';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  DeviceMapResponse,
  DeviceMapV2Response,
  GetDeviceHealthCheckCountResponse,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';

export interface HealthCheckStoreState {
  deviceModalIsOpen: boolean;
  deviceSelected: undefined | GetDevicesActivatedLastReadingsResponse;
  deviceSelectedDailyReadings:
    | GetDevicesActivatedDailyReadingsResponse
    | undefined;
  devicesFetched: DeviceMapResponse[] | undefined;

  devicesFetchedV2: DeviceMapResponse[] | undefined;
  devicesFetchedInfoV2: Omit<DeviceMapV2Response, 'devices'> | undefined;

  tagSelecteds: GLSelectMultipleOptions[] | undefined;
  tagValuesSelecteds: GLSelectMultipleOptions[] | undefined;

  bigNumbersIsOpen: boolean;

  modalFilterIsOpen: boolean;

  currentRelativeTime: GLRelativeListOption | undefined;
  currentDateRange: GLDateRangeType | undefined;

  userGroupSelecteds: GLSelectMultipleOptions[] | undefined;
  userParentGroupSelecteds: GLSelectMultipleOptions[] | undefined;

  geo_filter:
    | {
        min_lat: number;
        max_lat: number;
        min_long: number;
        max_long: number;
      }
    | undefined;

  range: GLDateRangeType;

  alertFilterValue: GLSelectMultipleOptions[] | undefined;
  alertTypesSelected: GLSelectMultipleOptions[] | undefined;
  filterByReading: GLSelectMultipleOptions[] | undefined;

  devicesHealthCheckCount: GetDeviceHealthCheckCountResponse | null;

  isFetchingDevices: boolean;
  isFetchingDevicesOverview: boolean;
  isFetchingDeviceInfo: boolean;
}

export interface HealthCheckStoreActions {
  setDeviceModalIsOpen: (deviceModalIsOpen: boolean) => void;
  setDeviceSelected: (
    deviceSelected?: GetDevicesActivatedLastReadingsResponse,
  ) => void;
  setDevicesFetched: (devicesFetched?: DeviceMapResponse[]) => void;

  setDevicesFetchedV2: (devicesFetchedV2?: DeviceMapResponse[]) => void;
  setDevicesFetchedInfoV2: (devicesFetchedInfoV2?: Omit<DeviceMapV2Response, 'devices'>) => void

  setDeviceSelectedDailyReadings: (
    deviceSelectedDailyReadings?: GetDevicesActivatedDailyReadingsResponse,
  ) => void;

  setTagSelecteds: (tagSelecteds: GLSelectMultipleOptions[] | undefined) => void;

  setTagValuesSelecteds: (tagValuesSelecteds: GLSelectMultipleOptions[] | undefined) => void;

  setBigNumbersIsOpen: (bigNumbersIsOpen: boolean) => void;

  setModalFilterIsOpen: (modalFilterIsOpen: boolean) => void;

  setUserGroupSelecteds: (
    userGroupSelecteds: GLSelectMultipleOptions[] | undefined,
  ) => void;
  setUserParentGroupSelecteds: (
    userParentGroupSelecteds: GLSelectMultipleOptions[] | undefined
  ) => void;

  setRange: (date: HealthCheckStoreState['range']) => void;
  setCurrentRelativeTime: (option?: GLRelativeListOption) => void;
  setCurrentDateRange: (range?: GLDateRangeType) => void;

  setGeoFilter: (geo_filter: HealthCheckStoreState['geo_filter']) => void;
  setAlertFilterValue: (
    alertFilterValue: HealthCheckStoreState['alertFilterValue'],
  ) => void;
  setAlertTypesSelected: (
    alertTypesSelected: HealthCheckStoreState['alertTypesSelected'],
  ) => void;

  setFilterByReading: (
    filterByReading: HealthCheckStoreState['filterByReading'],
  ) => void;

  setDevicesHealthCheckCount: (
    devicesHealthCheckCount: GetDeviceHealthCheckCountResponse | null,
  ) => void;

  setIsFetchingDevices: (isFetchingDevices: boolean) => void;
  setIsFetchingDevicesOverview: (isFetchingDevicesOverview: boolean) => void;
  setIsFetchingDeviceInfo: (isFetchingDeviceInfo: boolean) => void;
  resetAll: () => void;
}

export interface HealthCheckStore {
  state: HealthCheckStoreState;
  actions: HealthCheckStoreActions;
}

export const useHealthCheckStore = createWithEqualityFn<HealthCheckStore>(
  (set) => ({
    state: {
      deviceModalIsOpen: false,
      deviceSelected: undefined,
      devicesFetched: undefined,

      devicesFetchedV2: undefined,
      devicesFetchedInfoV2: undefined,
      deviceSelectedDailyReadings: undefined,

      bigNumbersIsOpen: true,

      userGroupSelecteds: undefined,
      userParentGroupSelecteds: undefined,

      tagSelecteds: undefined,

      tagValuesSelecteds: undefined,

      modalFilterIsOpen: false,

      currentDateRange: undefined,
      currentRelativeTime: {
        id: 'last_7_days',
        range: {
          from: subDays(new Date(), 7),
          to: new Date(),
        },
        text: i18n.t('days.last7Days'),
      },
      range: {
        from: subDays(new Date(), 7),
        to: new Date(),
      },

      geo_filter: undefined,

      alertFilterValue: undefined,
      filterByReading: undefined,
      alertTypesSelected: undefined,
      devicesHealthCheckCount: null,

      isFetchingDevicesOverview: false,
      isFetchingDevices: false,
      isFetchingDeviceInfo: false,
    },
    actions: {
      setDeviceModalIsOpen: (deviceModalIsOpen) =>
        set((state) => ({
          state: {
            ...state.state,
            deviceModalIsOpen,
          },
        })),

      setDeviceSelectedDailyReadings: (deviceSelectedDailyReadings) =>
        set((state) => ({
          state: {
            ...state.state,
            deviceSelectedDailyReadings,
          },
        })),

      setDeviceSelected: (deviceSelected) =>
        set((state) => ({
          state: {
            ...state.state,
            deviceSelected,
          },
        })),

      setDevicesFetched: (devicesFetched) => {
        set((state) => ({
          state: {
            ...state.state,
            devicesFetched,
          },
        }));
      },

      setDevicesFetchedV2: (devicesFetchedV2) => {
        set((state) => ({
          state: {
            ...state.state,
            devicesFetchedV2,
          },
        }))
      },

      setDevicesFetchedInfoV2: (devicesFetchedInfoV2) => {
        set((state) => ({
          state: {
            ...state.state,
            devicesFetchedInfoV2
          },
        }))
      },

      setBigNumbersIsOpen: (bigNumbersIsOpen) =>
        set((state) => ({
          state: {
            ...state.state,
            bigNumbersIsOpen,
          },
        })),

      setUserGroupSelecteds: (userGroupSelecteds) =>
        set((state) => ({
          state: {
            ...state.state,
            userGroupSelecteds,
          },
        })),

      setUserParentGroupSelecteds: (userParentGroupSelecteds) =>
        set((state) => ({
          state: {
            ...state.state,
            userParentGroupSelecteds,
          },
        })),

      setTagSelecteds: (tagSelecteds) =>
        set((state) => ({
          state: {
            ...state.state,
            tagSelecteds,
          },
        })),

      setTagValuesSelecteds: (tagValuesSelecteds) =>
        set((state) => ({
          state: {
            ...state.state,
            tagValuesSelecteds,
          },
        })),

      setModalFilterIsOpen: (modalFilterIsOpen) =>
        set((state) => ({
          state: {
            ...state.state,
            modalFilterIsOpen,
          },
        })),

      setCurrentDateRange: (currentDateRange) =>
        set((state) => ({
          state: {
            ...state.state,
            currentDateRange,
          },
        })),

      setCurrentRelativeTime: (currentRelativeTime) =>
        set((state) => ({
          state: {
            ...state.state,
            currentRelativeTime,
          },
        })),

      setRange: (range) =>
        set((state) => ({
          state: {
            ...state.state,
            range,
          },
        })),

      setGeoFilter: (geo_filter) =>
        set((state) => ({
          state: {
            ...state.state,
            geo_filter,
          },
        })),

      setIsFetchingDevices: (isFetchingDevices) =>
        set((state) => ({
          state: {
            ...state.state,
            isFetchingDevices,
          },
        })),

      setAlertFilterValue: (alertFilterValue) =>
        set((state) => ({
          state: {
            ...state.state,
            alertFilterValue,
          },
        })),

      setAlertTypesSelected: (alertTypesSelected) =>
        set((state) => ({
          state: {
            ...state.state,
            alertTypesSelected,
          },
        })),

      setFilterByReading: (filterByReading) =>
        set((state) => ({
          state: {
            ...state.state,
            filterByReading,
          },
        })),

      setDevicesHealthCheckCount: (devicesHealthCheckCount) =>
        set((state) => ({
          state: {
            ...state.state,
            devicesHealthCheckCount,
          },
        })),

      setIsFetchingDevicesOverview: (isFetchingDevicesOverview) =>
        set((state) => ({
          state: {
            ...state.state,
            isFetchingDevicesOverview,
          },
        })),

      setIsFetchingDeviceInfo: (isFetchingDeviceInfo) =>
        set((state) => ({
          state: {
            ...state.state,
            isFetchingDeviceInfo,
          },
        })),

      resetAll: () =>
        set((state) => ({
          state: {
            ...state.state,
            deviceModalIsOpen: false,
            deviceSelected: undefined,
            devicesFetched: undefined,
            alertFilterValue: [{ id: 'all', text: 'Todos' }],
            alertTypesSelected: undefined,
            isFetchingDevices: false,
            currentDateRange: undefined,
            currentRelativeTime: {
              id: 'last_7_days',
              range: {
                from: subDays(new Date(), 7),
                to: new Date(),
              },
              text: i18n.t('days.last7Days'),
            },
            geo_filter: undefined,
            range: {
              from: subDays(new Date(), 7),
              to: new Date(),
            },
          },
        })),
    },
  }),
);
