import { GLTransferList } from '@group-link-one/grouplink-components';

import { useGroupUsersTransferList } from './useGroupUsersTransferList';

export function GroupUsersTransferList(): JSX.Element {
  const {
    t,
    customersAvailables,
    customersAddeds,
    rightSideOptions,
    leftSideOptions,
    groupsUsersState,
    onSelectLeftItem,
    onSelectRightItem,
    onTransferAllItemsToLeft,
    onTransferAllItemsToRight,
    onTransferItem,
    onSearch,
  } = useGroupUsersTransferList();

  return (
    <GLTransferList
      leftSideList={customersAvailables}
      leftSideOptions={leftSideOptions}
      leftSideIsFetching={groupsUsersState.isFetchingMoreAvailableUsers}
      leftSideTexts={{
        search: t('groupsUsers.modal.transferList.leftSide.search'),
        emptyTitle:
          groupsUsersState.availableUsersSearch.length === 0
            ? t('groupsUsers.modal.transferList.leftSide.emptyState.title')
            : t(
                'groupsUsers.modal.transferList.leftSide.emptyStateFromSearch.title',
              ),
        emptyBody:
          groupsUsersState.availableUsersSearch.length === 0
            ? t(
                'groupsUsers.modal.transferList.leftSide.emptyState.description',
              )
            : t(
                'groupsUsers.modal.transferList.leftSide.emptyStateFromSearch.description',
              ),
      }}
      rightSideList={customersAddeds}
      rightSideOptions={rightSideOptions}
      rightSideIsFetching={groupsUsersState.isFetchingMoreUsersInGroup}
      rightSideTexts={{
        search: t('groupsUsers.modal.transferList.rightSide.search'),
        emptyTitle:
          groupsUsersState.usersInGroupSearch.length === 0
            ? t('groupsUsers.modal.transferList.rightSide.emptyState.title')
            : t(
                'groupsUsers.modal.transferList.rightSide.emptyStateFromSearch.title',
              ),
        emptyBody:
          groupsUsersState.usersInGroupSearch.length === 0
            ? t(
                'groupsUsers.modal.transferList.rightSide.emptyState.description',
              )
            : t(
                'groupsUsers.modal.transferList.rightSide.emptyStateFromSearch.description',
              ),
      }}
      onTransferItemToLeft={(items) => onTransferItem(items, 'left')}
      onTransferItemToRight={(items) => onTransferItem(items, 'right')}
      onSearch={(value, side) => onSearch(value, side)}
      onSelectLeftItem={onSelectLeftItem}
      onSelectRightItem={onSelectRightItem}
      onTransferAllItemsToLeft={onTransferAllItemsToLeft}
      onTransferAllItemsToRight={onTransferAllItemsToRight}
    />
  );
}
