import { useI18n, useToast } from '@group-link-one/grouplink-components';
import {
  DetailsDeviceStoreActions,
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../store/details-device-store';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { useModalAddTagContent } from '../ModalAddTag/Content/useModalAddTagContent';
import { useDeviceListAllDevices } from '../Content/useDeviceListContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useHandleCache } from '@/hooks/useHandleCache';

interface ModalAddTag {
  t: (key: string) => string;
  reassign: () => Promise<void | unknown>;
  deviceListState: DetailsDeviceStoreState;
  deviceListActions: DetailsDeviceStoreActions;
}

export const useModalSureToAddValue = (): ModalAddTag => {
  const { assignDeviceToTag } = useTagsService();
  const { t } = useI18n();
  const { invalidateCache } = useHandleCache();

  const { state: deviceListState, actions: deviceListActions } =
    useDetailsDeviceStore();

  async function reassign(): Promise<void | unknown> {
    try {
      await assignDeviceToTag({
        device_ids: [deviceListState.device?.device_id!],
        operations: [
          {
            key: deviceListState.dataToReassign.tag,
            value: deviceListState.dataToReassign.tagValue!,
          },
        ],
        preserve_existing_tags: true,
        strict: false,
        is_value_unique: false,
      });

      deviceListActions.setDevice({
        ...deviceListState.device!,
        tags: [
          ...(deviceListState.device?.tags || []).filter(
            (tag) => tag.key !== deviceListState.dataToReassign.tag,
          ),
          {
            key: deviceListState.dataToReassign.tag,
            value: deviceListState.dataToReassign.tagValue!,
          },
        ],
      });

      deviceListActions.setTagSelected({
        key: '',
        value: '',
        id: '',
        is_strict: undefined,
      });

      invalidateCache(['devices-activated-last-readings']);

      deviceListActions.setModalAddTagIsOpen(false);
      deviceListActions.setModalSureToAddValueIsOpen(false);

      return;
    } catch (error) {
      return error;
    }
  }

  return {
    t,
    deviceListState,
    deviceListActions,
    reassign,
  };
};
