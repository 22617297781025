import {
  Colors,
  GL_COLORS,
  GL_GAS,
  GL_IDEA,
  GL_IDEA_OFF,
  GL_THUNDERBOLT,
  GL_WATER,
} from '@group-link-one/grouplink-components';
import { ReactElement } from 'react';

import {
  Application,
  DeviceMeasurementCategory,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { useWaterIcon } from '@/images/DeviceList/water-green';
import { useGasIcon } from '@/images/DeviceList/gas-icon';
import { useEnergyIcon } from '@/images/DeviceList/energy-icon';
import { useStreetLightIcon } from '@/images/DeviceList/street-light-icon';

interface GetOptionsByApplicationParams {
  type?: Application;
  off?: boolean;
  deviceMeasurementCategory?: DeviceMeasurementCategory;
}

interface ApplicationOptionsSvgIconParams {
  withAlert?: boolean;
  off?: boolean;
  showNotReading?: boolean;
}

export interface ApplicationOptions {
  text: string;
  unit: string;
  color: string;
  hexColor: string;
  icon: (size: number, fill: string) => ReactElement;
  svgIcon: ({ withAlert }: ApplicationOptionsSvgIconParams) => string;
}

const energy: ApplicationOptions = {
  color: GL_COLORS.PURPLE,
  text: 'Energy',
  unit: 'kWh',
  hexColor: '#7523D7',
  icon: (size, fill) => <GL_THUNDERBOLT size={size} fill={fill} />,
  svgIcon: ({ withAlert, showNotReading }) => {
    if (showNotReading) return useEnergyIcon('no-reading');
    return withAlert ? useEnergyIcon('red') : useEnergyIcon('green');
  },
};

const water: ApplicationOptions = {
  color: GL_COLORS.ACCENT_COLOR,
  text: 'Water',
  unit: 'm³',
  icon: (size, fill) => <GL_WATER size={size} fill={fill} />,
  hexColor: '#0033FF',
  svgIcon: ({ withAlert, showNotReading }) => {
    if (showNotReading) return useWaterIcon('no-reading');
    return withAlert ? useWaterIcon('red') : useWaterIcon('green');
  },
};

const gas: ApplicationOptions = {
  color: Colors.ORANGE,
  text: 'Gas',
  unit: 'm³',
  hexColor: '#ff9966',
  icon: (size, fill) => <GL_GAS size={size} fill={fill} />,
  svgIcon: ({ withAlert, showNotReading }) => {
    if (showNotReading) return useGasIcon('no-reading');
    return withAlert ? useGasIcon('red') : useGasIcon('green');
  },
};

const streetlightDefault = {
  text: 'Street Light',
  unit: 'Wh',
};

const streetlight: ApplicationOptions = {
  ...streetlightDefault,
  color: Colors.LIGHT_GREEN,
  hexColor: '#00ffaa',
  icon: (size, fill) => <GL_IDEA size={size} fill={fill} />,
  svgIcon: ({ withAlert, showNotReading }) => {
    if (showNotReading) return useStreetLightIcon('no-reading', false);
    return withAlert ? useStreetLightIcon('red', false) : useStreetLightIcon('green', false);
  },
};

const streetlightOff: ApplicationOptions = {
  ...streetlightDefault,
  color: GL_COLORS.NAVY,
  hexColor: '#333366',
  icon: (size, fill) => <GL_IDEA_OFF size={size} fill={fill} />,
  svgIcon: ({ withAlert, showNotReading }) => {
    if (showNotReading) return useStreetLightIcon('no-reading', true);
    return withAlert ? useStreetLightIcon('red', true) : useStreetLightIcon('green', true);
  },
};

export function getOptionsByApplication({
  type,
  off,
  deviceMeasurementCategory,
}: GetOptionsByApplicationParams): ApplicationOptions {
  switch (type) {
    case 'GLUtilitiesEnergy':
      return energy;

    case 'GLUtilitiesWater':
      if (deviceMeasurementCategory?.includes('water')) {
        return water;
      }

      if (deviceMeasurementCategory?.includes('gas')) {
        return gas;
      }

      return water;

    case 'GLUtilitiesLight':
      if (off) return streetlightOff;
      return streetlight;

    default:
      return water;
  }
}
