import { IColumns, useI18n } from '@group-link-one/grouplink-components';


export const useStreetLightColumns = (): IColumns[] => {
  const { t } = useI18n();

  return [
    {
      columnName: t('eventList.columns.remoteId'), // remote_id
      hasActions: false,
      width: 15
    },
    {
      columnName: t('eventList.columns.address'), // display_Address
      width: 34
    },
    {
      columnName: t('eventList.columns.created_at'), // time
      hasActions: false,
      width: 15
    },
    {
      columnName: t('eventList.columns.activeEnergyAccum'), // activeEnergyAccum
      hasActions: false,
      width: 15
    },
    {
      columnName: t('eventList.columns.reactiveEnergyAccum'), // reactiveEnergyAccum
      hasActions: false,
      width: 15
    },
    {
      columnName: t('eventList.columns.alarmsCount'), // alarmsCount
      hasActions: false,
      width: 10
    },
  ];
};
