import {
  GL_COLORS,
  GL_DOWNLOAD,
  GL_FILTER,
  GLBox,
  GLButton,
} from '@group-link-one/grouplink-components';

import { useRightFilters } from './useRightFilters';

export function RightFilters(): JSX.Element {
  const {
    t,
    reportInfo,
    is500px,
    isDownloadReportAvailable,
    features,
    user,
    onClickFilter,
    onClickDownloadReport,
  } = useRightFilters();

  return (
    <GLBox
      width="fit-content"
      justify="center"
      align="center"
      style={{
        position: 'absolute',
        top: !is500px ? 40 : 100,
        right: 40,
      }}
    >
      {isDownloadReportAvailable && (
        <GLButton
          variant="outline"
          icon={<GL_DOWNLOAD size={18} fill={GL_COLORS.NAVY} />}
          border
          colorIconDynamically
          text={t('healthCheck.downloadReport.text')}
          align="center"
          iconPosition="right"
          disabled={!!reportInfo}
          isLoading={!!reportInfo}
          weight={600}
          style={{
            // width: 'fit-content',
            paddingInline: 20,
            minWidth: 150,
            backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
          }}
          onClick={onClickDownloadReport}
        />
      )}

      {features.healthCheck.filters.button && (
        <GLButton
          variant="outline"
          icon={<GL_FILTER size={18} fill={GL_COLORS.NAVY} />}
          border
          colorIconDynamically
          text={t('deviceList.search.filter')}
          align="center"
          iconPosition="right"
          weight={600}
          style={{
            width: 'fit-content',
            paddingInline: 20,
            backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
          }}
          onClick={onClickFilter}
        />
      )}
    </GLBox>
  );
}
