import { useAuth } from '@group-link-one/gl-utils';
import { AlertType } from '@group-link-one/grouplink-components';
import { useMemo } from 'react';

type AlarmsByUseCase = {
  alarms: string[] | undefined;
  alarmsMapped: string[] | undefined;
  alertsMapped: Record<AlertType, string>;
};

export const alertsMapped = {
  CALIBRATING: 'Calibrating',
  CAR_PLUGGED: 'Car plugged',
  LEAKAGE: 'Leakage',
  MAGNETIC_FRAUDULENCE: 'Magnetic fraud',
  MAX_FLOW: 'Max flow',
  OPEN_DOOR: 'Open door',
  OUT_OF_RANGE: 'Out of range',
  REVERSE_FLOW: 'Reverse flow',
  VIOLATION: 'Violation',
  WIRE_CUTTING: 'Wire cutting',
  LIGHT_FLICKERING_LAMP: 'Flickering lamp',
  LIGHT_OPEN_LOAD: 'Open load',
  LIGHT_OUTPUT_STATUS: 'Output status',
  LIGHT_OVER_CURRENT: 'Over current',
  LIGHT_OVER_VOLTAGE: 'Over voltage',
  LIGHT_PHOTOCELL_STATUS: 'Photocell status',
  LIGHT_UNDER_VOLTAGE: 'Under voltage',
} as Record<AlertType, string>;

const alarmsByUseCase = {
  util_water: [
    'CALIBRATING',
    'CAR_PLUGGED',
    'WIRE_CUTTING',
    'OPEN_DOOR',
    'OUT_OF_RANGE',
    'VIOLATION',
  ],
  util_light: [
    'LIGHT_FLICKERING_LAMP',
    'LIGHT_OPEN_LOAD',
    'LIGHT_OUTPUT_STATUS',
    'LIGHT_OVER_CURRENT',
    'LIGHT_OVER_VOLTAGE',
    'LIGHT_PHOTOCELL_STATUS',
    'LIGHT_UNDER_VOLTAGE',
  ],
  util_kwh: ['LIGHT_FLICKERING_LAMP'],
  util_rms: ['LIGHT_FLICKERING_LAMP'],
};

export const useAlarmsByUseCase = (): AlarmsByUseCase => {
  const { user } = useAuth();

  const useCase = useMemo(() => {
    return user?.use_case;
  }, [user?.use_case]);

  const alarms = useMemo(() => {
    if (!useCase) return;

    return alarmsByUseCase[useCase] || [];
  }, [useCase]);

  const alarmsMapped: string[] | undefined = useMemo(() => {
    return alarms?.map((alarm) => alertsMapped[alarm as AlertType]);
  }, [alarms]);

  return {
    alarms,
    alarmsMapped,
    alertsMapped,
  };
};
