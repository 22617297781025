import {
  Colors,
  GLPieChartDataProps,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useMemo } from 'react';

import { useGetDailyReadings } from '../../DeviceList/ModalDeviceDetails/Graphs/useGetDailyReadings';
import { useDetailsDeviceStore } from '../../DeviceList/store/details-device-store';
import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../store/health-check-store';
import {
  Application,
  DevicesActivatedLastReadingsChannel,
  GetDevicesActivatedDailyReadingsRow,
  LastReadingSeries,
  Series,
} from '@/Services/deviceListService/useDeviceListService.types';
import { useQuery } from '@tanstack/react-query';
import { useDeviceGroupsUtils } from '@/Pages/GroupList/useDeviceGroupsUtils';
import { GetOrgDeviceGroupIdResponse } from '@/Services/groupListService/useGroupListService.types';
import { useMediaQuery } from 'usehooks-ts';
import { MeasurementCategory, useAuth, UseCase } from '@group-link-one/gl-utils';
import { useGetStreetLightValues } from '@/hooks/useGetStreetLightValues';
import { ApplicationOptions, getOptionsByApplication } from '@/Pages/DeviceList/utils/getOptionsByApplication';

type DailyReadingInfo = {
  with_readings: number;
  without_readings: number;
  readingsFormatted: GetDevicesActivatedDailyReadingsRow[];
};


interface IUseDeviceModal {
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  communicationRate: string;
  dailyReadingInfo: DailyReadingInfo | undefined;
  pieChartData: GLPieChartDataProps[];
  applicationOptions: ApplicationOptions
  t: (key: string) => string;
  currentDeviceChannel: DevicesActivatedLastReadingsChannel | undefined;
  groups: GetOrgDeviceGroupIdResponse | null | undefined;
  streetLightValuesArray: { label: string; value: string }[];
  deviceMeasurementCategory: MeasurementCategory  | undefined;
  is800px: boolean;
  infoActive: { label: string; value: string }[] | undefined;
  showLastReading: boolean;
  isStreetLight: boolean;
  onClickSeeDetails: () => void;
  getLastReading: () => string;
}

export const useDeviceModal = (): IUseDeviceModal => {
  const { getDeviceGroup } = useDeviceGroupsUtils();
  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();
  const { user } = useAuth()
  const is800px = useMediaQuery('(max-width: 800px)');

  const { t } = useI18n();

  const { actions: detailsDeviceActions } = useDetailsDeviceStore();

  const { formatReadings } = useGetDailyReadings();

  const deviceSelected = healthCheckState.deviceSelected;
  const group = deviceSelected?.groups[0];

  const { data: groups } = useQuery({
    queryKey: [
      'getOrgDeviceGroupId',
      group
    ],
    queryFn: async () => {
      if (!deviceSelected || !group) return null;

      try {
        const response = await getDeviceGroup(
          deviceSelected?.groups[0],
        );

        return response;
      } catch (error) {
        return null;
      }
    },
  });

  const useCase = user?.use_case as UseCase;

  const currentDeviceChannel = deviceSelected?.channels[0];
  const isStreetLight = useCase === 'util_light' && deviceSelected?.application === 'GLUtilitiesLight';
  const isEnergy = useCase === 'util_kwh' && deviceSelected?.application === 'GLUtilitiesEnergy';

  const deviceMeasurementCategory = user?.device_measurement_categories?.length === 1
    ? user?.device_measurement_categories[0]
    : undefined;

  const showLastReading = !isStreetLight && !isEnergy

  const applicationOptions = getOptionsByApplication({
    type: healthCheckState.deviceSelected?.application,
    deviceMeasurementCategory
  })

  const { streetLightValuesArray, findValueInSeries } = useGetStreetLightValues({
    device: deviceSelected,
  })

  const infosByApplication: Partial<Record<Application, {label: string; value: string;}[]>> = {
    'GLUtilitiesEnergy': [
      {
        label: t('deviceList.detailsModal.ut.bigNumbers.one.title'),
        value: findValueInSeries('energy_reverse', { showUnit: true })
      },
      {
        label: t('deviceList.detailsModal.ut.bigNumbers.reverseEnergy.title'),
        value: findValueInSeries('energy_reverse', { showUnit: true })
      }
    ],
    'GLUtilitiesWater': [
      {
        label: t('deviceList.detailsModal.ut.bigNumbers.one.title'),
        value: findValueInSeries('water', { showUnit: true })
      }
    ],
    'GLUtilitiesLight': streetLightValuesArray
  }

  const infoActive = infosByApplication[deviceSelected?.application as Application]

  function onClickSeeDetails(): void {
    if (!deviceSelected) {
      return;
    }
    detailsDeviceActions.setDevice(deviceSelected);
    detailsDeviceActions.setModalIsOpen(true);
  }

  function getLastReading() {
    if (isStreetLight) return '--'

    let lastReading = currentDeviceChannel?.last_reading
    const unit = currentDeviceChannel?.unit

    if (deviceSelected?.application === 'GLUtilitiesWater') {
      lastReading = Number((lastReading as Series).series.find(s => s.serie === 'water')?.value)
    }

    return String(lastReading + ` ${unit}`)
  }

  const dailyReadingInfo = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) {
      return;
    }

    const { readingsFormatted } = formatReadings(
      healthCheckState.deviceSelectedDailyReadings.rows,
      healthCheckState.range.from,
      healthCheckState.range.to,
    );

    return {
      with_readings: readingsFormatted.filter((reading) => reading.reading > 0)
        .length,
      without_readings: readingsFormatted.filter(
        (reading) => reading.reading === 0,
      ).length,
      readingsFormatted,
    };
  }, [healthCheckState.deviceSelectedDailyReadings]);

  const pieChartData: GLPieChartDataProps[] = useMemo(() => {
    if (!dailyReadingInfo) {
      return [];
    }

    return [
      {
        id: 'with_readings',
        label: t('healthCheck.deviceSelected.communicationRate.withReadings'),
        value: dailyReadingInfo.with_readings,
        color: Colors.LIGHT_GREEN,
        hasTooltip: false,
        description: t(
          'healthCheck.deviceSelected.communicationRate.withReadingAbr',
        ),
      },
      {
        id: 'without_readings',
        label: t(
          'healthCheck.deviceSelected.communicationRate.withoutReadings',
        ),
        value: dailyReadingInfo.without_readings,
        color: Colors.ORANGE,
        hasTooltip: false,
        description: t(
          'healthCheck.deviceSelected.communicationRate.withoutReadingAbr',
        ),
      },
    ];
  }, [dailyReadingInfo]);

  const communicationRate = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) {
      return '0%';
    }

    const totalReadings = dailyReadingInfo?.readingsFormatted.length || 0;
    const readingsWithCommunication =
      dailyReadingInfo?.readingsFormatted.filter(
        (reading) => reading.reading > 0,
      ).length || 0;

    return ((readingsWithCommunication / totalReadings) * 100)
      .toFixed(1)
      .concat('%');
  }, [dailyReadingInfo]);

  return {
    healthCheckState,
    healthCheckActions,
    communicationRate,
    dailyReadingInfo,
    applicationOptions,
    pieChartData,
    is800px,
    infoActive,
    isStreetLight,
    showLastReading,
    t,
    currentDeviceChannel,
    groups,
    getLastReading,
    deviceMeasurementCategory,
    streetLightValuesArray,
    onClickSeeDetails,
  };
};
