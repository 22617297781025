import {
  TabProps,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';
import { useTagsStore } from '@/Pages/Tags/stores/tags.store';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { useHandleCache } from '@/hooks/useHandleCache';
import { useGLFlags } from '@group-link-one/gl-utils';
import { useState } from 'react';

const schema = yup.object().shape({
  values: yup.string().required(),
});

export const useAddValues = () => {
  const { t } = useI18n();
  const { showFeature } = useGLFlags();
  const { createTagValue } = useTagsService();
  const { invalidateCache } = useHandleCache();
  const { addToast } = useToast();
  const { actions, state } = useTagsStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isDisabled, setIsDisabled] = useState(true);

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      count: state.tagValues.length,
      name: t('tags.content.modal.createNewTag.addValues.values.list.tab'),
      cacheKey: '',
      onClick: () => {},
    },
  ];

  const { handleSubmit, setValue, reset, register } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      values: '',
    },
  });

  async function onSubmit(data: any) {
    const alreadyExists = state.tagValues.find(
      (tagValue) => tagValue.value === data.values,
    );

    reset({ values: '' });
    setIsDisabled(true);

    if (state.modalType === 'edition') {
      try {
        await createTagValue({
          tag_key_id: Number(state.activeTag?.key.id),
          value: data.values,
        });

        invalidateCache([
          'get-tag-values',
          state.modalType === 'edition',
          state.activeTag?.key.id,
        ]);

        actions.setTagValues({
          id: '',
          value: data.values,
        });
      } catch (error) {
        return error;
      }
      return;
    }

    if (alreadyExists && state.modalType === 'creation') {
      addToast({
        type: 'error',
        title: t('tags.content.toasts.valueError.title'),
        message: t('tags.content.toasts.valueError.description'),
      });
    }
    actions.setTagValues({
      id: '',
      value: data.values,
    });
  }

  return {
    t,
    isMobile,
    tabs,
    actions,
    state,
    isDisabled,
    handleSubmit,
    onSubmit,
    setValue,
    showFeature,
    setIsDisabled,
    register,
  };
};
