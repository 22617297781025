import { create } from 'zustand';

import {
  GetTagRowMap,
  TagValue,
  TagValueRow,
} from '../../../Services/tagsService/useTagsService.type';

interface ActiveTag extends TagValueRow {
  is_strict: boolean;
  rows?: GetTagRowMap[];
}

export interface TagsStoreState {
  modalCreateTagIsOpen: boolean;
  modalAssignValuesToTagIsOpen: boolean;

  activeTagId?: string;
  activeTag: ActiveTag | undefined;
  activeTagValueToEdit: TagValue | undefined;
  activeTagValueToDelete: TagValue | undefined;

  activeTagValues: TagValue[];

  selectedTagValue: TagValue | undefined;

  preserveExistingTags: boolean;

  dataActiveTagIsLoading: boolean;

  modalDeleteTagIsOpen: boolean;

  modalAssignToDeviceIsOpen: boolean;

  modalType: 'creation' | 'edition' | '';

  tagValues: TagValue[];
  tagValuesError: string;

  search: string;

  keyName?: string;
  keyNameError?: string;

  description?: string;

  isStrict: boolean;
  strictError: string;

  label?: string;
  labelError?: string;
}

export interface TagsStoreActions {
  setModalCreateTagIsOpen: (modalCreateTagIsOpen: boolean) => void;
  setModalAssignValuesToTagIsOpen: (
    modalAssignValuesToTagIsOpen: boolean,
  ) => void;

  setActiveTagId: (activeTagId?: string) => void;
  setActiveTag: (activeTag?: ActiveTag | undefined) => void;

  setActiveTagValues: (activeTagValues: TagValue[]) => void;

  setSelectedTagValue: (selectedTagValue?: TagValue) => void;

  setActiveTagValueToEdit: (activeTagToEdit?: TagValue) => void;
  setActiveTagValueToDelete: (activeTagToDelete?: TagValue) => void;

  setDataActiveTagIsLoading: (dataActiveTagIsLoading: boolean) => void;
  setModalDeleteTagIsOpen: (modalDeleteTagIsOpen: boolean) => void;

  setPreserveExistingTags: (preserveExistingTags: boolean) => void;

  setModalType: (modalType: 'creation' | 'edition' | '') => void;

  setModalAssignToDeviceIsOpen: (modalAssignToDeviceIsOpen: boolean) => void;

  setKeyName: (keyName: string) => void;
  setKeyNameError: (keyNameError: string) => void;

  setDescription: (description: string) => void;

  setTagValues: (tagValues: TagValue) => void;

  setTagValuesToAutoComplete: (tagValues: TagValue[]) => void;
  setTagValuesError: (tagValuesError: string) => void;

  setRemoveTagValue: (tagValue: string) => void;

  setSearch: (search: string) => void;

  setIsStrict: (isStrict: boolean) => void;
  setStrictError: (strictError: string) => void;

  setLabel: (label: string) => void;
  setLabelError: (labelError: string) => void;

  reset: () => void;
}

export interface TagsStore {
  state: TagsStoreState;
  actions: TagsStoreActions;
}

export const useTagsStore = create<TagsStore>((set) => ({
  state: {
    modalCreateTagIsOpen: false,
    modalAssignValuesToTagIsOpen: false,
    activeTagId: undefined,
    activeTag: undefined,
    activeTagValueToEdit: undefined,
    activeTagValueToDelete: undefined,

    preserveExistingTags: true,
    dataActiveTagIsLoading: false,
    modalDeleteTagIsOpen: false,

    activeTagValues: [],

    tagValues: [],
    tagValuesError: '',

    selectedTagValue: undefined,

    modalAssignToDeviceIsOpen: false,

    modalType: '',

    search: '',

    keyName: '',
    keyNameError: '',

    description: '',

    isStrict: false,
    strictError: '',

    label: '',
    labelError: '',
  },
  actions: {
    setModalType: (modalType) =>
      set((state) => ({
        state: {
          ...state.state,
          modalType,
        },
      })),

    setModalCreateTagIsOpen: (modalCreateTagIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalCreateTagIsOpen,
        },
      })),

    setModalAssignValuesToTagIsOpen: (modalAssignValuesToTagIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalAssignValuesToTagIsOpen,
        },
      })),

    setTagValues: (tagValue: TagValue) =>
      set((state) => {
        const tagExists = state.state.tagValues.some(
          (tag) => tag.value === tagValue.value,
        );

        return {
          state: {
            ...state.state,
            tagValues: tagExists
              ? state.state.tagValues.map((tag) =>
                  tag.value === tagValue.value ? tagValue : tag,
                )
              : [...state.state.tagValues, tagValue],
          },
        };
      }),

    setTagValuesToAutoComplete: (tagValues: TagValue[]) =>
      set((state) => ({
        state: {
          ...state.state,
          tagValues: tagValues,
        },
      })),

    setRemoveTagValue: (tagValue: string) =>
      set((state) => ({
        state: {
          ...state.state,
          tagValues: state.state.tagValues.filter(
            (tag) => tag.value !== tagValue,
          ),
        },
      })),

    setTagValuesError: (tagValuesError) =>
      set((state) => ({
        state: {
          ...state.state,
          tagValuesError,
        },
      })),

    setActiveTagValueToEdit: (activeTagValueToEdit) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTagValueToEdit,
        },
      })),

    setActiveTagValueToDelete: (activeTagValueToDelete) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTagValueToDelete,
        },
      })),

    setActiveTagId: (activeTagId) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTagId,
        },
      })),

    setActiveTag: (activeTag) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTag,
        },
      })),

    setActiveTagValues: (activeTagValues) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTagValues,
        },
      })),

    setSelectedTagValue: (selectedTagValue) =>
      set((state) => ({
        state: {
          ...state.state,
          selectedTagValue,
        },
      })),

    setPreserveExistingTags: (preserveExistingTags) =>
      set((state) => ({
        state: {
          ...state.state,
          preserveExistingTags,
        },
      })),

    setDataActiveTagIsLoading: (dataActiveTagIsLoading) =>
      set((state) => ({
        state: {
          ...state.state,
          dataActiveTagIsLoading,
        },
      })),

    setModalDeleteTagIsOpen: (modalDeleteTagIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalDeleteTagIsOpen,
        },
      })),

    setKeyName: (keyName) =>
      set((state) => ({
        state: {
          ...state.state,
          keyName,
        },
      })),

    setKeyNameError: (keyNameError) =>
      set((state) => ({
        state: {
          ...state.state,
          keyNameError,
        },
      })),

    setDescription: (description) =>
      set((state) => ({
        state: {
          ...state.state,
          description,
        },
      })),

    setSearch: (search) =>
      set((state) => ({
        state: {
          ...state.state,
          search,
        },
      })),

    setLabel: (label) =>
      set((state) => ({
        state: {
          ...state.state,
          label,
        },
      })),

    setLabelError: (labelError) =>
      set((state) => ({
        state: {
          ...state.state,
          labelError,
        },
      })),

    setModalAssignToDeviceIsOpen: (modalAssignToDeviceIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalAssignToDeviceIsOpen,
        },
      })),

    setIsStrict: (isStrict) =>
      set((state) => ({
        state: {
          ...state.state,
          isStrict,
        },
      })),

    setStrictError: (strictError) =>
      set((state) => ({
        state: {
          ...state.state,
          strictError,
        },
      })),

    reset: () =>
      set((state) => ({
        state: {
          ...state.state,
          modalCreateTagIsOpen: false,
          modalAssignValuesToTagIsOpen: false,
          activeTagId: undefined,
          activeTag: undefined,
          activeTagValueToEdit: undefined,
          activeTagValueToDelete: undefined,
          dataActiveTagIsLoading: false,
          modalDeleteTagIsOpen: false,
          selectedTagValue: undefined,
          isStrict: false,
          search: '',
          modalType: '',
          keyName: '',
          keyNameError: '',
          description: '',
          tagValues: [],
          descriptionError: '',
          label: '',
          labelError: '',
          tagValuesError: '',
        },
      })),
  },
}));
