import {
  AlertType,
  formatValueSize,
  GL_ALARM,
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_MONITOR,
  GL_PIN,
  GL_TAG,
  GL_SMARTPHONE,
  GL_WIFI,
  GLBox,
  GLButton,
  GLDropdown,
  GLTooltip,
  GLTypography,
  GL_DELETE,
  GLCardTemplate,
  GL_PLUS,
  GLTextField,
  GL_SEARCH,
  GL_HELP,
  GLEmptyList,
  GL_WARNING_CIRCLE,
  GL_ARROW_DOWN,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { getOptionsByApplication } from '../../utils/getOptionsByApplication';
import { AlertsContainer, DeviceNameContainer, TitleContainer } from './Style';
import { useDeviceDetailsTitleContent } from './useDeviceDetailsTitleContent';
import { If } from '@/utils/GLStatement/If';
import { Alarms } from './Alarms/Alarms';

export function DeviceDetailsTitleContent(): JSX.Element {
  const {
    device,
    langActive,
    features,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    detailsDeviceActions,
    t,
    alertsMapped,
    editingDeviceNameValue,
    hasAlarms,
    hasMoreThanOneChannel,
    hasToShowTagsInfo,
    isToShowOffset,
    isToShowPulseFactor,
    isEditingDeviceName,
    is1190px,
    isMobile,
    is768Px,
    editingRemoteIdValue,
    tags,
    tagInfos,
    isAddingNewValue,
    onSubmit,
    setIsAddignNewValue,
    setNewTagValue,
    onDeviceNameClick,
    onAddressClick,
    onChannelChange,
    setURLParams,
    onSubmitTagValue,
    showFeature,
    formatDate,
    handleInputChange,
  } = useDeviceDetailsTitleContent();

  const activateByText = `${currentChannelData?.activated_by}`;

  return (
    <GLBox
      width="100%"
      animation="fade-in-down"
      direction="column"
      transition={{
        delay: 0.3,
        duration: 1.4,
      }}
    >
      <TitleContainer>
        <GLBox
          layout
          direction="column"
          justify="flex-start"
          gap={5}
          width="auto"
        >
          <DeviceNameContainer
            layout
            className={isEditingDeviceName ? 'editing' : ''}
            onClick={() => onDeviceNameClick('device_name')}
            tabIndex={0}
            role="button"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onDeviceNameClick('device_name');
              }
            }}
          >
            <GLTypography
              as="p"
              text={editingDeviceNameValue || '--'}
              color="FONT_COLOR"
              weight={600}
              fontSize={is768Px ? 5.5 : 6.5}
              style={{
                whiteSpace: !is768Px ? 'nowrap' : 'normal',
                outline: 'transparent',
              }}
            />
          </DeviceNameContainer>

          <GLTypography
            as="span"
            text={`ID ${device?.device_id || ''}`}
            color="DISABLED_FONT_COLOR"
            weight={600}
            fontSize={3}
            style={{ whiteSpace: 'nowrap' }}
          />
        </GLBox>

        <GLBox layout>
          <GLTooltip
            text={
              device &&
                device?.meta?.display_address?.length &&
                device?.meta.display_address.length > 70
                ? device.meta.display_address
                : undefined
            }
          >
            <GLButton
              text={
                formatValueSize({
                  size: 70,
                  value: device?.meta.display_address || '',
                }) || '--'
              }
              variant="outline"
              align="left"
              border={false}
              weight={600}
              colorIconDynamically={true}
              icon={
                <GL_PIN
                  size={16}
                  fill={
                    getOptionsByApplication({
                      type: device?.application,
                    }).color
                  }
                />
              }
              color={
                getOptionsByApplication({
                  type: device?.application,
                }).color
              }
              style={{ width: 'fit-content', textAlign: 'left' }}
              onClick={onAddressClick}
            />
          </GLTooltip>
        </GLBox>
      </TitleContainer>

      <Alarms alarms={currentChannelData?.alerts} />

      <GLBox
        align={isMobile ? 'flex-start' : 'flex-end'}
        justify={isMobile ? 'flex-start' : 'normal'}
        direction={isMobile ? 'column' : 'row'}
      >
        <GLBox
          width={is768Px ? 300 : 'fit-content'}
          height={is768Px ? 'auto' : 48}
          align="center"
          direction={is768Px ? 'column' : 'row'}
          style={{
            border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
            padding: is768Px ? '10px' : '20px 20px',
            borderRadius: 26,
          }}
          gap={is768Px ? 5 : 0}
        >
          <GLBox
            justify={is768Px ? 'flex-start' : 'center'}
            style={{
              paddingInline: is768Px ? 10 : 14.5,
              paddingBlock: is768Px ? 10 : 0,
              backgroundColor: is768Px ? GL_COLORS.BACKGROUND_SECONDARY : 'none',
            }}
            borderRadius={is768Px ? 8 : 0}
          >
            <GLTypography
              text="REMOTE ID"
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            />
            <GLTypography
              text={editingRemoteIdValue || '--'}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
          </GLBox>

          <GLBox
            direction="row"
            width={'100%'}
            borderRadius={is768Px ? 8 : 0}
            height={is768Px ? 'auto' : 20}
            style={{
              borderLeft: !is768Px ? `1px solid ${GL_COLORS.BORDER_COLOR}` : 'none',
              paddingInline: is768Px ? 10 : 14.5,
              paddingBlock: is768Px ? 10 : 0,
              backgroundColor: is768Px ? GL_COLORS.BACKGROUND_SECONDARY : 'none',

            }}
            align='center'
            justify={is768Px ? 'flex-start' : 'center'}

          >
            <GLTypography
              text={t('deviceList.detailsModal.activatedAt')}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            />
            <GLTypography
              text={
                device
                  ? format(
                    new Date(currentChannelData?.activated_at || ''),
                    'dd/MM/yyyy',
                    {
                      locale: langActive,
                    },
                  )
                  : ''
              }
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
          </GLBox>

          <GLBox
            direction="row"
            align="center"
            borderRadius={is768Px ? 8 : 0}
            height={is768Px ? 'auto' : 20}
            style={{
              borderLeft: !is768Px ? `1px solid ${GL_COLORS.BORDER_COLOR}` : 'none',
              paddingInline: is768Px ? 10 : 14.5,
              paddingBlock: is768Px ? 10 : 0,
              backgroundColor: is768Px ? GL_COLORS.BACKGROUND_SECONDARY : 'none',
            }}
            justify={is768Px ? 'flex-start' : 'center'}
          >
            <GLTypography
              text={t('deviceList.card.ut.lastEvent')}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{
                width: '100%',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            />
            <GLTypography
              text={`${formatDate(device?.last_seen).day} • ${formatDate(device?.last_seen).hour}`}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            />
          </GLBox>

          <GLBox
            borderRadius={is768Px ? 8 : 0}
            height={is768Px ? 'auto' : 20}
            style={{
              borderLeft: !is768Px ? `1px solid ${GL_COLORS.BORDER_COLOR}` : 'none',
              paddingInline: is768Px ? 7 : 14.5,
              paddingBlock: is768Px ? 5 : 0,
              backgroundColor: is768Px ? GL_COLORS.BACKGROUND_SECONDARY : 'none',
            }}
          >
            <GLDropdown.Root>
              <GLDropdown.Button
                style={{ height: 30, transform: is768Px ? 'initial' : 'translateY(-5px)' }}
                icon={
                  <GLBox >
                    <GLTypography
                      text={t(
                        'deviceList.registerModal.form.fields.additionalInformation.label',
                      )}
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    />

                    <GL_CHEVRON_DOWN fill={GL_COLORS.FONT_COLOR} size={16} />
                  </GLBox>
                }
                hasOutline={false}
              />

              <GLDropdown.BoxOptions
                width="fit-content"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 10,
                  padding: 15,
                }}
                position={is768Px ? 'bottom-left' : 'bottom-right'}
              >
                <If condition={isToShowOffset}>
                  <GLBox
                    style={{
                      backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                      padding: 14.5,
                    }}
                  >
                    <GLTypography
                      text="OFFSET"
                      color="FONT_COLOR_DARK_GREY"
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                    />
                    <GLTypography
                      text={String(currentChannelData?.device_offset) || ''}
                      color="TITLE_COLOR_SECONDARY"
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                    />
                  </GLBox>
                </If>

                <If condition={isToShowPulseFactor}>
                  <GLBox
                    style={{
                      backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                      padding: 14.5,
                    }}
                  >
                    <GLTypography
                      text={`${getOptionsByApplication({
                        type: detailsDeviceState.device?.application,
                      }).unit || ''
                        } / PULSO`}
                      color="FONT_COLOR_DARK_GREY"
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                      style={{ textTransform: 'uppercase' }}
                    />
                    <GLTypography
                      text={String(currentChannelData?.device_pulse_factor) || ''}
                      color="TITLE_COLOR_SECONDARY"
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                    />
                  </GLBox>
                </If>

                <GLBox
                  direction="row"
                  width={'100%'}
                  style={{
                    backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                    padding: 14.5,
                  }}
                >
                  <GLTypography
                    text={t('deviceList.card.activatedBy')}
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                    style={{
                      textTransform: 'uppercase',
                      whiteSpace: 'nowrap',
                    }}
                  />
                  <GLBox direction="row" align="center">
                    <GLTypography
                      text={activateByText || ''}
                      color="TITLE_COLOR_SECONDARY"
                      as="span"
                      fontSize={is1190px ? 3 : 3.5}
                      weight={600}
                    />

                    {device?.meta.activation_mode === 'app' ? (
                      <GL_SMARTPHONE fill={GL_COLORS.FONT_COLOR} />
                    ) : device?.meta.activation_mode === 'server_side' ? (
                      <GL_WIFI fill={GL_COLORS.FONT_COLOR} />
                    ) : (
                      <GL_MONITOR fill={GL_COLORS.FONT_COLOR} />
                    )}
                  </GLBox>
                </GLBox>
              </GLDropdown.BoxOptions>
            </GLDropdown.Root>
          </GLBox>
        </GLBox>

        {/* <GLBox
          width={'fit-content'}
          align="center"
          height={48}
          style={{
            border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
            padding: '20px 20px',
            borderRadius: 26,
          }}
        >
          <GLBox align="center">
            <GLTypography
              text={t('deviceList.detailsModal.activatedAt')}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ textTransform: 'uppercase' }}
            />
            <GLTypography
              text={
                device
                  ? format(
                    new Date(currentChannelData?.activated_at || ''),
                    'dd/MM/yyyy',
                    {
                      locale: langActive,
                    },
                  )
                  : ''
              }
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
            <GLTypography
              text={t('deviceList.card.activatedBy')}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ textTransform: 'uppercase' }}
            />
            <GLTypography
              text={activateByText || ''}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{
                wordBreak: 'break-all',
                backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                borderRadius: 2,
              }}
            />

            {device?.meta.activation_mode === 'app' ? (
              <GL_SMARTPHONE fill={GL_COLORS.FONT_COLOR} />
            ) : device?.meta.activation_mode === 'server_side' ? (
              <GL_WIFI fill={GL_COLORS.FONT_COLOR} />
            ) : (
              <GL_MONITOR fill={GL_COLORS.FONT_COLOR} />
            )}
          </GLBox>
        </GLBox>

        <GLBox
          width={'fit-content'}
          align="center"
          height={48}
          style={{
            border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
            padding: '20px 20px',
            borderRadius: 26,
          }}
        >
          <GLBox align="center">
            <GLTypography
              text={t('deviceList.card.ut.lastEvent')}
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
              style={{ textTransform: 'uppercase' }}
            />
            <GLTypography
              text={`${formatDate(device?.last_seen).day} • ${formatDate(device?.last_seen).hour}`}
              color="TITLE_COLOR_SECONDARY"
              as="span"
              fontSize={is1190px ? 3 : 3.5}
              weight={600}
            />
          </GLBox>
        </GLBox> */}

        <If condition={hasMoreThanOneChannel}>
          <GLDropdown.Root width="130px">
            <GLDropdown.Input
              width="100%"
              icon={
                <GL_CHEVRON_DOWN
                  size={12}
                  fill={GL_COLORS.DISABLED_FONT_COLOR}
                />
              }
              value={currentChannelValue}
              valueOptions={{
                whiteSpace: 'nowrap',
                fontWeight: 600,
              }}
            />

            <GLDropdown.BoxOptions width="100%">
              {detailsDeviceState.device?.channels?.map((channel, index) => (
                <GLDropdown.Option
                  key={index}
                  text={`Channel ${channel.channel + 1}`}
                  value={`Channel ${channel.channel + 1}`}
                  hasCheckInActive={false}
                  onClick={(value) => {
                    onChannelChange(value);
                  }}
                />
              ))}
            </GLDropdown.BoxOptions>
          </GLDropdown.Root>
        </If>
      </GLBox>

      <If condition={hasToShowTagsInfo}>
        <GLBox align="center" gap={20}>
          <If condition={features.deviceList.detailsModal.showTags}>
            <GLTypography
              text="TAGS"
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={3.5}
              weight={600}
              style={{ whiteSpace: 'nowrap' }}
            />

            <If condition={features.deviceList.detailsModal.createTag}>
              <motion.div
                initial={{
                  opacity: 0,
                  x: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: 0 * 0.15,
                }}
              >
                <GLDropdown.Root>
                  <GLDropdown.Button
                    icon={<GL_PLUS fill={GL_COLORS.FONT_COLOR} size={12} />}
                    style={{
                      backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                      width: 36,
                      height: 36,
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 6,
                    }}
                  ></GLDropdown.Button>

                  <GLDropdown.BoxOptions
                    width={'fit-content'}
                    style={{
                      borderRadius: 6,
                      zIndex: 999999,
                    }}
                  >
                    <GLBox
                      direction="column"
                      style={{
                        padding: 15,
                      }}
                    >
                      <GLBox>
                        <GLTextField
                          icon={
                            <GL_SEARCH fill={GL_COLORS.DISABLED_FONT_COLOR} />
                          }
                          placeholder={t('common.search')}
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      </GLBox>

                      <GLBox width={'100%'}>
                        <GLBox direction="column">
                          {tags?.length === 0 && (
                            <GLBox width={'250px'} style={{ padding: 14.5 }}>
                              <GLEmptyList
                                title=""
                                description="Tags not found"
                                icon={
                                  <GL_TAG
                                    fill={GL_COLORS.FONT_COLOR}
                                    size={18}
                                  />
                                }
                                hasBorder={false}
                              />
                            </GLBox>
                          )}

                          {tags
                            ?.filter((tag) => tag.label)
                            .map((item, index) => (
                              <motion.div
                                key={index}
                                initial={{
                                  opacity: 0,
                                  x: -20,
                                }}
                                animate={{
                                  opacity: 1,
                                  x: 0,
                                }}
                                transition={{
                                  duration: 0.5,
                                  delay: index * 0.15,
                                }}
                                style={{
                                  width: '100%',
                                }}
                              >
                                <GLBox direction="row" align="center" gap={0}>
                                  <GLBox
                                    height={49}
                                    style={{
                                      backgroundColor: GL_COLORS.BORDER_COLOR,
                                      borderRadius: `6px 0px 0px 6px`,
                                      padding: 14.5,
                                    }}
                                  >
                                    <GLBox>
                                      <GL_TAG
                                        fill={GL_COLORS.FONT_COLOR}
                                        size={14}
                                      />
                                      <GLTypography
                                        text={item.key}
                                        weight={600}
                                        color="FONT_COLOR"
                                        as="span"
                                        fontSize={3.5}
                                        style={{
                                          maxWidth: 80,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                      />
                                    </GLBox>
                                    {item.is_strict && (
                                      <GLBox justify="flex-end">
                                        <GLTooltip
                                          text={t(
                                            'deviceList.detailsModal.tags.modal.isStrict',
                                          )}
                                          position="top-left"
                                          tooltipTextStyle={{
                                            color: GL_COLORS.WHITE,
                                          }}
                                          tooltipBoxStyle={{
                                            zIndex: 0,
                                          }}
                                          width={200}
                                        >
                                          <GL_WARNING_CIRCLE
                                            fill={GL_COLORS.FONT_COLOR}
                                            size={12}
                                          />
                                        </GLTooltip>
                                      </GLBox>
                                    )}
                                  </GLBox>
                                  <GLBox>
                                    <GLDropdown.Root width="100%">
                                      <GLDropdown.Input
                                        onClickCapture={() => {
                                          detailsDeviceActions.setTagSelected({
                                            key: item.key,
                                            id: item.id,
                                            is_strict: item.is_strict,
                                          });
                                          setIsAddignNewValue(false);
                                        }}
                                        icon={
                                          <GL_CHEVRON_DOWN
                                            size={12}
                                            fill={GL_COLORS.DISABLED_FONT_COLOR}
                                          />
                                        }
                                        placeholder={t('common.select')}
                                        style={{
                                          border: 'none',
                                          backgroundColor:
                                            GL_COLORS.BACKGROUND_SECONDARY,
                                          borderRadius: '0px 6px 6px 0px',
                                        }}
                                        valueOptions={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 'normal',
                                        }}
                                      />

                                      <GLDropdown.BoxOptions
                                        width={260}
                                        style={{
                                          zIndex: 9999999,
                                        }}
                                      >
                                        <GLBox>
                                          <GLTextField
                                            style={{
                                              borderBottom: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                                              borderRadius: '6px 6px 0px 0px',
                                            }}
                                            icon={
                                              <GL_SEARCH
                                                fill={
                                                  GL_COLORS.DISABLED_FONT_COLOR
                                                }
                                              />
                                            }
                                            placeholder={t('common.search')}
                                          />
                                        </GLBox>
                                        {tagInfos?.values.map(
                                          (tagValue, index) => (
                                            <GLDropdown.Option
                                              key={index}
                                              text={tagValue.value}
                                              value={tagValue.value}
                                              hasCheckInActive={false}
                                              onClick={(value) => {
                                                onSubmit({
                                                  tag: detailsDeviceState
                                                    .tagSelected.key!,
                                                  tagValue: tagValue.value,
                                                  preserve_existing_tags: true,
                                                });
                                              }}
                                            />
                                          ),
                                        )}

                                        {isAddingNewValue && (
                                          <motion.div
                                            key={index}
                                            initial={{
                                              opacity: 0,
                                              x: -20,
                                            }}
                                            animate={{
                                              opacity: 1,
                                              x: 0,
                                            }}
                                            transition={{
                                              duration: 0.5,
                                              delay: index * 0.15,
                                            }}
                                            style={{
                                              width: '100%',
                                            }}
                                          >
                                            <GLBox
                                              width={'100%'}
                                              style={{
                                                padding: '15px',
                                                borderTop: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                                                borderRadius: '0px 0px 6px 6px',
                                              }}
                                            >
                                              <GLTextField
                                                placeholder={t(
                                                  'deviceList.detailsModal.tags.modal.newValue',
                                                )}
                                                style={{
                                                  width: '100%',
                                                  borderRadius: 6,
                                                }}
                                                onChange={(e) =>
                                                  setNewTagValue(e.target.value)
                                                }
                                              />

                                              <GLBox
                                                justify="flex-end"
                                                width={'fit-content'}
                                                onClick={onSubmitTagValue}
                                              >
                                                <GLDropdown.Button
                                                  icon={
                                                    <GL_PLUS
                                                      fill={
                                                        GL_COLORS.FONT_COLOR_VARIANT
                                                      }
                                                      size={14}
                                                    />
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      GL_COLORS.ACCENT_COLOR,
                                                    padding: 16,
                                                    borderRadius: 6,
                                                  }}
                                                />
                                              </GLBox>
                                            </GLBox>
                                          </motion.div>
                                        )}
                                        {showFeature(
                                          'action_btn_assign_value_to_tag',
                                        ) &&
                                          !item.is_strict && (
                                            <GLDropdown.Option
                                              text={`+ ${t('common.add')}`}
                                              isToCloseAfterClick={false}
                                              hasToShowSelected={
                                                isAddingNewValue
                                              }
                                              currentValue="add-new-value"
                                              identifier="add-new-value"
                                              color={'ACCENT_COLOR'}
                                              spanStyle={{
                                                fontWeight: 600,
                                              }}
                                              style={{
                                                borderTop: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                                                borderRadius:
                                                  '0px 0px 10px 10px',
                                              }}
                                              onClick={() => {
                                                setIsAddignNewValue(true);
                                              }}
                                            />
                                          )}
                                      </GLDropdown.BoxOptions>
                                    </GLDropdown.Root>
                                  </GLBox>
                                </GLBox>
                              </motion.div>
                            ))}
                        </GLBox>
                      </GLBox>
                    </GLBox>
                  </GLDropdown.BoxOptions>
                </GLDropdown.Root>
              </motion.div>
            </If>

            {device && device?.tags?.length > 0 && (
              <AlertsContainer>
                <GLBox gap={30} align="center">
                  {device?.tags?.map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{
                        opacity: 0,
                        x: -20,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                      }}
                      transition={{
                        duration: 0.5,
                        delay: index * 0.15,
                      }}
                    >
                      <GLCardTemplate.Provider>
                        <GLCardTemplate.Root>
                          <GLCardTemplate.Header
                            align="center"
                            justify="space-between"
                            style={{
                              padding: 8,
                              backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                            }}
                          >
                            <GLBox align="center" justify="space-between">
                              <GLBox
                                style={{
                                  borderRadius: '6px 0px 0px 6px',
                                }}
                              >
                                <GL_TAG
                                  size={18}
                                  fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                                />

                                <GLTypography
                                  text={item.key}
                                  color="FONT_COLOR_DARK_GREY"
                                  as="span"
                                  fontSize={3.5}
                                  style={{ whiteSpace: 'nowrap' }}
                                />
                              </GLBox>

                              <GLBox
                                align="center"
                                direction="row"
                                gap={10}
                                style={{
                                  paddingRight: 15,
                                }}
                              >
                                <GLBox>
                                  <GLTypography
                                    text={item.value}
                                    color="FONT_COLOR_DARK_GREY"
                                    as="span"
                                    fontSize={3.5}
                                    style={{ whiteSpace: 'nowrap' }}
                                  />
                                </GLBox>
                              </GLBox>
                            </GLBox>
                            <GLCardTemplate.Actions
                              disappearOnHover={false}
                              showOnHover
                            >
                              <GLBox
                                align="center"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  detailsDeviceActions.setModalUnassignTagOptions(
                                    {
                                      open: true,
                                      modalDescription: t(
                                        'deviceList.detailsModal.tags.modal.removingTag.description',
                                      ),
                                      tagsThatWillBeRemoved: [
                                        { ...item, value: null },
                                      ],
                                    },
                                  );

                                  setURLParams({
                                    q: '',
                                  });

                                  detailsDeviceActions.setTagSelected({
                                    key: item.key,
                                    value: item.value,
                                  });
                                }}
                              >
                                <GL_DELETE
                                  fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                                  size={15}
                                />
                              </GLBox>
                            </GLCardTemplate.Actions>
                          </GLCardTemplate.Header>
                        </GLCardTemplate.Root>
                      </GLCardTemplate.Provider>
                    </motion.div>
                  ))}

                  {device?.tags?.length > 1 && (
                    <GLButton
                      text={t(
                        'deviceList.detailsModal.tags.modal.removingTag.clearAllTags',
                      )}
                      variant="outline"
                      align="center"
                      color={GL_COLORS.ACCENT_COLOR}
                      icon={
                        <GL_DELETE fill={GL_COLORS.ACCENT_COLOR} size={10} />
                      }
                      weight={600}
                      onClick={() => {
                        detailsDeviceActions.setModalUnassignTagOptions({
                          open: true,
                          modalDescription: t(
                            'deviceList.detailsModal.tags.modal.removingAllTags.description',
                          ),
                          tagsThatWillBeRemoved: device?.tags?.map((tag) => ({
                            ...tag,
                            value: null,
                          })),
                        });
                      }}
                    />
                  )}
                </GLBox>
              </AlertsContainer>
            )}
          </If>
        </GLBox>
      </If>
    </GLBox>
  );
}
